import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_inventory_material_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], skip?: number, take?: number }): 
  Promise<{ result: { WarehouseId?: number, MaterialId?: number, PackagedId?: number, GrossWeight?: number, PackagedAmount?: number, Material?: { Description?: string, LookupCode?: string, Name?: string, ProjectId?: number, ShelfLifeSpan?: number, Project?: { LookupCode?: string }, Status?: { Name?: string }, MaterialGroup?: { Name?: string }, Lots?: { ReceiveDate?: string }[] }, Packaging?: { ShortName?: string }, Warehouse?: { Name?: string }, WeightUom?: { Short_name?: string }, total_license_plates_count_material_id?: { TotalLicenseplates?: number, Lot?: { MaterialId?: number } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_inventory_material_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], skip?: number, take?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { WarehouseId?: number, MaterialId?: number, PackagedId?: number, GrossWeight?: number, PackagedAmount?: number, Material?: { Description?: string, LookupCode?: string, Name?: string, ProjectId?: number, ShelfLifeSpan?: number, Project?: { LookupCode?: string }, Status?: { Name?: string }, MaterialGroup?: { Name?: string }, Lots?: { ReceiveDate?: string }[] }, Packaging?: { ShortName?: string }, Warehouse?: { Name?: string }, WeightUom?: { Short_name?: string }, total_license_plates_count_material_id?: { TotalLicenseplates?: number, Lot?: { MaterialId?: number } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_inventory_material_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], skip?: number, take?: number, $keys: { WarehouseId?: number, MaterialId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { WarehouseId?: number, MaterialId?: number, PackagedId?: number, GrossWeight?: number, PackagedAmount?: number, Material?: { Description?: string, LookupCode?: string, Name?: string, ProjectId?: number, ShelfLifeSpan?: number, Project?: { LookupCode?: string }, Status?: { Name?: string }, MaterialGroup?: { Name?: string }, Lots?: { ReceiveDate?: string }[] }, Packaging?: { ShortName?: string }, Warehouse?: { Name?: string }, WeightUom?: { Short_name?: string }, total_license_plates_count_material_id?: { TotalLicenseplates?: number, Lot?: { MaterialId?: number } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_inventory_material_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], skip?: number, take?: number    }) {
      return false;
    }
}
