import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_customized_ds_sales_order_lines_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, lineNumber: number }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, Marks?: string, MaterialId?: number, PackagedId?: number, Material?: { Description?: string }, ShipmentLines?: { Id?: number, Shipment?: { ExpectedWarehouseId?: number } }[], Order?: { PreferredWarehouseId?: number }, total_inactive?: { TotalAvailablePackagedAmount?: number, TotalAvailableBaseAmount?: number, TotalInactiveBaseAmount?: number, TotalInactivePackagedAmount?: number, TotalSoftAllocatedBaseAmount?: number, TotalSoftAllocatedPackagedAmount?: number, MaterialId?: number, PackagedId?: number, WarehouseId?: number }[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.lineNumber)) {
      missingRequiredInParams.push('\'lineNumber\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/customized_ds_sales_order_lines_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number, lineNumber: number    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
        if(isNil(inParams.lineNumber)) {
          return true; 
        }
      return false;
    }
}
