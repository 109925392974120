import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_update_material_packagingService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { material_id: number, packaging: number, update: $frontendTypes.FootprintApiManager.input_update_material_packaging }): Promise<{ material_packaging?: $frontendTypes.FootprintApiManager.public_material_packaging }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.material_id)) {
      missingRequiredInParams.push('\'material_id\'');
    }
    if (isNil(inParams.packaging)) {
      missingRequiredInParams.push('\'packaging\'');
    }
    if (isNil(inParams.update)) {
      missingRequiredInParams.push('\'update\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootprintApiManager/functions/update_material_packaging`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

