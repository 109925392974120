import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { FootprintApiManager_ReportService } from './FootprintApiManager.report.index';


@Injectable({ providedIn: 'root' })
export class Orderful_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.Notifications = this.injector.get(Notifications_ReportService);
    this.FootprintApiManager = this.injector.get(FootprintApiManager_ReportService);
  }
    public Utilities: Utilities_ReportService;
    public Notifications: Notifications_ReportService;
    public FootprintApiManager: FootprintApiManager_ReportService;

  public Orderful: Orderful_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

