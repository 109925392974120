import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_dock_appointment_reportServiceInParams {
  dock_appointment_id?: number}

interface IReports_custom_dock_appointment_reportServiceData {
  ds_dock_appointment?: { result?: { dockAppointment?: { Id?: number, AssignOn?: string, CancelledOn?: string, CheckedInOn?: string, CompletedOn?: string, DriverLicense?: string, DriverName?: string, InProcessOn?: string, LookupCode?: string, Notes?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledDeparture?: string, VehicleLicense?: string, ScheduledLocation_Name?: string, ScheduledCarrier_Name?: string, ScheduledOwner_Name?: string, ScheduledProject_Name?: string, Status_Name?: string, AssignedLocation_Name?: string, Warehouse_Name?: string, Warehouse_TimeZoneId?: string, Type_Name?: string, Order_Notes?: string, convertedScheduledArrival__ConvertedDate?: string, convertedScheduledDeparture_ConvertedDate?: string, convertedCheckInOn_ConvertedDate?: string, convertedAssignedOn_ConvertedDate?: string, convertedInProcessOn_ConvertedDate?: string, convertedCompletedOn_ConvertedDate?: string, convertedCancelledOn_ConvertedDate?: string, Order_Lookup?: string, LoadContainer_Lookup?: string } } }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_dock_appointment_reportService extends ReportBaseService<IReports_custom_dock_appointment_reportServiceInParams, IReports_custom_dock_appointment_reportServiceData> {

  protected reportReferenceName = 'custom_dock_appointment_report';
  protected appReferenceName = 'Reports';

  constructor(
    $utils: UtilsService,
    private $datasources: Reports_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_dock_appointment_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_dock_appointment_reportServiceInParams): Promise<IReports_custom_dock_appointment_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IReports_custom_dock_appointment_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_dock_appointment_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        dock_appointment_id:  $report.inParams.dock_appointment_id 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_dock_appointment_flow_report.get(dsParams);
      
      data.ds_dock_appointment = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
