import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_prediction_flow_load_containerService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { load_container_id?: number }): Promise<{ result?: { Picktime?: number, Predict?: number, Load?: number, LoadContainerId?: number } }> {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_prediction_flow_load_container/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      load_container_id?: number    }) {
      return false;
    }
}
