<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-filters"
                     *ngIf="!filtersets.filters.hidden && !filtersets.filters.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.filters.collapsible }">
                    <div *ngIf="!filtersets.filters.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.filters.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.filters.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.filters.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.filters.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.filters.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.filters.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-from_date" *ngIf="!filters.from_date.hidden && !filters.from_date.removed" 
                                  class="field-container standard {{filters.from_date.invalid ? 'invalid' : ''}} {{filters.from_date.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.from_date.styles.style"
                                  [ngClass]="filters.from_date.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.from_date.label + (filters.from_date.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.from_date.label}}<span *ngIf="filters.from_date.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <app-datebox 
                                  data-cy="dateBox"
                                  formControlName="from_date"
                                  [format]="filters.from_date.control.format"
                                  [mode]="filters.from_date.control.mode"
                                  [ngStyle]="filters.from_date.control.styles.style"
                                  [ngClass]="filters.from_date.control.styles.classes"
                                  [tooltip]="filters.from_date.control.tooltip">
                              </app-datebox>
                              <ng-container *ngIf="filters.from_date.invalid">
                                <ng-container *ngFor="let error of filters.from_date.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-to_date" *ngIf="!filters.to_date.hidden && !filters.to_date.removed" 
                                  class="field-container standard {{filters.to_date.invalid ? 'invalid' : ''}} {{filters.to_date.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.to_date.styles.style"
                                  [ngClass]="filters.to_date.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.to_date.label + (filters.to_date.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.to_date.label}}<span *ngIf="filters.to_date.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <app-datebox 
                                  data-cy="dateBox"
                                  formControlName="to_date"
                                  [format]="filters.to_date.control.format"
                                  [mode]="filters.to_date.control.mode"
                                  [ngStyle]="filters.to_date.control.styles.style"
                                  [ngClass]="filters.to_date.control.styles.classes"
                                  [tooltip]="filters.to_date.control.tooltip">
                              </app-datebox>
                              <ng-container *ngIf="filters.to_date.invalid">
                                <ng-container *ngFor="let error of filters.to_date.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-warehouse" *ngIf="!filters.warehouse.hidden && !filters.warehouse.removed" 
                                  class="field-container standard {{filters.warehouse.invalid ? 'invalid' : ''}} {{filters.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.warehouse.styles.style"
                                  [ngClass]="filters.warehouse.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouse.label + (filters.warehouse.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouse.label}}<span *ngIf="filters.warehouse.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Locations-warehouses_dd_single 
                                  data-cy="selector"
                                  [type]="filters.warehouse.control.type"
                                  formControlName="warehouse"
                                  (displayTextChange)="filters.warehouse.control.displayText=$event"
                                  [placeholder]="filters.warehouse.control.placeholder"
                                  [styles]="filters.warehouse.control.styles"
                                  [tooltip]="filters.warehouse.control.tooltip"
                              >
                              </Locations-warehouses_dd_single>
                              <ng-container *ngIf="filters.warehouse.invalid">
                                <ng-container *ngFor="let error of filters.warehouse.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-operation_codes" *ngIf="!filters.operation_codes.hidden && !filters.operation_codes.removed" 
                                  class="field-container standard {{filters.operation_codes.invalid ? 'invalid' : ''}} {{filters.operation_codes.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.operation_codes.styles.style"
                                  [ngClass]="filters.operation_codes.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.operation_codes.label + (filters.operation_codes.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.operation_codes.label}}<span *ngIf="filters.operation_codes.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Inventory-operationcodes_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.operation_codes.control.type"
                                  formControlName="operation_codes"
                                  (displayTextChange)="filters.operation_codes.control.displayText=$event"
                                  [placeholder]="filters.operation_codes.control.placeholder"
                                  [styles]="filters.operation_codes.control.styles"
                                  [tooltip]="filters.operation_codes.control.tooltip"
                              >
                              </Inventory-operationcodes_dd_multi>
                              <ng-container *ngIf="filters.operation_codes.invalid">
                                <ng-container *ngFor="let error of filters.operation_codes.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
              
                          <div class="fieldset-details" *ngIf="!filtersets.filters.expanded">
              
              
                          </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.report.hasTabToShow" class="tab" data-cy="tab-report">
              <h2 [className]="tabs.report.active? 'active': ''" (click)="tabs.report.activate()">{{tabs.report.title}}</h2>
            </div>
          </div>
        
              <Reports-custom_user_activity_report *ngIf="tabs.report.active"
              #$tabs_report
              [from_date]="$tabs_report_custom_user_activity_report_inParams_from_date"
              [to_date]="$tabs_report_custom_user_activity_report_inParams_to_date"
              [warehouse_id]="$tabs_report_custom_user_activity_report_inParams_warehouse_id"
              [operation_code_ids]="$tabs_report_custom_user_activity_report_inParams_operation_code_ids"
              ($refreshEvent)="refresh(false, false, '$tabs_report')">
              </Reports-custom_user_activity_report>
        </div>

      </ng-container>
    </div>
  </div>
</div>