import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { app_custom_dock_appointments_hub_ComponentContextService } from './app.custom_dock_appointments_hub.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_dock_appointments_calendarComponent } from './FootprintManager.dock_appointments_calendar.component';
import { FootprintManager_dock_appointments_gridComponent } from './FootprintManager.dock_appointments_grid.component';
import { FootprintManager_dock_appointments_dashboard_gridComponent } from './FootprintManager.dock_appointments_dashboard_grid.component';
import { app_custom_dock_status_gridComponent } from './app.custom_dock_status_grid.component';
import { DockAppointments_total_appt_checked_in_by_date_widgetComponent } from './DockAppointments.total_appt_checked_in_by_date_widget.component';
import { DockAppointments_total_appt_not_checked_in_by_date_widgetComponent } from './DockAppointments.total_appt_not_checked_in_by_date_widget.component';
import { ExcelMaterialImport_owners_dd_singleComponent } from './ExcelMaterialImport.owners_dd_single.component'
import { ExcelMaterialImport_projects_dd_singleComponent } from './ExcelMaterialImport.projects_dd_single.component'
import { Locations_warehouses_dd_multiComponent } from './Locations.warehouses_dd_multi.component'
import { Carriers_carriers_dd_singleComponent } from './Carriers.carriers_dd_single.component'
import { DockAppointments_dock_appointment_types_dd_multiComponent } from './DockAppointments.dock_appointment_types_dd_multi.component'
import { DockAppointments_dock_appointment_statuses_multiComponent } from './DockAppointments.dock_appointment_statuses_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_dock_appointments_calendarComponent),
    forwardRef(() => FootprintManager_dock_appointments_gridComponent),
    forwardRef(() => FootprintManager_dock_appointments_dashboard_gridComponent),
    forwardRef(() => app_custom_dock_status_gridComponent),
    forwardRef(() => DockAppointments_total_appt_checked_in_by_date_widgetComponent),
    forwardRef(() => DockAppointments_total_appt_not_checked_in_by_date_widgetComponent),
    forwardRef(() => ExcelMaterialImport_owners_dd_singleComponent),
    forwardRef(() => ExcelMaterialImport_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_multiComponent),
    forwardRef(() => Carriers_carriers_dd_singleComponent),
    forwardRef(() => DockAppointments_dock_appointment_types_dd_multiComponent),
    forwardRef(() => DockAppointments_dock_appointment_statuses_multiComponent),
  ],
  selector: 'app-custom_dock_appointments_hub',
  templateUrl: './app.custom_dock_appointments_hub.component.html'
})
export class app_custom_dock_appointments_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { currentDate?: string, appointmentList_fromDate?: string, appointmentList_toDate?: string, appointmentList_dockDoorIds?: number[], calendar_viewDate?: string, calendar_dockDoorIds?: number[], timeZoneDates?: { timeZoneId?: string, viewDate?: string }[] } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouses: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      manage_dock_doors: new ToolModel(new ButtonModel('manage_dock_doors', new ButtonStyles(null, null), false, false, false, 'Manage dock doors', 'ms-Icon ms-Icon--SettingsAdd', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    warehouses: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouses'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouses', false, false),
    carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Carrier', false, false),
    type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['type'] as DatexFormControl, 
  ESelectBoxType.chips, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Type', false, false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  ESelectBoxType.chips, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
  };


  filtersets = {
    newGroup1: new FieldsetModel(
      'Filters',
      false,
      true,
      false,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      dock_appointments_calendar: new TabItemModel(
        this.rootTabGroup, 
        'Calendar', 
        false,
        ),
      appointment_list: new TabItemModel(
        this.rootTabGroup, 
        'Appointments list', 
        false,
        ),
      dock_appointment_dashboard: new TabItemModel(
        this.rootTabGroup, 
        'Orders dashboard', 
        false,
        ),
      dock_status: new TabItemModel(
        this.rootTabGroup, 
        'Dock Status', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_warehouseIds: number[];
    get $tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_warehouseIds;
    }
  
    cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_typeIds: number[];
    get $tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_typeIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.type.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_typeIds, expr)) {
        this.cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_typeIds = expr;
      }
      return this.cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_typeIds;
    }
  
    get $tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_carrierId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.carrier.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_statusIds: number[];
    get $tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_statusIds, expr)) {
        this.cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_statusIds;
    }
  
    cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_dockDoorIds: number[];
    get $tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_dockDoorIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.calendar_dockDoorIds;
      
      if(!isEqual(this.cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_dockDoorIds, expr)) {
        this.cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_dockDoorIds = expr;
      }
      return this.cacheValueFor_$tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_dockDoorIds;
    }
  
    get $tabs_dock_appointments_calendar_dock_appointments_calendar_inParams_viewDate(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.calendar_viewDate;
      
      return expr;
    }
  
    cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_typeIds: number[];
    get $tabs_appointment_list_dock_appointments_grid_inParams_typeIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.type.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_typeIds, expr)) {
        this.cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_typeIds = expr;
      }
      return this.cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_typeIds;
    }
  
    get $tabs_appointment_list_dock_appointments_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_appointment_list_dock_appointments_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_appointment_list_dock_appointments_grid_inParams_carrierId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.carrier.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_statusIds: number[];
    get $tabs_appointment_list_dock_appointments_grid_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_statusIds, expr)) {
        this.cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_statusIds;
    }
  
    cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_warehouseIds: number[];
    get $tabs_appointment_list_dock_appointments_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_warehouseIds;
    }
  
    get $tabs_appointment_list_dock_appointments_grid_inParams_fromDate(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.appointmentList_fromDate;
      
      return expr;
    }
  
    get $tabs_appointment_list_dock_appointments_grid_inParams_toDate(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.appointmentList_toDate;
      
      return expr;
    }
  
    cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_dockDoorIds: number[];
    get $tabs_appointment_list_dock_appointments_grid_inParams_dockDoorIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.appointmentList_dockDoorIds;
      
      if(!isEqual(this.cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_dockDoorIds, expr)) {
        this.cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_dockDoorIds = expr;
      }
      return this.cacheValueFor_$tabs_appointment_list_dock_appointments_grid_inParams_dockDoorIds;
    }
  
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds: number[];
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds;
    }
  
    cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds: number[];
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.type.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds, expr)) {
        this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds = expr;
      }
      return this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds;
    }
  
    cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_statusIds: number[];
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_statusIds, expr)) {
        this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_statusIds;
    }
  
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_fromDate(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.appointmentList_fromDate;
      
      return expr;
    }
  
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_toDate(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.appointmentList_toDate;
      
      return expr;
    }
  
    cacheValueFor_$tabs_dock_status_custom_dock_status_grid_inParams_warehouse_ids: number[];
    get $tabs_dock_status_custom_dock_status_grid_inParams_warehouse_ids(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_dock_status_custom_dock_status_grid_inParams_warehouse_ids, expr)) {
        this.cacheValueFor_$tabs_dock_status_custom_dock_status_grid_inParams_warehouse_ids = expr;
      }
      return this.cacheValueFor_$tabs_dock_status_custom_dock_status_grid_inParams_warehouse_ids;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_dock_appointments_calendar', { read: FootprintManager_dock_appointments_calendarComponent }) $tabs_dock_appointments_calendar: FootprintManager_dock_appointments_calendarComponent;
      @ViewChild('$tabs_appointment_list', { read: FootprintManager_dock_appointments_gridComponent }) $tabs_appointment_list: FootprintManager_dock_appointments_gridComponent;
      @ViewChild('$tabs_dock_appointment_dashboard', { read: FootprintManager_dock_appointments_dashboard_gridComponent }) $tabs_dock_appointment_dashboard: FootprintManager_dock_appointments_dashboard_gridComponent;
      @ViewChild('$tabs_dock_status', { read: app_custom_dock_status_gridComponent }) $tabs_dock_status: app_custom_dock_status_gridComponent;
    //#endregion tabs children
    widgets = {
      total_appt_checked_in_by_date_widget: new WidgetModel(false),
      total_appt_not_checked_in_by_date_widget: new WidgetModel(false),
    };
  
    //#region widgets inParams
    cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_typeIds: number[];
    get $widgets_total_appt_checked_in_by_date_widget_inParams_typeIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.type.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_typeIds, expr)) {
        this.cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_typeIds = expr;
      }
      return this.cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_typeIds;
    }
  
    get $widgets_total_appt_checked_in_by_date_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_total_appt_checked_in_by_date_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $widgets_total_appt_checked_in_by_date_widget_inParams_carrierId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.carrier.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_statusIds: number[];
    get $widgets_total_appt_checked_in_by_date_widget_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_statusIds, expr)) {
        this.cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_statusIds;
    }
  
    cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_warehouseIds: number[];
    get $widgets_total_appt_checked_in_by_date_widget_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_warehouseIds;
    }
  
    cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_timeZoneDates: { timeZoneId?: string, viewDate?: string }[];
    get $widgets_total_appt_checked_in_by_date_widget_inParams_timeZoneDates(): { timeZoneId?: string, viewDate?: string }[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.timeZoneDates as {timeZoneId: string, viewDate: string}[];
      
      if(!isEqual(this.cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_timeZoneDates, expr)) {
        this.cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_timeZoneDates = expr;
      }
      return this.cacheValueFor_$widgets_total_appt_checked_in_by_date_widget_inParams_timeZoneDates;
    }
  
    cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_typeIds: number[];
    get $widgets_total_appt_not_checked_in_by_date_widget_inParams_typeIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.type.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_typeIds, expr)) {
        this.cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_typeIds = expr;
      }
      return this.cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_typeIds;
    }
  
    get $widgets_total_appt_not_checked_in_by_date_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_total_appt_not_checked_in_by_date_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $widgets_total_appt_not_checked_in_by_date_widget_inParams_carrierId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.carrier.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_statusIds: number[];
    get $widgets_total_appt_not_checked_in_by_date_widget_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_statusIds, expr)) {
        this.cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_statusIds;
    }
  
    cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_warehouseIds: number[];
    get $widgets_total_appt_not_checked_in_by_date_widget_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_warehouseIds;
    }
  
    cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_timeZoneDates: { timeZoneId?: string, viewDate?: string }[];
    get $widgets_total_appt_not_checked_in_by_date_widget_inParams_timeZoneDates(): { timeZoneId?: string, viewDate?: string }[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.timeZoneDates as {timeZoneId: string, viewDate: string}[];
      
      if(!isEqual(this.cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_timeZoneDates, expr)) {
        this.cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_timeZoneDates = expr;
      }
      return this.cacheValueFor_$widgets_total_appt_not_checked_in_by_date_widget_inParams_timeZoneDates;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_total_appt_checked_in_by_date_widget', { read:  DockAppointments_total_appt_checked_in_by_date_widgetComponent }) $widgets_total_appt_checked_in_by_date_widget: DockAppointments_total_appt_checked_in_by_date_widgetComponent;
      @ViewChild('$widgets_total_appt_not_checked_in_by_date_widget', { read:  DockAppointments_total_appt_not_checked_in_by_date_widgetComponent }) $widgets_total_appt_not_checked_in_by_date_widget: DockAppointments_total_appt_not_checked_in_by_date_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_owner_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner.control.value;
    
    return expr;
  }



  cacheValueFor_$fields_status_selector_inParams_statusIds: number[];
  get $fields_status_selector_inParams_statusIds(): number[] {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = [0,1,2,3,4];
    
    if(!isEqual(this.cacheValueFor_$fields_status_selector_inParams_statusIds, expr)) {
      this.cacheValueFor_$fields_status_selector_inParams_statusIds = expr;
    }
    return this.cacheValueFor_$fields_status_selector_inParams_statusIds;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Dock appointments hub`;
  }


  constructor(
  private $utils: UtilsService,
  private $settings: SettingsValuesService,
  private $shell: app_ShellService,
  private $datasources: app_DatasourceService,
  private $flows: app_FlowService,
  private $reports: app_ReportService,
  private $localization: app_LocalizationService,
  private $operations: app_OperationService,
  private $logger: CleanupLoggerService,
  private $context: app_custom_dock_appointments_hub_ComponentContextService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.dock_appointments_calendar,
      this.tabs.appointment_list,
      this.tabs.dock_appointment_dashboard,
      this.tabs.dock_status,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Custom Dock Appointments Hub';
    
    const $hub = this;
    const $utils = this.$utils;

    (this.filters.type.control as SelectBoxModel).reset([1,2,3,4,5,6,7,8,9,10]);
    (this.filters.status.control as SelectBoxModel).reset([0,1,2,3]);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['owner']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.formGroup
      .controls['project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.formGroup
      .controls['warehouses']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_warehouse_change();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_total_appt_checked_in_by_date_widget') {
      if (!isNil(this.$widgets_total_appt_checked_in_by_date_widget) && !this.widgets.total_appt_checked_in_by_date_widget.hidden) {
        this.$widgets_total_appt_checked_in_by_date_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_total_appt_not_checked_in_by_date_widget') {
      if (!isNil(this.$widgets_total_appt_not_checked_in_by_date_widget) && !this.widgets.total_appt_not_checked_in_by_date_widget.hidden) {
        this.$widgets_total_appt_not_checked_in_by_date_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_dock_appointments_calendar') {
      if (!isNil(this.$tabs_dock_appointments_calendar) && !this.tabs.dock_appointments_calendar.hidden) {
        this.$tabs_dock_appointments_calendar.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_appointment_list') {
      if (!isNil(this.$tabs_appointment_list) && !this.tabs.appointment_list.hidden) {
        this.$tabs_appointment_list.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_dock_appointment_dashboard') {
      if (!isNil(this.$tabs_dock_appointment_dashboard) && !this.tabs.dock_appointment_dashboard.hidden) {
        this.$tabs_dock_appointment_dashboard.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_dock_status') {
      if (!isNil(this.$tabs_dock_status) && !this.tabs.dock_status.hidden) {
        this.$tabs_dock_status.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $hub: app_custom_dock_appointments_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_dock_appointments_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'custom_dock_appointments_hub.on_owner_change');
  $hub.filters.project.control.value = null;
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $hub: app_custom_dock_appointments_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_dock_appointments_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'custom_dock_appointments_hub.on_project_change');
  const projectId = $hub.filters.project.control.value
  
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($hub.filters.owner.control.value)) {
      const project = (await $datasources.DockAppointments.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
  
          $hub.filters.owner.control.value = project[0].OwnerId;
  
      }
  
  }
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: app_custom_dock_appointments_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_dock_appointments_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'custom_dock_appointments_hub.on_init');
  $hub.vars.currentDate = $utils.date.now();
  
  
  if ((await $operations.FootprintManager.Enable_Locations_DefaultWarehouseByActivity.isAuthorized())) {
      let username = (await $flows.Utilities.get_username_flow({})).userName;
  
      let defaultWarehouseResult = (await $datasources.Locations.ds_get_default_warehouse_by_user.get({ userName: username })).result;
  
      $hub.filters.warehouses.control.value = $utils.isDefined(defaultWarehouseResult?.warehouseId) ? [defaultWarehouseResult.warehouseId] : null;
  
  }
  }
  on_appointment_list_filters_updated(event = null) {
    return this.on_appointment_list_filters_updatedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_list_filters_updatedInternal(
    $hub: app_custom_dock_appointments_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_dock_appointments_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'custom_dock_appointments_hub.on_appointment_list_filters_updated');
  // Set Grid Variables to updated filter values
  $hub.vars.appointmentList_fromDate = $event.fromDate;
  $hub.vars.appointmentList_toDate = $event.toDate;
  $hub.vars.appointmentList_dockDoorIds = $event.dockDoorIds;
  }
  on_calendar_filters_updated(event = null) {
    return this.on_calendar_filters_updatedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_calendar_filters_updatedInternal(
    $hub: app_custom_dock_appointments_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_dock_appointments_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'custom_dock_appointments_hub.on_calendar_filters_updated');
  // Update the hub variables with the updated filter values
  $hub.vars.calendar_viewDate = $event.filters.viewDate;
  $hub.vars.calendar_dockDoorIds = $event.filters.dockDoorIds
  }
  on_warehouse_change(event = null) {
    return this.on_warehouse_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_warehouse_changeInternal(
    $hub: app_custom_dock_appointments_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_dock_appointments_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'custom_dock_appointments_hub.on_warehouse_change');
  // Clear the dockDoor variables
  $hub.vars.appointmentList_dockDoorIds = null;
  $hub.vars.calendar_dockDoorIds = null;
  
  
  // Get timezone dates
  if ($utils.isDefined($hub.filters.warehouses.control.value)) {
      let timeZoneDates = (await $flows.DockAppointments.convert_dates_by_warehouse_timezones({ warehouseIds: $hub.filters.warehouses.control.value, dates: { currentDate: $utils.date.startOf('day', $utils.date.now()) } })).timeZoneDates;
      $hub.vars.timeZoneDates = timeZoneDates.map(tz => ({ timeZoneId: tz.timeZoneId, viewDate: tz.dates.currentDate }));
  }
  else {
      $hub.vars.timeZoneDates = [];
  }
  }
  on_manage_dock_door_clicked(event = null) {
    return this.on_manage_dock_door_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_dock_door_clickedInternal(
    $hub: app_custom_dock_appointments_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_dock_appointments_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'custom_dock_appointments_hub.on_manage_dock_door_clicked');
  
  
  const dialogResult = await $shell.FootprintManager.opendock_doors_hub({
      warehouseIds: $hub.filters.warehouses.control.value
  
  });
  
  
  
  }
  //#endregion private flows
}
