import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_update_vendor_lotService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { vendor_lot_id: number, update: $frontendTypes.FootprintApiManager.input_update_vendor_lot }): Promise<{ vendor_lot?: $frontendTypes.FootprintApiManager.public_vendor_lot }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.vendor_lot_id)) {
      missingRequiredInParams.push('\'vendor_lot_id\'');
    }
    if (isNil(inParams.update)) {
      missingRequiredInParams.push('\'update\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootprintApiManager/functions/update_vendor_lot`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

