import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { FootprintApiManager_insert_alert_formComponent } from './FootprintApiManager.insert_alert_form.component';
import { FootprintApiManager_insert_log_formComponent } from './FootprintApiManager.insert_log_form.component';
import { FootprintApiManager_asn_order_editorComponent } from './FootprintApiManager.asn_order_editor.component';
import { FootprintApiManager_footprint_api_runnerComponent } from './FootprintApiManager.footprint_api_runner.component';
import { FootprintApiManager_purchase_order_editorComponent } from './FootprintApiManager.purchase_order_editor.component';
import { FootprintApiManager_sales_order_editorComponent } from './FootprintApiManager.sales_order_editor.component';
import { FootprintApiManager_adjustment_tasks_gridComponent } from './FootprintApiManager.adjustment_tasks_grid.component';
import { FootprintApiManager_adjustments_gridComponent } from './FootprintApiManager.adjustments_grid.component';
import { FootprintApiManager_base_order_lines_gridComponent } from './FootprintApiManager.base_order_lines_grid.component';
import { FootprintApiManager_configurations_gridComponent } from './FootprintApiManager.configurations_grid.component';
import { FootprintApiManager_control_gridComponent } from './FootprintApiManager.control_grid.component';
import { FootprintApiManager_integrations_gridComponent } from './FootprintApiManager.integrations_grid.component';
import { FootprintApiManager_lifecycle_gridComponent } from './FootprintApiManager.lifecycle_grid.component';
import { FootprintApiManager_logs_gridComponent } from './FootprintApiManager.logs_grid.component';
import { FootprintApiManager_messages_gridComponent } from './FootprintApiManager.messages_grid.component';
import { FootprintApiManager_processes_gridComponent } from './FootprintApiManager.processes_grid.component';
import { FootprintApiManager_statuses_gridComponent } from './FootprintApiManager.statuses_grid.component';
import { FootprintApiManager_transaction_types_gridComponent } from './FootprintApiManager.transaction_types_grid.component';
import { FootprintApiManager_footprint_api_hubComponent } from './FootprintApiManager.footprint_api_hub.component';
import { FootprintApiManager_transaction_hubComponent } from './FootprintApiManager.transaction_hub.component';
import { FootprintApiManager_log_notes_reportComponent } from './FootprintApiManager.log_notes_report.component';
import { FootprintApiManager_transaction_reportComponent } from './FootprintApiManager.transaction_report.component';
import { FootprintApiManager_messages_json_payloadComponent } from './FootprintApiManager.messages_json_payload.component';
import { FootprintApiManager_view_json_payloadComponent } from './FootprintApiManager.view_json_payload.component';
import { FootprintApiManager_view_xml_payloadComponent } from './FootprintApiManager.view_xml_payload.component';
import { FootprintApiManager_directions_dd_singleComponent } from './FootprintApiManager.directions_dd_single.component';
import { FootprintApiManager_integrations_dd_singleComponent } from './FootprintApiManager.integrations_dd_single.component';
import { FootprintApiManager_message_properties_singleComponent } from './FootprintApiManager.message_properties_single.component';
import { FootprintApiManager_processes_dd_singleComponent } from './FootprintApiManager.processes_dd_single.component';
import { FootprintApiManager_statuses_dd_singleComponent } from './FootprintApiManager.statuses_dd_single.component';
import { FootprintApiManager_transaction_types_dd_singleComponent } from './FootprintApiManager.transaction_types_dd_single.component';
import { FootprintApiManager_directions_dd_multiComponent } from './FootprintApiManager.directions_dd_multi.component';
import { FootprintApiManager_integrations_dd_multiComponent } from './FootprintApiManager.integrations_dd_multi.component';
import { FootprintApiManager_message_properties_multiComponent } from './FootprintApiManager.message_properties_multi.component';
import { FootprintApiManager_processes_dd_multiComponent } from './FootprintApiManager.processes_dd_multi.component';
import { FootprintApiManager_statuses_dd_multiComponent } from './FootprintApiManager.statuses_dd_multi.component';
import { FootprintApiManager_transaction_types_dd_multiComponent } from './FootprintApiManager.transaction_types_dd_multi.component';
import { FootprintApiManager_control_partners_directions_widgetComponent } from './FootprintApiManager.control_partners_directions_widget.component';
import { FootprintApiManager_message_statuses_widgetComponent } from './FootprintApiManager.message_statuses_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';
import { Addresses_ShellService } from './Addresses.shell.service';
import { Owners_ShellService } from './Owners.shell.service';
import { Instructions_ShellService } from './Instructions.shell.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { Materials_ShellService } from './Materials.shell.service';
import { Carriers_ShellService } from './Carriers.shell.service';
import { PurchaseOrders_ShellService } from './PurchaseOrders.shell.service';
import { SalesOrders_ShellService } from './SalesOrders.shell.service';
import { SerialNumbers_ShellService } from './SerialNumbers.shell.service';
import { Inventory_ShellService } from './Inventory.shell.service';
import { Attachments_ShellService } from './Attachments.shell.service';

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
    public Addresses: Addresses_ShellService,
    public Owners: Owners_ShellService,
    public Instructions: Instructions_ShellService,
    public Notifications: Notifications_ShellService,
    public Materials: Materials_ShellService,
    public Carriers: Carriers_ShellService,
    public PurchaseOrders: PurchaseOrders_ShellService,
    public SalesOrders: SalesOrders_ShellService,
    public SerialNumbers: SerialNumbers_ShellService,
    public Inventory: Inventory_ShellService,
    public Attachments: Attachments_ShellService,
  ) {
    super(dialog, toastr);
  }

  public FootprintApiManager: FootprintApiManager_ShellService = this;

  public openinsert_alert_form(inParams:{ alert?: any }, replaceCurrentView?: boolean) {
    this.$logger.log('FootprintApiManager', 'insert_alert_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'insert_alert_form',
        referenceName: 'FootprintApiManager_insert_alert_form',
        component: FootprintApiManager_insert_alert_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinsert_alert_formDialog(
    inParams:{ alert?: any }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('FootprintApiManager', 'insert_alert_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_insert_alert_formComponent,
      'insert_alert_form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinsert_log_form(replaceCurrentView?: boolean) {
    this.$logger.log('FootprintApiManager', 'insert_log_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'insert_log_form',
        referenceName: 'FootprintApiManager_insert_log_form',
        component: FootprintApiManager_insert_log_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinsert_log_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('FootprintApiManager', 'insert_log_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_insert_log_formComponent,
      'insert_log_form',
      mode,
      dialogSize
    )
  }
  public openasn_order_editor(inParams:{ order_id: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Edit ASN order',
        referenceName: 'FootprintApiManager_asn_order_editor',
        component: FootprintApiManager_asn_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openasn_order_editorDialog(
    inParams:{ order_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_asn_order_editorComponent,
      'Edit ASN order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openfootprint_api_runner(inParams:{ title?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Runner',
        referenceName: 'FootprintApiManager_footprint_api_runner',
        component: FootprintApiManager_footprint_api_runnerComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openfootprint_api_runnerDialog(
    inParams:{ title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_footprint_api_runnerComponent,
      'Runner',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpurchase_order_editor(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Edit purchase order',
        referenceName: 'FootprintApiManager_purchase_order_editor',
        component: FootprintApiManager_purchase_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpurchase_order_editorDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_purchase_order_editorComponent,
      'Edit purchase order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opensales_order_editor(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Edit sales order',
        referenceName: 'FootprintApiManager_sales_order_editor',
        component: FootprintApiManager_sales_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opensales_order_editorDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_sales_order_editorComponent,
      'Edit sales order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openadjustment_tasks_grid(inParams:{ hasTransmissions?: boolean, project?: string, warehouse?: string, material?: string, taskIds?: number[], take?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('FootprintApiManager', 'adjustment_tasks_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Adjustment tasks grid',
        referenceName: 'FootprintApiManager_adjustment_tasks_grid',
        component: FootprintApiManager_adjustment_tasks_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openadjustment_tasks_gridDialog(
    inParams:{ hasTransmissions?: boolean, project?: string, warehouse?: string, material?: string, taskIds?: number[], take?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('FootprintApiManager', 'adjustment_tasks_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_adjustment_tasks_gridComponent,
      'Adjustment tasks grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openadjustments_grid(replaceCurrentView?: boolean) {
    this.$logger.log('FootprintApiManager', 'adjustments_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Adjustments grid',
        referenceName: 'FootprintApiManager_adjustments_grid',
        component: FootprintApiManager_adjustments_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openadjustments_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('FootprintApiManager', 'adjustments_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_adjustments_gridComponent,
      'Adjustments grid',
      mode,
      dialogSize
    )
  }
  public openbase_order_lines_grid(inParams:{ order_id: number }, replaceCurrentView?: boolean) {
    this.$logger.log('FootprintApiManager', 'base_order_lines_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Base orderlines grid ',
        referenceName: 'FootprintApiManager_base_order_lines_grid',
        component: FootprintApiManager_base_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openbase_order_lines_gridDialog(
    inParams:{ order_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('FootprintApiManager', 'base_order_lines_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_base_order_lines_gridComponent,
      'Base orderlines grid ',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openconfigurations_grid(inParams:{ integration_name?: string, user_name?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Configurations grid',
        referenceName: 'FootprintApiManager_configurations_grid',
        component: FootprintApiManager_configurations_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openconfigurations_gridDialog(
    inParams:{ integration_name?: string, user_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_configurations_gridComponent,
      'Configurations grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencontrol_grid(inParams:{ integration_name?: string, user_name?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Control grid',
        referenceName: 'FootprintApiManager_control_grid',
        component: FootprintApiManager_control_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencontrol_gridDialog(
    inParams:{ integration_name?: string, user_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_control_gridComponent,
      'Control grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openintegrations_grid(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Integrations grid',
        referenceName: 'FootprintApiManager_integrations_grid',
        component: FootprintApiManager_integrations_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openintegrations_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_integrations_gridComponent,
      'Integrations grid',
      mode,
      dialogSize
    )
  }
  public openlifecycle_grid(inParams:{ integration_name?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Lifecycle grid',
        referenceName: 'FootprintApiManager_lifecycle_grid',
        component: FootprintApiManager_lifecycle_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlifecycle_gridDialog(
    inParams:{ integration_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_lifecycle_gridComponent,
      'Lifecycle grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlogs_grid(inParams:{ transaction_id?: string, integration_name?: string, ingress_id?: string, egress_id?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Logs grid',
        referenceName: 'FootprintApiManager_logs_grid',
        component: FootprintApiManager_logs_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlogs_gridDialog(
    inParams:{ transaction_id?: string, integration_name?: string, ingress_id?: string, egress_id?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_logs_gridComponent,
      'Logs grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmessages_grid(inParams:{ transaction_id?: string, integration_name?: string, ingress_id?: string, eggress_id?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Messages grid',
        referenceName: 'FootprintApiManager_messages_grid',
        component: FootprintApiManager_messages_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmessages_gridDialog(
    inParams:{ transaction_id?: string, integration_name?: string, ingress_id?: string, eggress_id?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_messages_gridComponent,
      'Messages grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openprocesses_grid(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Processes grid',
        referenceName: 'FootprintApiManager_processes_grid',
        component: FootprintApiManager_processes_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openprocesses_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_processes_gridComponent,
      'Processes grid',
      mode,
      dialogSize
    )
  }
  public openstatuses_grid(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Statuses grid',
        referenceName: 'FootprintApiManager_statuses_grid',
        component: FootprintApiManager_statuses_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openstatuses_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_statuses_gridComponent,
      'Statuses grid',
      mode,
      dialogSize
    )
  }
  public opentransaction_types_grid(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Transaction types grid',
        referenceName: 'FootprintApiManager_transaction_types_grid',
        component: FootprintApiManager_transaction_types_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opentransaction_types_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_transaction_types_gridComponent,
      'Transaction types grid',
      mode,
      dialogSize
    )
  }
  public openfootprint_api_hub(inParams:{ integration_name?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'API hub',
        referenceName: 'FootprintApiManager_footprint_api_hub',
        component: FootprintApiManager_footprint_api_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openfootprint_api_hubDialog(
    inParams:{ integration_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_footprint_api_hubComponent,
      'API hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransaction_hub(inParams:{ transaction_id?: string, activate_grid?: string, ingress_id?: string, egress_id?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'transaction_hub',
        referenceName: 'FootprintApiManager_transaction_hub',
        component: FootprintApiManager_transaction_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransaction_hubDialog(
    inParams:{ transaction_id?: string, activate_grid?: string, ingress_id?: string, egress_id?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_transaction_hubComponent,
      'transaction_hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlog_notes_report(inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] }, replaceCurrentView?: boolean) {
    this.$logger.log('FootprintApiManager', 'log_notes_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'log_notes_report',
        referenceName: 'FootprintApiManager_log_notes_report',
        component: FootprintApiManager_log_notes_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlog_notes_reportDialog(
    inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('FootprintApiManager', 'log_notes_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootprintApiManager_log_notes_reportComponent,
      'log_notes_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransaction_report(inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] }, replaceCurrentView?: boolean) {
    this.$logger.log('FootprintApiManager', 'transaction_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'transaction_report',
        referenceName: 'FootprintApiManager_transaction_report',
        component: FootprintApiManager_transaction_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransaction_reportDialog(
    inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('FootprintApiManager', 'transaction_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      FootprintApiManager_transaction_reportComponent,
      'transaction_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmessages_json_payload(inParams:{ payload?: any, title?: string, id?: string, integration_name?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'messages_json_payload',
        referenceName: 'FootprintApiManager_messages_json_payload',
        component: FootprintApiManager_messages_json_payloadComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmessages_json_payloadDialog(
    inParams:{ payload?: any, title?: string, id?: string, integration_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_messages_json_payloadComponent,
      'messages_json_payload',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openview_json_payload(inParams:{ payload?: any, title?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'view_json_payload',
        referenceName: 'FootprintApiManager_view_json_payload',
        component: FootprintApiManager_view_json_payloadComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_json_payloadDialog(
    inParams:{ payload?: any, title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_view_json_payloadComponent,
      'view_json_payload',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openview_xml_payload(inParams:{ payload?: any, title?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'view_xml_payload',
        referenceName: 'FootprintApiManager_view_xml_payload',
        component: FootprintApiManager_view_xml_payloadComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_xml_payloadDialog(
    inParams:{ payload?: any, title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      FootprintApiManager_view_xml_payloadComponent,
      'view_xml_payload',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'FootprintApiManager_insert_alert_form') {
      this.$logger.log('FootprintApiManager', 'insert_alert_form');
      const title = 'insert_alert_form';
      const component = FootprintApiManager_insert_alert_formComponent;
      const inParams:{ alert?: any } = { alert: null };
      if (!isNil(params.get('alert'))) {
        const paramValueString = params.get('alert');
        inParams.alert = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_insert_log_form') {
      this.$logger.log('FootprintApiManager', 'insert_log_form');
      const title = 'insert_log_form';
      const component = FootprintApiManager_insert_log_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_asn_order_editor') {
      const title = 'Edit ASN order';
      const component = FootprintApiManager_asn_order_editorComponent;
      const inParams:{ order_id: number } = { order_id: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_footprint_api_runner') {
      const title = 'Runner';
      const component = FootprintApiManager_footprint_api_runnerComponent;
      const inParams:{ title?: string } = { title: null };
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_purchase_order_editor') {
      const title = 'Edit purchase order';
      const component = FootprintApiManager_purchase_order_editorComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_sales_order_editor') {
      const title = 'Edit sales order';
      const component = FootprintApiManager_sales_order_editorComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_adjustment_tasks_grid') {
      this.$logger.log('FootprintApiManager', 'adjustment_tasks_grid');
      const title = 'Adjustment tasks grid';
      const component = FootprintApiManager_adjustment_tasks_gridComponent;
      const inParams:{ hasTransmissions?: boolean, project?: string, warehouse?: string, material?: string, taskIds?: number[], take?: number } = { hasTransmissions: null, project: null, warehouse: null, material: null, taskIds: [], take: null };
      if (!isNil(params.get('hasTransmissions'))) {
        const paramValueString = params.get('hasTransmissions');
        inParams.hasTransmissions = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('project'))) {
        const paramValueString = params.get('project');
        // TODO: date
        inParams.project = paramValueString;
              }
      if (!isNil(params.get('warehouse'))) {
        const paramValueString = params.get('warehouse');
        // TODO: date
        inParams.warehouse = paramValueString;
              }
      if (!isNil(params.get('material'))) {
        const paramValueString = params.get('material');
        // TODO: date
        inParams.material = paramValueString;
              }
      if (!isNil(params.get('taskIds'))) {
        const paramValueString = params.get('taskIds');
        inParams.taskIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('take'))) {
        const paramValueString = params.get('take');
        inParams.take = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_adjustments_grid') {
      this.$logger.log('FootprintApiManager', 'adjustments_grid');
      const title = 'Adjustments grid';
      const component = FootprintApiManager_adjustments_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_base_order_lines_grid') {
      this.$logger.log('FootprintApiManager', 'base_order_lines_grid');
      const title = 'Base orderlines grid ';
      const component = FootprintApiManager_base_order_lines_gridComponent;
      const inParams:{ order_id: number } = { order_id: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_configurations_grid') {
      const title = 'Configurations grid';
      const component = FootprintApiManager_configurations_gridComponent;
      const inParams:{ integration_name?: string, user_name?: string } = { integration_name: null, user_name: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }
      if (!isNil(params.get('user_name'))) {
        const paramValueString = params.get('user_name');
        // TODO: date
        inParams.user_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_control_grid') {
      const title = 'Control grid';
      const component = FootprintApiManager_control_gridComponent;
      const inParams:{ integration_name?: string, user_name?: string } = { integration_name: null, user_name: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }
      if (!isNil(params.get('user_name'))) {
        const paramValueString = params.get('user_name');
        // TODO: date
        inParams.user_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_integrations_grid') {
      const title = 'Integrations grid';
      const component = FootprintApiManager_integrations_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_lifecycle_grid') {
      const title = 'Lifecycle grid';
      const component = FootprintApiManager_lifecycle_gridComponent;
      const inParams:{ integration_name?: string } = { integration_name: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_logs_grid') {
      const title = 'Logs grid';
      const component = FootprintApiManager_logs_gridComponent;
      const inParams:{ transaction_id?: string, integration_name?: string, ingress_id?: string, egress_id?: string } = { transaction_id: null, integration_name: null, ingress_id: null, egress_id: null };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }
      if (!isNil(params.get('ingress_id'))) {
        const paramValueString = params.get('ingress_id');
        // TODO: date
        inParams.ingress_id = paramValueString;
              }
      if (!isNil(params.get('egress_id'))) {
        const paramValueString = params.get('egress_id');
        // TODO: date
        inParams.egress_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_messages_grid') {
      const title = 'Messages grid';
      const component = FootprintApiManager_messages_gridComponent;
      const inParams:{ transaction_id?: string, integration_name?: string, ingress_id?: string, eggress_id?: string } = { transaction_id: null, integration_name: null, ingress_id: null, eggress_id: null };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }
      if (!isNil(params.get('ingress_id'))) {
        const paramValueString = params.get('ingress_id');
        // TODO: date
        inParams.ingress_id = paramValueString;
              }
      if (!isNil(params.get('eggress_id'))) {
        const paramValueString = params.get('eggress_id');
        // TODO: date
        inParams.eggress_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_processes_grid') {
      const title = 'Processes grid';
      const component = FootprintApiManager_processes_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_statuses_grid') {
      const title = 'Statuses grid';
      const component = FootprintApiManager_statuses_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_transaction_types_grid') {
      const title = 'Transaction types grid';
      const component = FootprintApiManager_transaction_types_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_footprint_api_hub') {
      const title = 'API hub';
      const component = FootprintApiManager_footprint_api_hubComponent;
      const inParams:{ integration_name?: string } = { integration_name: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_transaction_hub') {
      const title = 'transaction_hub';
      const component = FootprintApiManager_transaction_hubComponent;
      const inParams:{ transaction_id?: string, activate_grid?: string, ingress_id?: string, egress_id?: string } = { transaction_id: null, activate_grid: null, ingress_id: null, egress_id: null };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('activate_grid'))) {
        const paramValueString = params.get('activate_grid');
        // TODO: date
        inParams.activate_grid = paramValueString;
              }
      if (!isNil(params.get('ingress_id'))) {
        const paramValueString = params.get('ingress_id');
        // TODO: date
        inParams.ingress_id = paramValueString;
              }
      if (!isNil(params.get('egress_id'))) {
        const paramValueString = params.get('egress_id');
        // TODO: date
        inParams.egress_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_log_notes_report') {
      this.$logger.log('FootprintApiManager', 'log_notes_report');
      const title = 'log_notes_report';
      const component = FootprintApiManager_log_notes_reportComponent;
      const inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] } = { transaction_id: null, log_ids: [], message_ids: [] };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('log_ids'))) {
        const paramValueString = params.get('log_ids');
        inParams.log_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('message_ids'))) {
        const paramValueString = params.get('message_ids');
        inParams.message_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_transaction_report') {
      this.$logger.log('FootprintApiManager', 'transaction_report');
      const title = 'transaction_report';
      const component = FootprintApiManager_transaction_reportComponent;
      const inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] } = { transaction_id: null, log_ids: [], message_ids: [] };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('log_ids'))) {
        const paramValueString = params.get('log_ids');
        inParams.log_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('message_ids'))) {
        const paramValueString = params.get('message_ids');
        inParams.message_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_messages_json_payload') {
      const title = 'messages_json_payload';
      const component = FootprintApiManager_messages_json_payloadComponent;
      const inParams:{ payload?: any, title?: string, id?: string, integration_name?: string } = { payload: null, title: null, id: null, integration_name: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        // TODO: date
        inParams.id = paramValueString;
              }
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_view_json_payload') {
      const title = 'view_json_payload';
      const component = FootprintApiManager_view_json_payloadComponent;
      const inParams:{ payload?: any, title?: string } = { payload: null, title: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_view_xml_payload') {
      const title = 'view_xml_payload';
      const component = FootprintApiManager_view_xml_payloadComponent;
      const inParams:{ payload?: any, title?: string } = { payload: null, title: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_directions_dd_single') {
      const title = 'directions_dd';
      const component = FootprintApiManager_directions_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_integrations_dd_single') {
      const title = 'integrations_dd';
      const component = FootprintApiManager_integrations_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_message_properties_single') {
      this.$logger.log('FootprintApiManager', 'message_properties_single');
      const title = 'message_properties';
      const component = FootprintApiManager_message_properties_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_processes_dd_single') {
      const title = 'processes_dd';
      const component = FootprintApiManager_processes_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_statuses_dd_single') {
      const title = 'statuses_dd';
      const component = FootprintApiManager_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_transaction_types_dd_single') {
      const title = 'transaction_types_dd';
      const component = FootprintApiManager_transaction_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_directions_dd_multi') {
      const title = 'directions_dd';
      const component = FootprintApiManager_directions_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_integrations_dd_multi') {
      const title = 'integrations_dd';
      const component = FootprintApiManager_integrations_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_message_properties_multi') {
      this.$logger.log('FootprintApiManager', 'message_properties_multi');
      const title = 'message_properties';
      const component = FootprintApiManager_message_properties_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_processes_dd_multi') {
      const title = 'processes_dd';
      const component = FootprintApiManager_processes_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_statuses_dd_multi') {
      const title = 'statuses_dd';
      const component = FootprintApiManager_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_transaction_types_dd_multi') {
      const title = 'transaction_types_dd';
      const component = FootprintApiManager_transaction_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_control_partners_directions_widget') {
      const title = 'Total in/out transactions';
      const component = FootprintApiManager_control_partners_directions_widgetComponent;
      const inParams:{ integration_name?: string } = { integration_name: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'FootprintApiManager_message_statuses_widget') {
      const title = 'Message statuses';
      const component = FootprintApiManager_message_statuses_widgetComponent;
      const inParams:{ integration_name?: string } = { integration_name: null };
      if (!isNil(params.get('integration_name'))) {
        const paramValueString = params.get('integration_name');
        // TODO: date
        inParams.integration_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Addresses.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Owners.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Instructions.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Notifications.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Materials.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Carriers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PurchaseOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SalesOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SerialNumbers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Inventory.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Attachments.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
