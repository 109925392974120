<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"


[rowCanExpand]="true"
>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="return_to_stock" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_return_to_stock_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator1" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="undo_confirmation" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_undo_production_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="finished_good">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.finished_good.displayControl.styles.style"
          [ngClass]="row.cells.finished_good.displayControl.styles.classes"
          [matTooltip]="row.cells.finished_good.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.finished_good.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="produced">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.produced.displayControl.styles.style"
          [ngClass]="row.cells.produced.displayControl.styles.classes"
          [matTooltip]="row.cells.produced.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.produced.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="license_plate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.license_plate.displayControl.styles.style"
          [ngClass]="row.cells.license_plate.displayControl.styles.classes"
          [matTooltip]="row.cells.license_plate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.license_plate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="completed_on">

    <ng-template gridCellDisplayControlDef let-row>
    <app-datebox 
        data-cy="dateBox"
        [formControl]="row.formGroup.controls['completed_on_display']"
        [format]="row.cells.completed_on.displayControl.format"
        [mode]="row.cells.completed_on.displayControl.mode"
        [ngStyle]="row.cells.completed_on.displayControl.styles.style"
        [ngClass]="row.cells.completed_on.displayControl.styles.classes"
        [tooltip]="row.cells.completed_on.displayControl.tooltip">
    </app-datebox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot_expiration">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot_expiration.displayControl.styles.style"
          [ngClass]="row.cells.lot_expiration.displayControl.styles.classes"
          [matTooltip]="row.cells.lot_expiration.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot_expiration.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="workshift">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.workshift.displayControl.styles.style"
          [ngClass]="row.cells.workshift.displayControl.styles.classes"
          [matTooltip]="row.cells.workshift.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.workshift.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <FootprintManager-manufacturing_orders_manufacturing_production_components_grid
    [manufacturing_order_id]="row.$rowExpand_FootprintManager_manufacturing_orders_manufacturing_production_components_grid_inParams_manufacturing_order_id"
    [manufacturing_order_line_id]="row.$rowExpand_FootprintManager_manufacturing_orders_manufacturing_production_components_grid_inParams_manufacturing_order_line_id"
    [warehouse_id]="row.$rowExpand_FootprintManager_manufacturing_orders_manufacturing_production_components_grid_inParams_warehouse_id"
    [task_chain_head]="row.$rowExpand_FootprintManager_manufacturing_orders_manufacturing_production_components_grid_inParams_task_chain_head"
    ($refreshEvent)="row.refresh()">>
  </FootprintManager-manufacturing_orders_manufacturing_production_components_grid>
</ng-template>

</datex-grid>
