import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintApiManager_ShellService } from './FootprintApiManager.shell.service';
import { FootprintApiManager_OperationService } from './FootprintApiManager.operation.service';
import { FootprintApiManager_DatasourceService } from './FootprintApiManager.datasource.index';
import { FootprintApiManager_FlowService } from './FootprintApiManager.flow.index';
import { FootprintApiManager_ReportService } from './FootprintApiManager.report.index';
import { FootprintApiManager_LocalizationService } from './FootprintApiManager.localization.service';
import { FootprintApiManager_configurations_grid_ComponentContextService } from './FootprintApiManager.configurations_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintApiManager.frontend.types'
import { $frontendTypes as $types} from './FootprintApiManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintApiManager_configurations_gridComponentEntity {
  id?: string, name?: string, value?: string, notes?: string, created_by?: string, created_on?: string, modified_by?: string, modified_on?: string, integration_name?: string, user_name?: string}

interface IFootprintApiManager_configurations_gridComponentInParams {
  integration_name?: string, user_name?: string}


class FootprintApiManager_configurations_gridComponentRowModel extends GridRowModel {
  grid: FootprintApiManager_configurations_gridComponent;
  entity: IFootprintApiManager_configurations_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    integration_name_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    value_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    id: null,
    integration_name: null,
    user_name: null,
    name: null,
    value: null,
    notes: null,
    created_by: null,
    created_on: null,
    modified_by: null,
    modified_on: null,
  }



  constructor(
    grid: FootprintApiManager_configurations_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintApiManager_ShellService,
private $datasources: FootprintApiManager_DatasourceService,
private $flows: FootprintApiManager_FlowService,
private $reports: FootprintApiManager_ReportService,
private $localization: FootprintApiManager_LocalizationService,
private $operations: FootprintApiManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintApiManager_configurations_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.id = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.id,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.integration_name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.integration_name,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['integration_name_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.user_name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.user_name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.name,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['name_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.value = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.value,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['value_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.notes = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.notes,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['notes_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.created_by = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.created_by,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.created_on = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.created_on,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.modified_by = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.modified_by,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.modified_on = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.modified_on,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintApiManager_configurations_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintApiManager_configurations_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      name:  null ,
      notes:  $grid.fullTextSearch ,
      integration_name:  $grid.inParams.integration_name ,
      user_name:  $grid.inParams.user_name ,
    };
    const data = await this.$datasources.FootprintApiManager.ds_storage_configurations.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintApiManager_configurations_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.id.displayControl as TextModel).text = $row.entity.id;
    (this.cells.integration_name.displayControl as TextModel).text = $row.entity.integration_name;
    (this.cells.integration_name.editControl as TextBoxModel).reset($row.entity.integration_name);
    (this.cells.user_name.displayControl as TextModel).text = $row.entity.user_name;
    (this.cells.name.displayControl as TextModel).text = $row.entity.name;
    (this.cells.name.editControl as TextBoxModel).reset($row.entity.name);
    (this.cells.value.editControl as TextBoxModel).reset($row.entity.value);
    (this.cells.notes.editControl as TextBoxModel).reset($row.entity.notes);
    (this.cells.created_by.displayControl as TextModel).text = $row.entity.created_by;
    (this.cells.modified_by.displayControl as TextModel).text = $row.entity.modified_by;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_existing_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintApiManager_configurations_gridComponentRowModel,
  $grid: FootprintApiManager_configurations_gridComponent, 
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_configurations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.name.editControl.value) &&
      $utils.isDefined($row.cells.value.editControl.value)
  
  if (allRequiredFieldHaveValue === false) {
      $shell.FootprintApiManager.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  };
  
  if ($row.cells.name.editControl.isChanged || $row.cells.value.editControl.isChanged || $row.cells.notes.editControl.isChanged || $row.cells.integration_name.editControl.isChanged) {
  
      let encrypt: boolean = $grid.filters.encrypt.control.value ?? false;
  
      let config_id: string = $row.entity?.id ?? "";
      let config_name: string = $row.cells.name.editControl.value;
      let config_value: string = $row.cells.value.editControl.value;
      let config_notes: string = $row.cells.notes.editControl.value;
      let integration_name: string = $grid.inParams.integration_name ?? "";
      let user_name: string = $grid.inParams.user_name ?? "";
  
      if (integration_name === "") {
          integration_name = $row.cells.integration_name.editControl.value ?? "";
      };
  
      try {
  
          if (config_id === "" && integration_name === "") {
              throw new Error("You need to specify the integration to insert a configuration! Please select it from the grid drop-down!")
          };
  
          await $flows.FootprintApiManager.get_configuration({
              id: config_id,
              name: config_name,
              value: config_value,
              notes: config_notes,
              encrypt: encrypt,
              integration_name: integration_name,
              user_name: user_name
          });
  
          await $grid.refresh();
  
      } catch (error) {
          $shell.FootprintApiManager.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      };
  };
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintApiManager_configurations_gridComponentRowModel,
  $grid: FootprintApiManager_configurations_gridComponent, 
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_configurations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 02/13/2024
  
  const format = `MM/DD/YYYY, LT`;
  
  if ($utils.isDefined(format)) {
      if ($utils.isDefined($row.entity.created_on)) {
          $row.cells.created_on.displayControl.text = $utils.date.format($row.entity.created_on, format);
      };
      if ($utils.isDefined($row.entity.modified_on)) {
          $row.cells.modified_on.displayControl.text = $utils.date.format($row.entity.modified_on, format);
      };
  };
  
  let notes = $row.entity.notes;
  
  if ($utils.isDefined(notes)) {
      notes = (notes.length > 75) ? notes.substring(0, 75) : notes;
      $row.cells.notes.displayControl.text = notes;
  };
  
  let value = $row.entity.value;
  
  if ($utils.isDefined(value)) {
      value = (value.length > 75) ? value.substring(0, 75) : value;
      $row.cells.value.displayControl.text = value;
  };
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintApiManager-configurations_grid',
  templateUrl: './FootprintApiManager.configurations_grid.component.html'
})
export class FootprintApiManager_configurations_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintApiManager_configurations_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintApiManager_configurations_gridComponentInParams = { integration_name: null, user_name: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     id: new GridHeaderModel(new HeaderStyles(null, null), 'id', 'ID', false, false, null, false),       integration_name: new GridHeaderModel(new HeaderStyles(null, null), 'integration_name', 'Integration name', false, false, null, false),       user_name: new GridHeaderModel(new HeaderStyles(null, null), 'user_name', 'User name', false, false, null, false),       name: new GridHeaderModel(new HeaderStyles(null, null), 'name', 'Name', true, false, null, false),       value: new GridHeaderModel(new HeaderStyles(null, null), 'value', 'Value', true, false, null, false),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'notes', 'Notes', false, false, null, false),       created_by: new GridHeaderModel(new HeaderStyles(null, null), 'created_by', 'Created by', false, false, null, false),       created_on: new GridHeaderModel(new HeaderStyles(null, null), 'created_on', 'Created on', false, false, null, false),       modified_by: new GridHeaderModel(new HeaderStyles(null, null), 'modified_by', 'Modified by', false, false, null, false),       modified_on: new GridHeaderModel(new HeaderStyles(null, null), 'modified_on', 'Modified on', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintApiManager_configurations_gridComponentRowModel[] = [];

  @Input('integration_name') set $inParams_integration_name(value: any) {
    this.inParams['integration_name'] = value;
  }
  get $inParams_integration_name(): any {
    return this.inParams['integration_name'] ;
  }
  @Input('user_name') set $inParams_user_name(value: any) {
    this.inParams['user_name'] = value;
  }
  get $inParams_user_name(): any {
    return this.inParams['user_name'] ;
  }

  topToolbar = {
      delete_setting: new ToolModel(new ButtonModel('delete_setting', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };

  formGroup: FormGroup = new FormGroup({
    encrypt: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  filters = {
    encrypt: new FieldModel(new CheckBoxModel(this.formGroup.controls['encrypt'] as DatexFormControl, null, false, false, 'Encrypt Value', null)
, new ControlContainerStyles(null, null), '', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams


  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintApiManager_ShellService,
    private $datasources: FootprintApiManager_DatasourceService,
    private $flows: FootprintApiManager_FlowService,
    private $reports: FootprintApiManager_ReportService,
    private $localization: FootprintApiManager_LocalizationService,
    private $operations: FootprintApiManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintApiManager_configurations_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Configurations grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      name:  null ,
      notes:  $grid.fullTextSearch ,
      integration_name:  $grid.inParams.integration_name ,
      user_name:  $grid.inParams.user_name ,
    };
    try {
      const data = await this.$datasources.FootprintApiManager.ds_storage_configurations.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintApiManager_configurations_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintApiManager_configurations_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintApiManager_configurations_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: IFootprintApiManager_configurations_gridComponentEntity) {
    const row = new FootprintApiManager_configurations_gridComponentRowModel(
      this,
      this.$utils,
      this.$settings,
      this.$shell, 
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$localization,
      this.$operations,
      this.$logger,
      this.$context);
    await row.$initializeNew(entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintApiManager_configurations_gridComponent,
  
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_configurations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/14/2024
  
  $grid.title = 'FootPrint API Manager Settings Grid'
  $grid.headers.id.hidden = true;
  
  let integration_name: string = $grid.inParams.integration_name ?? "";
  
  if (integration_name.toUpperCase() === "NULL") {
      integration_name = "";
  };
  
  if (integration_name !== "") {
      $grid.headers.integration_name.hidden = true;
  };
  
  let user_name: string = $grid.inParams.user_name ?? "";
  
  if (user_name.toUpperCase() === "NULL") {
      user_name = "";
  };
  
  if (user_name !== "") {
      $grid.headers.user_name.hidden = true;
  };
  
  if (await $operations.FootprintApiManager.Enable_ApiManager_ConfigurationsGrid.isAuthorized()) {
      //Do nothing
  } else {
      $grid.headers.name.hidden = true;
      $grid.headers.value.hidden = true;
      $grid.headers.notes.hidden = true;
      $grid.headers.created_by.hidden = true;
      $grid.headers.created_on.hidden = true;
      $grid.headers.modified_by.hidden = true;
      $grid.headers.modified_on.hidden = true;
      $grid.headers.integration_name.hidden = true;
      $shell.FootprintApiManager.openErrorDialog("Not authorized", "You are not authorized to view the FootPrint API Manager configurations.")
  };
  
  $grid.canEdit = false;
  $grid.headers.id.hidden = true;
  
  $grid.topToolbar.delete_setting.hidden = true;
  $grid.topToolbar.delete_setting.control.readOnly = true;
  
  if (await $operations.FootprintApiManager.Enable_ApiManager_ConfigurationsGrid.isAuthorized()) {
      $grid.topToolbar.delete_setting.hidden = false;
      $grid.canEdit = true;
  };
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootprintApiManager_configurations_gridComponent,
  
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_configurations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/13/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { name: string, id: string, errorMessages: string[] }[] = [];
  let resubmitted_records = [];
  
  let logs = (await $flows.FootprintApiManager.storage_logs({action: "", integration_name: ""})).payload;
  let log_payload: typeof logs[0];
  
  let messages = (await $flows.FootprintApiManager.storage_configurations({action: "", integration_name: ""})).payload;
  let message_payload: typeof messages[0];
  
  if (selected_rows.length === 0) {
      $shell.FootprintApiManager.openErrorDialog('Delete error', 'No records were selected.');
      return;
  } else {
  
      const confirm_purge = (await $shell.FootprintApiManager.openConfirmationDialog(`Confirm delete`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let current_error: string;
              let success = false;
              current_error = null;
  
              try {
  
                  let entity = row.entity
  
                  message_payload = {
                      "id": entity.id
                  };
  
                  await $flows.FootprintApiManager.storage_configurations({ action: "Delete", payload: message_payload, integration_name: row.entity.integration_name });
  
                  log_payload = {
                      "process": "message_grid",
                      "level": 1,
                      "notes": `Deleted setting Name: [${row.entity.name}] Value: [${row.entity.value}] Notes: [${row.entity.notes}]`
                  };
  
                  await $flows.FootprintApiManager.storage_logs({ action: "Write", payload: log_payload, integration_name: row.entity.integration_name });
  
                  success = true;
  
              } catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
                  if (!$utils.isDefined(target_error.message)) {
                      target_error = { message: `Uncaught exception. ${JSON.stringify(target_error)}` };
                  };
  
                  current_error = target_error.message;
              };
  
              if (success) {
                  resubmitted_records.push(row.entity.id.toString())
              } else {
                  errors.push({
                      name: row.entity.name, id: row.entity.id, errorMessages: [current_error]
                  });
              };
          };
  
          if ($utils.isDefined(errors)) {
  
              await $shell.FootprintApiManager.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  resubmitted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: resubmitted_records })).formattedValue} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id} Name: ${error.name}` })))
          };
      };
  };
  
  $grid.refresh();
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintApiManager_configurations_gridComponent,
  
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_configurations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/31/2024
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_setting.control.readOnly = (selectedRowsCount > 0 ? false : true);
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootprintApiManager_configurations_gridComponent,
  
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_configurations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/14/2024
  
  let filter: string[] = [];
  
  filter = $grid.rows.filter(r => $utils.isDefined(r.entity.user_name))?.map(r => r.entity.id);
  if (filter.length === 0) {
      $grid.headers.user_name.hidden = true;
  };
  
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: FootprintApiManager_configurations_gridComponent,
  
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_configurations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
  if ($grid.rows.length > 0) {
  
      let ids: string[] = [];
  
      if ($utils.isDefined($grid.selectedRows)) {
          if ($grid.selectedRows.length > 0) {
              ids = $grid.selectedRows.map(o => o.entity.id);
          };
      };
  
      let notes = $grid.fullTextSearch;
      let integration_name = $grid.inParams.integration_name;
  
      let data = (await $datasources.FootprintApiManager.ds_storage_configurations.getList({
          notes: notes,
          integration_name: integration_name
      }));
  
      if (data.totalCount > 0) {
  
          let result = data.result;
  
          if (ids.length > 0) {
              result = result.filter(r => ids.indexOf(r.id) >= 0);
          };
  
          let configurations = result.map(r => {
              return {
                  "Integration Name": r.integration_name,
                  "User Name": r.user_name,
                  "Name": r.name,
                  "Value": r.value,
                  "Notes": r.notes.substring(0,30000),
                  "Created By": r.created_by,
                  "Created On (UTC)": r.created_on,
                  "Modified By": r.modified_by,
                  "Modified On (UTC)": r.modified_on
              }
          });
  
          let wb = $event;
          let ws = $utils.excel.json_to_sheet(configurations);
          $utils.excel.book_append_sheet(wb, ws, "Configurations");
  
      } else {
          $shell.FootprintApiManager.openInfoDialog('Error', 'The parameters did not return any records to export.')
      };
  } else {
      $shell.FootprintApiManager.openInfoDialog('Error', 'There are no records to export.')
  };
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'FootprintApiManager_configurations_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
