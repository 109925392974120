import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { app_custom_dock_appointment_check_in_options_form_ComponentContextService } from './app.custom_dock_appointment_check_in_options_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { DockAppointments_dock_doors_dd_singleComponent } from './DockAppointments.dock_doors_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => DockAppointments_dock_doors_dd_singleComponent),
  ],
  selector: 'app-custom_dock_appointment_check_in_options_form',
  templateUrl: './app.custom_dock_appointment_check_in_options_form.component.html'
})
export class app_custom_dock_appointment_check_in_options_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { title?: string, dockAppointmentId: number, warehouseId: number, scheduledDockDoorId?: number, appointmentType?: string } = { title: null, dockAppointmentId: null, warehouseId: null, scheduledDockDoorId: null, appointmentType: null };
  //#region Inputs
  @Input('title') set $inParams_title(v: string) {
    this.inParams.title = v;
  }
  get $inParams_title(): string {
    return this.inParams.title;
  }
  @Input('dockAppointmentId') set $inParams_dockAppointmentId(v: number) {
    this.inParams.dockAppointmentId = v;
  }
  get $inParams_dockAppointmentId(): number {
    return this.inParams.dockAppointmentId;
  }
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  @Input('scheduledDockDoorId') set $inParams_scheduledDockDoorId(v: number) {
    this.inParams.scheduledDockDoorId = v;
  }
  get $inParams_scheduledDockDoorId(): number {
    return this.inParams.scheduledDockDoorId;
  }
  @Input('appointmentType') set $inParams_appointmentType(v: string) {
    this.inParams.appointmentType = v;
  }
  get $inParams_appointmentType(): string {
    return this.inParams.appointmentType;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean, scheduledDoorId?: number, assignedDoorId?: number, driverName?: string, driverLicense?: string, vehicleLicense?: string } = { confirm: null, scheduledDoorId: null, assignedDoorId: null, driverName: null, driverLicense: null, vehicleLicense: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean, scheduledDoorId?: number, assignedDoorId?: number, driverName?: string, driverLicense?: string, vehicleLicense?: string }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    scheduled_dock_door: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    assigned_dock_door: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    driver_name: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    driver_license: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vehicle_license: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    title: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    scheduled_dock_door: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['scheduled_dock_door'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Scheduled dock door', false, false),
    assigned_dock_door: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['assigned_dock_door'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Assigned dock door', false, false),
    driver_name: new FieldModel(new TextBoxModel(this.formGroup.controls['driver_name'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Carrier name', false, false),
    driver_license: new FieldModel(new TextBoxModel(this.formGroup.controls['driver_license'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Driver phone number', false, false),
    vehicle_license: new FieldModel(new TextBoxModel(this.formGroup.controls['vehicle_license'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Trailer number', false, false),
  };

  fieldsets = {
    newGroup1: new FieldsetModel(
      '',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  cacheValueFor_$fields_scheduled_dock_door_selector_inParams_warehouseIds: number[];
  get $fields_scheduled_dock_door_selector_inParams_warehouseIds(): number[] {
    const $form = this;
    const $utils = this.$utils;
    const expr = [$form.inParams.warehouseId];
    
    if(!isEqual(this.cacheValueFor_$fields_scheduled_dock_door_selector_inParams_warehouseIds, expr)) {
      this.cacheValueFor_$fields_scheduled_dock_door_selector_inParams_warehouseIds = expr;
    }
    return this.cacheValueFor_$fields_scheduled_dock_door_selector_inParams_warehouseIds;
  }

  get $fields_scheduled_dock_door_selector_inParams_appointmentType(): string {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.inParams.appointmentType;
    
    return expr;
  }

  cacheValueFor_$fields_assigned_dock_door_selector_inParams_warehouseIds: number[];
  get $fields_assigned_dock_door_selector_inParams_warehouseIds(): number[] {
    const $form = this;
    const $utils = this.$utils;
    const expr = [$form.inParams.warehouseId];
    
    if(!isEqual(this.cacheValueFor_$fields_assigned_dock_door_selector_inParams_warehouseIds, expr)) {
      this.cacheValueFor_$fields_assigned_dock_door_selector_inParams_warehouseIds = expr;
    }
    return this.cacheValueFor_$fields_assigned_dock_door_selector_inParams_warehouseIds;
  }

  get $fields_assigned_dock_door_selector_inParams_appointmentType(): string {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.inParams.appointmentType;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    scheduled_dock_door: this.fields.scheduled_dock_door.control.valueChanges
    ,
    assigned_dock_door: this.fields.assigned_dock_door.control.valueChanges
    ,
    driver_name: this.fields.driver_name.control.valueChanges
    ,
    driver_license: this.fields.driver_license.control.valueChanges
    ,
    vehicle_license: this.fields.vehicle_license.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: app_ShellService,
private $datasources: app_DatasourceService,
private $flows: app_FlowService,
private $reports: app_ReportService,
private $localization: app_LocalizationService,
private $operations: app_OperationService,
private $logger: CleanupLoggerService,
private $context: app_custom_dock_appointment_check_in_options_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.dockAppointmentId)) {
        this.$missingRequiredInParams.push('dockAppointmentId');
      }
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Dock Appointment Check In';
  
    const $form = this;
    const $utils = this.$utils;

    (this.fields.scheduled_dock_door.control as SelectBoxModel).reset($form.inParams.scheduledDockDoorId);
    
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .scheduled_dock_door
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .assigned_dock_door
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .driver_name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .driver_license
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .vehicle_license
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: app_custom_dock_appointment_check_in_options_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_dock_appointment_check_in_options_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'custom_dock_appointment_check_in_options_form.on_cancel_clicked');
  
  $form.outParams.confirm = false;
  $form.close();
  
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: app_custom_dock_appointment_check_in_options_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_dock_appointment_check_in_options_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'custom_dock_appointment_check_in_options_form.on_confirm_clicked');
  // Handle double click scenario
  $form.toolbar.confirm.control.readOnly = true;
  
  
  $form.outParams.scheduledDoorId = $form.fields.scheduled_dock_door.control.value;
  $form.outParams.assignedDoorId = $form.fields.assigned_dock_door.control.value;
  $form.outParams.driverName = $form.fields.driver_name.control.value;
  $form.outParams.driverLicense = $form.fields.driver_license.control.value;
  $form.outParams.vehicleLicense = $form.fields.vehicle_license.control.value;
  
  $form.outParams.confirm = true;
  
  $form.events.outParamsChange.emit();
  
  
  $form.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: app_custom_dock_appointment_check_in_options_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_dock_appointment_check_in_options_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'custom_dock_appointment_check_in_options_form.on_init');
  $form.outParams.confirm = false;
  
  var title;
  
  if ($utils.isDefined($form.inParams.title)) {
      title = $form.inParams.title;
  }
  else {
      title = 'Check in appointment?';
  }
  
  
  // Set title field
  $form.title = title;
  
  // Set focus on scheduled door field
  $form.fields.scheduled_dock_door.control.focus();
  
  
  }
  //#endregion private flows
}
