import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_inventory_activity_reportServiceInParams {
  from_date: string, to_date: string, warehouse_ids: number[], project_ids: number[], material_ids?: number[], vendor_lot_ids?: number[]}

interface IReports_custom_inventory_activity_reportServiceData {
  InventoryActivity?: { result?: { object?: { completed_date_time?: string, operation_code?: string, owner_reference?: string, materialLookupCode?: string, vendorLotLookupCode?: string, actualPackagedAmount?: number, packageUOM?: string, materialDescription?: string, orderAccount?: string, address?: string, city?: string, state?: string, Country?: string, ExpirationDate?: string, licenseplate_lookup?: string, shipping_container_lookup?: string, netWeight?: number, grossWeight?: number, warehouseName?: string, projectName?: string, taskId?: number }[] } }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_inventory_activity_reportService extends ReportBaseService<IReports_custom_inventory_activity_reportServiceInParams, IReports_custom_inventory_activity_reportServiceData> {

  protected reportReferenceName = 'custom_inventory_activity_report';
  protected appReferenceName = 'Reports';

  constructor(
    $utils: UtilsService,
    private $datasources: Reports_DatasourceService,
    private $logger: CleanupLoggerService
    ) {
      super($utils);
      $logger.log('Reports', 'custom_inventory_activity_report');
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_inventory_activity_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_inventory_activity_reportServiceInParams): Promise<IReports_custom_inventory_activity_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IReports_custom_inventory_activity_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_inventory_activity_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        from_date:  $report.inParams.from_date ,
        to_date:  $report.inParams.to_date ,
        warehouse_ids:  $report.inParams.warehouse_ids ,
        project_ids:  $report.inParams.project_ids ,
        material_ids:  $report.inParams.material_ids ,
        vendor_lot_ids:  $report.inParams.vendor_lot_ids 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_inventory_activity_report_flow.get(dsParams);
      
      data.InventoryActivity = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
