import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintApiManager_ShellService } from './FootprintApiManager.shell.service';
import { FootprintApiManager_OperationService } from './FootprintApiManager.operation.service';
import { FootprintApiManager_DatasourceService } from './FootprintApiManager.datasource.index';
import { FootprintApiManager_FlowService } from './FootprintApiManager.flow.index';
import { FootprintApiManager_ReportService } from './FootprintApiManager.report.index';
import { FootprintApiManager_LocalizationService } from './FootprintApiManager.localization.service';
import { FootprintApiManager_footprint_api_hub_ComponentContextService } from './FootprintApiManager.footprint_api_hub.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintApiManager.frontend.types'
import { $frontendTypes as $types} from './FootprintApiManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintApiManager_lifecycle_gridComponent } from './FootprintApiManager.lifecycle_grid.component';
import { FootprintApiManager_messages_gridComponent } from './FootprintApiManager.messages_grid.component';
import { FootprintApiManager_logs_gridComponent } from './FootprintApiManager.logs_grid.component';
import { FootprintApiManager_configurations_gridComponent } from './FootprintApiManager.configurations_grid.component';
import { FootprintApiManager_control_gridComponent } from './FootprintApiManager.control_grid.component';
import { Notifications_alerts_gridComponent } from './Notifications.alerts_grid.component';
import { FootprintApiManager_integrations_gridComponent } from './FootprintApiManager.integrations_grid.component';
import { FootprintApiManager_processes_gridComponent } from './FootprintApiManager.processes_grid.component';
import { FootprintApiManager_statuses_gridComponent } from './FootprintApiManager.statuses_grid.component';
import { FootprintApiManager_transaction_types_gridComponent } from './FootprintApiManager.transaction_types_grid.component';
import { FootprintApiManager_message_statuses_widgetComponent } from './FootprintApiManager.message_statuses_widget.component';
import { FootprintApiManager_control_partners_directions_widgetComponent } from './FootprintApiManager.control_partners_directions_widget.component';
import { FootprintApiManager_integrations_dd_singleComponent } from './FootprintApiManager.integrations_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintApiManager_lifecycle_gridComponent),
    forwardRef(() => FootprintApiManager_messages_gridComponent),
    forwardRef(() => FootprintApiManager_logs_gridComponent),
    forwardRef(() => FootprintApiManager_configurations_gridComponent),
    forwardRef(() => FootprintApiManager_control_gridComponent),
    forwardRef(() => Notifications_alerts_gridComponent),
    forwardRef(() => FootprintApiManager_integrations_gridComponent),
    forwardRef(() => FootprintApiManager_processes_gridComponent),
    forwardRef(() => FootprintApiManager_statuses_gridComponent),
    forwardRef(() => FootprintApiManager_transaction_types_gridComponent),
    forwardRef(() => FootprintApiManager_message_statuses_widgetComponent),
    forwardRef(() => FootprintApiManager_control_partners_directions_widgetComponent),
    forwardRef(() => FootprintApiManager_integrations_dd_singleComponent),
  ],
  selector: 'FootprintApiManager-footprint_api_hub',
  templateUrl: './FootprintApiManager.footprint_api_hub.component.html'
})
export class FootprintApiManager_footprint_api_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { integration_name?: string } = { integration_name: null };
  //#region Inputs
  @Input('integration_name') set $inParams_integration_name(v: string) {
    this.inParams.integration_name = v;
  }
  get $inParams_integration_name(): string {
    return this.inParams.integration_name;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    integration_name: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      runner: new ToolModel(new ButtonModel('runner', new ButtonStyles(null, null), false, false, false, 'Run manually', 'icon-ic_fluent_person_running_20_regular', null)
    , false),
      refresh_dropdowns: new ToolModel(new ButtonModel('refresh_dropdowns', new ButtonStyles(['creation'], null), false, false, false, 'Refresh drop downs', 'icon-ic_fluent_arrow_clockwise_20_regular', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    integration_name: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['integration_name'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), '', false, false),
  };


  filtersets = {
    integration: new FieldsetModel(
      'Integration',
      false,
      true,
      true,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      lifecycle: new TabItemModel(
        this.rootTabGroup, 
        'Lifecycle', 
        false,
        ),
      messages: new TabItemModel(
        this.rootTabGroup, 
        'Messages', 
        false,
        ),
      logs: new TabItemModel(
        this.rootTabGroup, 
        'Logs', 
        false,
        ),
      configurations: new TabItemModel(
        this.rootTabGroup, 
        'Configurations', 
        false,
        ),
      control: new TabItemModel(
        this.rootTabGroup, 
        'Control', 
        false,
        ),
      alerts: new TabItemModel(
        this.rootTabGroup, 
        'Alerts', 
        false,
        ),
      integrations: new TabItemModel(
        this.rootTabGroup, 
        'Integrations', 
        false,
        ),
      processes: new TabItemModel(
        this.rootTabGroup, 
        'Processes', 
        false,
        ),
      statuses: new TabItemModel(
        this.rootTabGroup, 
        'Statuses', 
        false,
        ),
      transaction_types: new TabItemModel(
        this.rootTabGroup, 
        'Transaction types', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_lifecycle_lifecycle_grid_inParams_integration_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.integration_name.control.value;
      
      return expr;
    }
  
    get $tabs_messages_messages_grid_inParams_integration_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.integration_name.control.value;
      
      return expr;
    }
  
    get $tabs_logs_logs_grid_inParams_integration_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.integration_name.control.value;
      
      return expr;
    }
  
    get $tabs_configurations_configurations_grid_inParams_integration_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.integration_name.control.value;
      
      return expr;
    }
  
    get $tabs_control_control_grid_inParams_integration_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.integration_name.control.value;
      
      return expr;
    }
  
    get $tabs_alerts_alerts_grid_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.integration_name.control.value;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_lifecycle', { read: FootprintApiManager_lifecycle_gridComponent }) $tabs_lifecycle: FootprintApiManager_lifecycle_gridComponent;
      @ViewChild('$tabs_messages', { read: FootprintApiManager_messages_gridComponent }) $tabs_messages: FootprintApiManager_messages_gridComponent;
      @ViewChild('$tabs_logs', { read: FootprintApiManager_logs_gridComponent }) $tabs_logs: FootprintApiManager_logs_gridComponent;
      @ViewChild('$tabs_configurations', { read: FootprintApiManager_configurations_gridComponent }) $tabs_configurations: FootprintApiManager_configurations_gridComponent;
      @ViewChild('$tabs_control', { read: FootprintApiManager_control_gridComponent }) $tabs_control: FootprintApiManager_control_gridComponent;
      @ViewChild('$tabs_alerts', { read: Notifications_alerts_gridComponent }) $tabs_alerts: Notifications_alerts_gridComponent;
      @ViewChild('$tabs_integrations', { read: FootprintApiManager_integrations_gridComponent }) $tabs_integrations: FootprintApiManager_integrations_gridComponent;
      @ViewChild('$tabs_processes', { read: FootprintApiManager_processes_gridComponent }) $tabs_processes: FootprintApiManager_processes_gridComponent;
      @ViewChild('$tabs_statuses', { read: FootprintApiManager_statuses_gridComponent }) $tabs_statuses: FootprintApiManager_statuses_gridComponent;
      @ViewChild('$tabs_transaction_types', { read: FootprintApiManager_transaction_types_gridComponent }) $tabs_transaction_types: FootprintApiManager_transaction_types_gridComponent;
    //#endregion tabs children
    widgets = {
      message_statuses: new WidgetModel(false),
      in_out_totals: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_message_statuses_inParams_integration_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.integration_name.control.value;
      
      return expr;
    }
  
    get $widgets_in_out_totals_inParams_integration_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.integration_name.control.value;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_message_statuses', { read:  FootprintApiManager_message_statuses_widgetComponent }) $widgets_message_statuses: FootprintApiManager_message_statuses_widgetComponent;
      @ViewChild('$widgets_in_out_totals', { read:  FootprintApiManager_control_partners_directions_widgetComponent }) $widgets_in_out_totals: FootprintApiManager_control_partners_directions_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `${($hub.inParams.integration_name != null && $hub.inParams.integration_name != 'undefined' && $hub.inParams.integration_name?.toLowerCase() !== 'null' && $hub.inParams.integration_name?.trim() !== '') ? `${$hub.inParams.integration_name} Hub` : "Footprint API Hub"}`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `This is where we can see messages, logs, and run the ${($hub.inParams.integration_name != null && $hub.inParams.integration_name != 'undefined' && $hub.inParams.integration_name?.toLowerCase() !== 'null' && $hub.inParams.integration_name?.trim() !== '') ? `${$hub.inParams.integration_name} Hub` : "Footprint API Hub"}`;
  }

  constructor(
  private $utils: UtilsService,
  private $settings: SettingsValuesService,
  private $shell: FootprintApiManager_ShellService,
  private $datasources: FootprintApiManager_DatasourceService,
  private $flows: FootprintApiManager_FlowService,
  private $reports: FootprintApiManager_ReportService,
  private $localization: FootprintApiManager_LocalizationService,
  private $operations: FootprintApiManager_OperationService,
  private $logger: CleanupLoggerService,
  private $context: FootprintApiManager_footprint_api_hub_ComponentContextService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.lifecycle,
      this.tabs.messages,
      this.tabs.logs,
      this.tabs.configurations,
      this.tabs.control,
      this.tabs.alerts,
      this.tabs.integrations,
      this.tabs.processes,
      this.tabs.statuses,
      this.tabs.transaction_types,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'API hub';
    
    const $hub = this;
    const $utils = this.$utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_message_statuses') {
      if (!isNil(this.$widgets_message_statuses) && !this.widgets.message_statuses.hidden) {
        this.$widgets_message_statuses.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_in_out_totals') {
      if (!isNil(this.$widgets_in_out_totals) && !this.widgets.in_out_totals.hidden) {
        this.$widgets_in_out_totals.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_lifecycle') {
      if (!isNil(this.$tabs_lifecycle) && !this.tabs.lifecycle.hidden) {
        this.$tabs_lifecycle.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_messages') {
      if (!isNil(this.$tabs_messages) && !this.tabs.messages.hidden) {
        this.$tabs_messages.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_logs') {
      if (!isNil(this.$tabs_logs) && !this.tabs.logs.hidden) {
        this.$tabs_logs.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_configurations') {
      if (!isNil(this.$tabs_configurations) && !this.tabs.configurations.hidden) {
        this.$tabs_configurations.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_control') {
      if (!isNil(this.$tabs_control) && !this.tabs.control.hidden) {
        this.$tabs_control.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_alerts') {
      if (!isNil(this.$tabs_alerts) && !this.tabs.alerts.hidden) {
        this.$tabs_alerts.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_integrations') {
      if (!isNil(this.$tabs_integrations) && !this.tabs.integrations.hidden) {
        this.$tabs_integrations.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_processes') {
      if (!isNil(this.$tabs_processes) && !this.tabs.processes.hidden) {
        this.$tabs_processes.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_statuses') {
      if (!isNil(this.$tabs_statuses) && !this.tabs.statuses.hidden) {
        this.$tabs_statuses.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_transaction_types') {
      if (!isNil(this.$tabs_transaction_types) && !this.tabs.transaction_types.hidden) {
        this.$tabs_transaction_types.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_run(event = null) {
    return this.on_runInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_runInternal(
    $hub: FootprintApiManager_footprint_api_hubComponent,
  
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_footprint_api_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/23/2024
  
  await $shell.FootprintApiManager.openfootprint_api_runnerDialog({}, "modal");
  $hub.refresh();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootprintApiManager_footprint_api_hubComponent,
  
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_footprint_api_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 02/19/2025
  
  if (await $operations.FootprintApiManager.Enable_ApiManager_ConfigurationsGrid.isAuthorized()) {
      $hub.tabs.configurations.hidden = false;
      $hub.tabs.control.hidden = false;
  } else {
      $hub.tabs.configurations.hidden = true;
      $hub.tabs.control.hidden = true;
  }
  
  if (await $operations.FootprintApiManager.Enable_ApiManager_MessagesGrid.isAuthorized()) {
      $hub.tabs.messages.hidden = false;
  } else {
      $hub.tabs.messages.hidden = true;
  }
  
  if (await $operations.FootprintApiManager.Enable_ApiManager_AlertsGrid.isAuthorized()) {
      $hub.tabs.alerts.hidden = false;
  } else {
      $hub.tabs.alerts.hidden = true;
  }
  
  if (await $operations.FootprintApiManager.Enable_ApiManager_LifecycleGrid.isAuthorized()) {
      $hub.tabs.lifecycle.hidden = false;
  } else {
      $hub.tabs.lifecycle.hidden = true;
  }
  
  if (await $operations.FootprintApiManager.Enable_ApiManager_IntegrationsGrid.isAuthorized()) {
      $hub.tabs.integrations.hidden = false;
  } else {
      $hub.tabs.integrations.hidden = true;
  }
  
  if (await $operations.FootprintApiManager.Enable_ApiManager_ProcessesGrid.isAuthorized()) {
      $hub.tabs.processes.hidden = false;
  } else {
      $hub.tabs.processes.hidden = true;
  }
  
  if (await $operations.FootprintApiManager.Enable_ApiManager_StatusesGrid.isAuthorized()) {
      $hub.tabs.statuses.hidden = false;
  } else {
      $hub.tabs.statuses.hidden = true;
  }
  
  if (await $operations.FootprintApiManager.Enable_ApiManager_TransactionTypesGrid.isAuthorized()) {
      $hub.tabs.transaction_types.hidden = false;
  } else {
      $hub.tabs.transaction_types.hidden = true;
  }
  
  if (await $operations.FootprintApiManager.Enable_ApiManager_ManualRunner.isAuthorized()) {
      $hub.toolbar.runner.hidden = false;
  }
  else {
      $hub.toolbar.runner.hidden = true;
  }
  
  let integration_name: string = "";
  let title: string = "";
  
  integration_name = $hub.inParams.integration_name ?? '';
  if (integration_name?.trim()?.toLowerCase() === "null") { integration_name = '' }
  if (integration_name?.trim()?.toLowerCase() === "undefined") { integration_name = '' }
  
  title = (integration_name !== '') ? `${$hub.inParams.integration_name} Hub` : "Logs & Configuration Hub";
  
  if (integration_name !== '') {
  
      $hub.title = `${integration_name}`;
      $hub.filters.integration_name.control.value = integration_name;
      $hub.filtersets.integration.hidden = true;
      $hub.tabs.integrations.hidden = true;
      $hub.tabs.transaction_types.hidden = true;
      $hub.tabs.statuses.hidden = true;
      $hub.tabs.processes.hidden = true;
  }
  }
  on_refresh(event = null) {
    return this.on_refreshInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refreshInternal(
    $hub: FootprintApiManager_footprint_api_hubComponent,
  
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_footprint_api_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/20/2024
  
  $flows.FootprintApiManager.list_integrations({action: "Refresh"});
  $flows.FootprintApiManager.list_processes({action: "Refresh"});
  $flows.FootprintApiManager.list_statuses({action: "Refresh"});
  $flows.FootprintApiManager.list_transaction_types({action: "Refresh"});
  }
  //#endregion private flows
}
