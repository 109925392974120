import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { app_DatasourceService } from './app.datasource.index';

interface Iapp_custom_load_diagram_reportServiceInParams {
  order_id: number}

interface Iapp_custom_load_diagram_reportServiceData {
  Main?: { result?: { result?: { total_packaged_quantity?: number, licesenplate_lookup?: string, lot_lookup?: string, material_lookup?: string, shipment_lookup?: string, task_picking_completed_actualamount_lplookup?: string, task_shipment_loading_completeddatetime?: string }[] } }
}

@Injectable({ providedIn: 'root' })
export class app_custom_load_diagram_reportService extends ReportBaseService<Iapp_custom_load_diagram_reportServiceInParams, Iapp_custom_load_diagram_reportServiceData> {

  protected reportReferenceName = 'custom_load_diagram_report';
  protected appReferenceName = 'app';

  constructor(
    $utils: UtilsService,
    private $datasources: app_DatasourceService,
    private $logger: CleanupLoggerService
    ) {
      super($utils);
      $logger.log('app', 'custom_load_diagram_report');
  }

  override throwIfMissingRequiredInParams (inParams: Iapp_custom_load_diagram_reportServiceInParams) {
  }

  protected async getData(inParams: Iapp_custom_load_diagram_reportServiceInParams): Promise<Iapp_custom_load_diagram_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: Iapp_custom_load_diagram_reportServiceInParams } = {
      inParams: inParams
    };

    const data: Iapp_custom_load_diagram_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        order_id:  $report.inParams.order_id 
      };
      
      const dsData = await this.$datasources.app.custom_ds_load_diagram_report.get(dsParams);
      
      data.Main = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
