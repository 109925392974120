import { 
  Component, 
  ComponentRef, 
  Inject, 
  OnDestroy, 
  OnInit, 
  TemplateRef, 
  ViewChild, 
  ViewContainerRef,
  AfterViewInit,
  EmbeddedViewRef,
} from '@angular/core';

import { 
  Router,
  ActivatedRoute, 
  ParamMap 
} from '@angular/router';

import { Location } from '@angular/common';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {Title} from "@angular/platform-browser";

import { 
  MsalInterceptorConfiguration, 
  MsalService, 
  MSAL_INTERCEPTOR_CONFIG 
} from '@azure/msal-angular';


import { 
  AccountInfo, 
  EndSessionRequest 
} from '@azure/msal-browser';

import { LoadingBarService } from '@ngx-loading-bar/core';

import { isNil, isObject } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexErrorService } from './error/datex-error-service';
import { protectedResources } from './auth-config';

import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';
import { WavelengthUiModule, WavelengthShellService, IMenubarItem, MenuItemModel } from 'wavelength-ui';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { app_shell_ComponentContextService } from './app.shell.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

enum EmbedScriptType {
  Inline,
  External
}

@Component({
  standalone: true,
  imports: [
    SharedModule,
    WavelengthUiModule
  ],
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  providers: [
    {
      provide: WavelengthShellService,
      useExisting: app_ShellService
    }
  ]
})
export class ShellComponent {

  title: string = "FootPrint Cloud";
  subtitle: string = "";
  logo: string = "assets/img/footprint_2024_icon_white.svg";

  account: AccountInfo;
  home: IMenubarItem;
  items: IMenubarItem[];
  menubar: any;
  toolbarItems: IMenubarItem[];
  toolbar: any;

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: app_ShellService,
    private $datasources: app_DatasourceService,
    private $flows: app_FlowService,
    private $reports: app_ReportService,
    private $localization: app_LocalizationService,
    private $operations: app_OperationService,
    private $logger: CleanupLoggerService,
    private $context: app_shell_ComponentContextService,

    private location: Location,
    private authService: MsalService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingBarService: LoadingBarService,
    private titleService:Title,
    private datexErrorService: DatexErrorService,
    @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfiguration: MsalInterceptorConfiguration
  ) {

    this.home = new MenuItemModel(
      'home',
      'ms-Icon ms-Icon--Home',
      'Home',
      [],
      () => this.openHome()
    );

    this.items = [
    
      new MenuItemModel('inbound', 'icon-datex-Inbound', 'Inbound', [
          new MenuItemModel('inbound_orders', 'icon-datex-InboundOrder', 'Inbound Orders', [],  () => {
              this.$shell.FootprintManager.openinbound_orders_hub(
              false)}
        ),
          new MenuItemModel('returns_hub', 'icon-datex-Returns', 'Returns', [],  () => {
              this.$shell.FootprintManager.openreturns_hub(
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('outbound', 'icon-datex-Outbound', 'Outbound', [
          new MenuItemModel('outbound_orders', 'icon-datex-OutboundOrder', 'Outbound Orders', [],  () => {
              this.$shell.FootprintManager.openoutbound_orders_hub(
              false)}
        ),
          new MenuItemModel('warehouse_transfers', 'ms-Icon ms-Icon--DependencyAdd', 'Warehouse Transfers', [],  () => {
              this.$shell.FootprintManager.openwarehouse_transfers_hub(
              false)}
        ),
          new MenuItemModel('pack_verification', 'icon-ic_fluent_barcode_scanner_20_regular', 'Pack Verification', [],  () => {
              this.$shell.FootprintManager.openpack_verification_hub(
              false)}
        ),
          new MenuItemModel('waves', 'icon-datex-Wave', 'Waves', [],  () => {
              this.$shell.FootprintManager.openwaves_hub(
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('transportation', 'icon-datex-Carrier', 'Transportation', [
          new MenuItemModel('dock_appointments', 'icon-ic_fluent_calendar_empty_20_regular', 'Dock Appointments', [],  () => {
              this.$shell.FootprintManager.opendock_appointments_hub(
              false)}
        ),
          new MenuItemModel('dock_doors', 'icon-ic_fluent_door_20_regular', 'Dock Doors', [],  () => {
              this.$shell.FootprintManager.opendock_doors_hub(
              {
                warehouseIds:  null 
              },
              false)}
        ),
          new MenuItemModel('driver_check_out', 'icon-ic_fluent_signature_20_regular', 'Driver Check Out', [],  () => {
              this.$shell.FootprintManager.opendriver_check_out_hub(
              {
                orderId:  null ,
                load_container_id:  null 
              },
              false)}
        ),
          new MenuItemModel('load_containers', 'icon-datex-LoadContainer', 'Load Containers', [],  () => {
              this.$shell.FootprintManager.openload_containers_hub(
              false)}
        ),
          new MenuItemModel('shipping_containers', 'icon-ic_fluent_layout_column_two_split_left_20_regular', 'Shipping Containers', [],  () => {
              this.$shell.FootprintManager.openshipping_containers_hub(
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('planning', 'icon-datex-Flow', 'Planning', [
          new MenuItemModel('labor_management_hub', 'icon-ic_fluent_people_team_toolbox_20_regular', 'Labor Management', [],  () => {
              this.$shell.FootprintManager.openlabor_management_hub(
              false)}
        ),
          new MenuItemModel('dock_status', 'icon-datex-LoadContainer', 'Dock status', [],  () => {
              this.$shell.opencustom_dock_status_grid(
              {
                warehouse_ids:  null 
              },
              false)}
        ),
          new MenuItemModel('custom_user_activity_report', 'ms-Icon ms-Icon--ReportDocument', 'User activity dashboard', [],  () => {
              this.$shell.opencustom_user_activity_report(
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('inventory', 'icon-datex-Inventory', 'Inventory', [
          new MenuItemModel('inventory', 'icon-datex-Inventory', 'Inventory', [],  () => {
              this.$shell.FootprintManager.openinventory_hub(
              {
                ownerId:  null ,
                projectId:  null ,
                warehouseId:  null 
              },
              false)}
        ),
          new MenuItemModel('activity_hub', 'icon-ic_fluent_tasks_app_20_regular', 'Activity', [],  () => {
              this.$shell.opencustom_activity_hub(
              false)}
        ),
          new MenuItemModel('lots', 'icon-datex-Lot', 'Lots', [],  () => {
              this.$shell.FootprintManager.openlots_hub(
              false)}
        ),
          new MenuItemModel('serialnumbers', 'icon-datex-SerialNumber', 'Serial Numbers', [],  () => {
              this.$shell.FootprintManager.openserialnumbers_hub(
              false)}
        ),
          new MenuItemModel('inventory_transfers_hub', 'icon-ic_fluent_box_multiple_arrow_right_20_regular', 'Inventory Transfers', [],  () => {
              this.$shell.FootprintManager.openinventory_transfers_hub(
              false)}
        ),
          new MenuItemModel('inventory_count', 'icon-datex-Count', 'Inventory Counts', [],  () => {
              this.$shell.FootprintManager.openinventory_counts_hub(
              false)}
        ),
          new MenuItemModel('replenishment', 'icon-datex-Replenishment', 'Replenishments', [],  () => {
              this.$shell.FootprintManager.openreplenishment_hub(
              false)}
        ),
          new MenuItemModel('warehouses', 'icon-datex-Warehouse', 'Warehouses', [],  () => {
              this.$shell.FootprintManager.openwarehouses_hub(
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('owners', 'icon-ic_fluent_video_person_20_filled', 'Owners', [
          new MenuItemModel('owners', 'icon-ic_fluent_video_person_20_filled', 'Owners', [],  () => {
              this.$shell.FootprintManager.openowners_hub(
              false)}
        ),
          new MenuItemModel('projects', 'icon-ic_fluent_person_edit_20_regular', 'Projects', [],  () => {
              this.$shell.FootprintManager.openprojects_hub(
              false)}
        ),
          new MenuItemModel('materials', 'icon-datex-Material', 'Materials', [],  () => {
              this.$shell.FootprintManager.openmaterials_hub(
              {
                ownerId:  null ,
                projectId:  null 
              },
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('billing', 'icon-ic_fluent_calculator_20_regular', 'Billing', [
          new MenuItemModel('billing_contracts', 'ms-Icon ms-Icon--PenWorkspace', 'Billing Contracts', [],  () => {
              this.$shell.FootprintManager.openbilling_contracts_hub(
              false)}
        ),
          new MenuItemModel('billing_records', 'icon-ic_fluent_grid_dots_20_regular', 'Billing Records', [],  () => {
              this.$shell.FootprintManager.openbilling_records_hub(
              false)}
        ),
          new MenuItemModel('invoices', 'icon-ic_fluent_receipt_20_regular', 'Invoices', [],  () => {
              this.$shell.FootprintManager.openinvoices_hub(
              {
                ownerId:  null ,
                projectId:  null 
              },
              false)}
        ),
          new MenuItemModel('work_orders', 'icon-ic_fluent_clipboard_edit_20_regular', 'Work Orders', [],  () => {
              this.$shell.FootprintManager.openwork_orders_hub(
              false)}
        ),
          new MenuItemModel('auto_invoicing_rules_hub', 'ms-Icon ms-Icon--Money', 'Auto-invoicing', [],  () => {
              this.$shell.Invoices.openinvoicing_rules_hub(
              {
                projectId:  null ,
                billingContractId:  null 
              },
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('integrations', 'icon-ic_fluent_cloud_bidirectional_20_regular', 'Integrations', [
          new MenuItemModel('entity_import', 'icon-ic_fluent_cloud_arrow_up_20_regular', 'Entity Import', [],  () => {
              this.$shell.FootprintManager.openentity_import_hub(
              false)}
        ),
          new MenuItemModel('orderful', 'ms-Icon ms-Icon--CloudImportExport', 'Ordeful', [],  () => {
              this.$shell.Orderful.openorderful_api_hub(
              {
                integration_name:  "Orderful" 
              },
              false)}
        ),
          new MenuItemModel('footprint_api_manager', 'ms-Icon ms-Icon--CloudFlow', 'Footprint API Manager', [],  () => {
              this.$shell.FootprintApiManager.openfootprint_api_hub(
              {
                integration_name:  null 
              },
              false)}
        ),
          new MenuItemModel('logs', 'icon-ic_fluent_toolbox_20_regular', 'Logs & Configuration', [],  () => {
              this.$shell.Utilities.openutilities_hub(
              {
                application_name:  null ,
                column_names:  null ,
                filter_values:  null ,
                user_name:  null 
              },
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    
      new MenuItemModel('settings', 'icon-ic_fluent_settings_20_regular', 'Settings', [
          new MenuItemModel('Inventory_configurations', 'ms-Icon ms-Icon--ProductCatalog', 'Inventory configurations', [],  () => {
              this.$shell.FootprintManager.openconfigurations_inventory_hub(
              false)}
        ),
          new MenuItemModel('storage_categories', 'ms-Icon ms-Icon--HomeGroup', 'Storage categories', [],  () => {
              this.$shell.FootprintManager.openconfigurations_storage_categories_hub(
              false)}
        ),
          new MenuItemModel('email_rules', 'icon-ic_fluent_mail_alert_20_regular', 'Email rules', [],  () => {
              this.$shell.Notifications.openauto_email_rules_hub(
              {
                projectId:  null 
              },
              false)}
        ),
          new MenuItemModel('surveys', 'icon-ic_fluent_clipboard_bullet_list_20_regular', 'Surveys', [],  () => {
              this.$shell.FootprintManager.opensurveys_hub(
              false)}
        ),
        ]
        ,      
        () => {},
      ),
    ];

    this.menubar = { 
        inbound: this.items.find(i => i.id == 'inbound') as { hidden: boolean, menubar: {  inbound_orders: { hidden: boolean },  returns_hub: { hidden: boolean },  } }, 
        outbound: this.items.find(i => i.id == 'outbound') as { hidden: boolean, menubar: {  outbound_orders: { hidden: boolean },  warehouse_transfers: { hidden: boolean },  pack_verification: { hidden: boolean },  waves: { hidden: boolean },  } }, 
        transportation: this.items.find(i => i.id == 'transportation') as { hidden: boolean, menubar: {  dock_appointments: { hidden: boolean },  dock_doors: { hidden: boolean },  driver_check_out: { hidden: boolean },  load_containers: { hidden: boolean },  shipping_containers: { hidden: boolean },  } }, 
        planning: this.items.find(i => i.id == 'planning') as { hidden: boolean, menubar: {  labor_management_hub: { hidden: boolean },  dock_status: { hidden: boolean },  custom_user_activity_report: { hidden: boolean },  } }, 
        inventory: this.items.find(i => i.id == 'inventory') as { hidden: boolean, menubar: {  inventory: { hidden: boolean },  activity_hub: { hidden: boolean },  lots: { hidden: boolean },  serialnumbers: { hidden: boolean },  inventory_transfers_hub: { hidden: boolean },  inventory_count: { hidden: boolean },  replenishment: { hidden: boolean },  warehouses: { hidden: boolean },  } }, 
        owners: this.items.find(i => i.id == 'owners') as { hidden: boolean, menubar: {  owners: { hidden: boolean },  projects: { hidden: boolean },  materials: { hidden: boolean },  } }, 
        billing: this.items.find(i => i.id == 'billing') as { hidden: boolean, menubar: {  billing_contracts: { hidden: boolean },  billing_records: { hidden: boolean },  invoices: { hidden: boolean },  work_orders: { hidden: boolean },  auto_invoicing_rules_hub: { hidden: boolean },  } }, 
        integrations: this.items.find(i => i.id == 'integrations') as { hidden: boolean, menubar: {  entity_import: { hidden: boolean },  orderful: { hidden: boolean },  footprint_api_manager: { hidden: boolean },  logs: { hidden: boolean },  } }, 
        settings: this.items.find(i => i.id == 'settings') as { hidden: boolean, menubar: {  Inventory_configurations: { hidden: boolean },  storage_categories: { hidden: boolean },  email_rules: { hidden: boolean },  surveys: { hidden: boolean },  } }, 
    };

    this.toolbarItems = [
    ];


    this.toolbar = { 
    };
  }

  private addScript(type: EmbedScriptType, data: string) {
    switch(type){
      case EmbedScriptType.Inline:
        this.addInlineScript(data);
        break;
      case EmbedScriptType.External:
        this.addExternalScript(data);
        break;
      default:
        throw new Error(`Unknown script type ${type}`);
    }
  }

  private addInlineScript(body: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = body;
    document.body.appendChild(script);
  }

  private addExternalScript(url: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.defer = true;
    document.body.appendChild(script);
  }

  private openHome() {    
    this.$shell.FootprintManager.openorders_hub(
      false
    );
  }

  onLogoutClicked(): void {
    this.authService.logout();
  }

  onInitialized() {
    Promise.resolve().then(() => {
      this.on_init();
    });

    this.account = this.authService.instance.getActiveAccount();
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $workspace: ShellComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_shell_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  // Hide navigation items
  
  // Inbound
  if (await $operations.FootprintManager.Disable_Navigation_Inbound.isAuthorized()) {
      $workspace.menubar.inbound.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Inbound_Returns.isAuthorized()) {
      $workspace.menubar.inbound.menubar.returns_hub.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Inbound_Orders_Hub.isAuthorized()) {
      $workspace.menubar.inbound.menubar.inbound_orders.hidden = true;
  }
  
  
  
  // Outbound
  if (await $operations.FootprintManager.Disable_Navigation_Outbound.isAuthorized()) {
      $workspace.menubar.outbound.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Outbound_Orders_Hub.isAuthorized()) {
      $workspace.menubar.outbound.menubar.outbound_orders.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Outbound_Pack_Verification.isAuthorized()) {
      $workspace.menubar.outbound.menubar.pack_verification.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Outbound_Waves.isAuthorized()) {
      $workspace.menubar.outbound.menubar.waves.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Outbound_Outbound_Warehouse_Transfers.isAuthorized()) {
      $workspace.menubar.outbound.menubar.warehouse_transfers.hidden = true;
  }
  
  // Trasportation
  if (await $operations.FootprintManager.Disable_Navigation_Transportation.isAuthorized()) {
      $workspace.menubar.transportation.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Transportation_Dock_Appointments.isAuthorized()) {
      $workspace.menubar.transportation.menubar.dock_appointments.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Transportation_Dock_Doors.isAuthorized()) {
      $workspace.menubar.transportation.menubar.dock_doors.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Transportation_Driver_Check_Out.isAuthorized()) {
      $workspace.menubar.transportation.menubar.driver_check_out.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Transportation_Load_Containers.isAuthorized()) {
      $workspace.menubar.transportation.menubar.load_containers.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Transportation_Shipping_Containers.isAuthorized()) {
      $workspace.menubar.transportation.menubar.shipping_containers.hidden = true;
  }
  
  // Planning
  if (await $operations.FootprintManager.Disable_Navigation_Planning.isAuthorized()) {
      $workspace.menubar.planning.hidden = true;
  }
  // if (await $operations.FootprintManager.Disable_Navigation_Planning_Inspections.isAuthorized()) {
  //     $workspace.menubar.planning.menubar.inspections.hidden = true;
  // }
  if (await $operations.FootprintManager.Disable_Navigation_Planning_Labor_Management.isAuthorized()) {
      $workspace.menubar.planning.menubar.labor_management_hub.hidden = true;
  }
  
  
  // Inventory
  if (await $operations.FootprintManager.Disable_Navigation_Inventory.isAuthorized()) {
      $workspace.menubar.inventory.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Inventory_Inventory_Hub.isAuthorized()) {
      $workspace.menubar.inventory.menubar.inventory.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Inventory_Inventory_Count.isAuthorized()) {
      $workspace.menubar.inventory.menubar.inventory_count.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Inventory_Inventory_Transfers.isAuthorized()) {
      $workspace.menubar.inventory.menubar.inventory_transfers_hub.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Inventory_Lots.isAuthorized()) {
      $workspace.menubar.inventory.menubar.lots.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Inventory_Replenishment.isAuthorized()) {
      $workspace.menubar.inventory.menubar.replenishment.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Inventory_Serial_Numbers.isAuthorized()) {
      $workspace.menubar.inventory.menubar.serialnumbers.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Inventory_Warehouses.isAuthorized()) {
      $workspace.menubar.inventory.menubar.warehouses.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Inventory_Activity.isAuthorized()) {
      $workspace.menubar.inventory.menubar.activity_hub.hidden = true;
  }
  
  
  // Owners
  if (await $operations.FootprintManager.Disable_Navigation_Owners.isAuthorized()) {
      $workspace.menubar.owners.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Owners_Owners_Hub.isAuthorized()) {
      $workspace.menubar.owners.menubar.owners.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Owners_Materials.isAuthorized()) {
      $workspace.menubar.owners.menubar.materials.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Owners_Projects.isAuthorized()) {
      $workspace.menubar.owners.menubar.projects.hidden = true;
  }
  
  // Billing
  if (await $operations.FootprintManager.Disable_Navigation_Billing.isAuthorized()) {
      $workspace.menubar.billing.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Billing_Billing_Contracts.isAuthorized()) {
      $workspace.menubar.billing.menubar.billing_contracts.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Billing_Billing_Records.isAuthorized()) {
      $workspace.menubar.billing.menubar.billing_records.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Billing_Invoices.isAuthorized()) {
      $workspace.menubar.billing.menubar.invoices.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Billing_Work_Orders.isAuthorized()) {
      $workspace.menubar.billing.menubar.work_orders.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Billing_Auto_Invoicing.isAuthorized()) {
      $workspace.menubar.billing.menubar.auto_invoicing_rules_hub.hidden = true;
  }
  
  // Integrations
  if (await $operations.FootprintManager.Disable_Navigation_Integrations.isAuthorized()) {
      $workspace.menubar.integrations.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Integrations_Entity_Import.isAuthorized()) {
      $workspace.menubar.integrations.menubar.entity_import.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Integrations_Footprint_Api_Manager.isAuthorized()) {
     // Change once api manager is refactored into utilities
     // $workspace.menubar.integrations.menubar.footprint_api_manager.hidden = true;
  }
  
  // Settings
  if (await $operations.FootprintManager.Disable_Navigation_Settings.isAuthorized()) {
      $workspace.menubar.settings.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Settings_Inventory_Configurations.isAuthorized()) {
      $workspace.menubar.settings.menubar.Inventory_configurations.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Settings_Storage_Categories.isAuthorized()) {
      $workspace.menubar.settings.menubar.storage_categories.hidden = true;
  }
  if (await $operations.FootprintManager.Disable_Navigation_Settings_Surveys.isAuthorized()) {
      $workspace.menubar.settings.menubar.surveys.hidden = true;
  }
  
  
  
  //Usersnap Widget
  if (await $operations.FootprintManager.Disable_Navigation_UserSnap.isAuthorized()) {
      // Do nothing
  }
  else {
      let usersnapContent = (await $flows.Usersnap.get_usersnap_widget({})).usersnap_scriptContent;
      $workspace.addScript(EmbedScriptType.Inline, usersnapContent);
  }
  // Document 360 Help Widget
  if (await $operations.FootprintManager.Disable_Navigation_Document360.isAuthorized()) {
      // Do nothing
  }
  else {
     let doc360Content = (await $flows.Document360.get_document360_widget({})).doc30_scriptContent;
     $workspace.addScript(EmbedScriptType.Inline, doc360Content);
  }
  
  
  
  }
  //#endregion private flows
}
