import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class EndeavorLabs_ds_prediction_order_filterService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { OrderId?: number }): 
  Promise<{ result: { Id?: number, CompletedDateTime?: string, Length?: number, OrderId?: number, ShipmentId?: number } }> 
  {
    let url = `${environment.backendUrl}api/EndeavorLabs/datasources/ds_prediction_order_filter/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      OrderId?: number    }) {
      return false;
    }
}
