import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_bol_content_loadService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { license_plate_ids: number[] }): 
  Promise<{ result: { Id?: number, ActualTargetLicensePlateId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.license_plate_ids)) {
      missingRequiredInParams.push('\'license_plate_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_bol_content_load/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { license_plate_ids: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualTargetLicensePlateId?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.license_plate_ids)) {
      missingRequiredInParams.push('\'license_plate_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_bol_content_load/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { license_plate_ids: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualTargetLicensePlateId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.license_plate_ids)) {
      missingRequiredInParams.push('\'license_plate_ids\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_bol_content_load/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      license_plate_ids: number[]    }) {
        if(isNil(inParams.license_plate_ids)) {
          return true; 
        }
      return false;
    }
}
