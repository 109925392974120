<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-message"
                     *ngIf="!fieldsets.message.hidden && !fieldsets.message.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.message.collapsible }">
                    <div *ngIf="!fieldsets.message.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.message.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.message.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.message.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.message.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.message.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.message.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-message" *ngIf="!fields.message.hidden && !fields.message.removed" 
                            class="field-container full {{fields.message.invalid ? 'invalid' : ''}} {{fields.message.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.message.styles.style"
                            [ngClass]="fields.message.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.message.label + (fields.message.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.message.label}}<span *ngIf="fields.message.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <ngx-codemirror
                                    formControlName="message"
                                    [options]="{
                                    theme: 'base16-light',
                                    mode: fields.message.control.codeMirrorMode,
                                    lineNumbers: true,
                                    lineWrapping: true,
                                    foldGutter: true,
                                    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                                    autoCloseBrackets: true,
                                    matchBrackets: true,
                                    lint: true
                                    }"
                                    [CodeValidator]="fields.message.control.mode"
                                    [matTooltip]="fields.message.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">
                        </ngx-codemirror>
                        
                        <button type="button margin-top-10"
                                (click)="fields.message.control.beautify();"
                                class="datex-button secondary ">Beautify</button>
                        <ng-container *ngIf="fields.message.invalid">
                          <ng-container *ngFor="let error of fields.message.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.message.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>