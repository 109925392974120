import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_user_activity_reportServiceInParams {
  from_date: string, to_date: string, warehouse_id?: number, operation_code_ids?: number[]}

interface IReports_custom_user_activity_reportServiceData {
  details?: { result?: { total?: number, Employee?: string, Warehouse?: { Name?: string }, OperationCode?: { Name?: string } }[], totalCount?: number }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_user_activity_reportService extends ReportBaseService<IReports_custom_user_activity_reportServiceInParams, IReports_custom_user_activity_reportServiceData> {

  protected reportReferenceName = 'custom_user_activity_report';
  protected appReferenceName = 'Reports';

  constructor(
    $utils: UtilsService,
    private $datasources: Reports_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_user_activity_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_user_activity_reportServiceInParams): Promise<IReports_custom_user_activity_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IReports_custom_user_activity_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_user_activity_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        from_date:  $report.inParams.from_date ,
        to_date:  $report.inParams.to_date ,
        warehouse_id:  $report.inParams.warehouse_id ,
        operation_codes_ids:  $report.inParams.operation_code_ids 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_get_tasks_by_warehouse_operation_codes_details.get(dsParams);
      
      data.details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
