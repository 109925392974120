import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_inventory_report_details_by_operation_type_idsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { project_ids: number[], from_date: string, to_date: string, operation_code_ids: number[], warehouse_ids: number[], material_ids?: number[], vendor_lot_ids?: number[], is_packaged_amount_ne_0?: boolean }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, CompletedDateTime?: string, GrossWeight?: number, LotId?: number, MaterialId?: number, NetWeight?: number, OrderId?: number, StatusId?: number, VendorLotId?: number, WarehouseId?: number, WeightUomId?: number, Lot?: { Id?: number, LookupCode?: string, MaterialId?: number, VendorLotId?: number }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string }, Project?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, Shipment?: { Id?: number, OrderLookups?: { OrderId?: number, Order?: { BillingAddresId?: number, LookupCode?: string, OwnerReference?: string, Account?: { Name?: string } } }[] }, OperationCode?: { Name?: string }, ActualPackagedPack?: { ShortName?: string }, ActualSourceLicensePlate?: { LookupCode?: string, ShippingContainer?: { LookupCode?: string } }, ActualTargetShippingContainer?: { LookupCode?: string }, ActualSourceShippingContainer?: { LookupCode?: string }, billing_address?: { Id?: number, City?: string, Country?: string, Line1?: string, Line2?: string, State?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.project_ids)) {
      missingRequiredInParams.push('\'project_ids\'');
    }
    if (isNil(inParams.from_date)) {
      missingRequiredInParams.push('\'from_date\'');
    }
    if (isNil(inParams.to_date)) {
      missingRequiredInParams.push('\'to_date\'');
    }
    if (isNil(inParams.operation_code_ids)) {
      missingRequiredInParams.push('\'operation_code_ids\'');
    }
    if (isNil(inParams.warehouse_ids)) {
      missingRequiredInParams.push('\'warehouse_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_inventory_report_details_by_operation_type_ids/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { project_ids: number[], from_date: string, to_date: string, operation_code_ids: number[], warehouse_ids: number[], material_ids?: number[], vendor_lot_ids?: number[], is_packaged_amount_ne_0?: boolean, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, CompletedDateTime?: string, GrossWeight?: number, LotId?: number, MaterialId?: number, NetWeight?: number, OrderId?: number, StatusId?: number, VendorLotId?: number, WarehouseId?: number, WeightUomId?: number, Lot?: { Id?: number, LookupCode?: string, MaterialId?: number, VendorLotId?: number }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string }, Project?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, Shipment?: { Id?: number, OrderLookups?: { OrderId?: number, Order?: { BillingAddresId?: number, LookupCode?: string, OwnerReference?: string, Account?: { Name?: string } } }[] }, OperationCode?: { Name?: string }, ActualPackagedPack?: { ShortName?: string }, ActualSourceLicensePlate?: { LookupCode?: string, ShippingContainer?: { LookupCode?: string } }, ActualTargetShippingContainer?: { LookupCode?: string }, ActualSourceShippingContainer?: { LookupCode?: string }, billing_address?: { Id?: number, City?: string, Country?: string, Line1?: string, Line2?: string, State?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.project_ids)) {
      missingRequiredInParams.push('\'project_ids\'');
    }
    if (isNil(inParams.from_date)) {
      missingRequiredInParams.push('\'from_date\'');
    }
    if (isNil(inParams.to_date)) {
      missingRequiredInParams.push('\'to_date\'');
    }
    if (isNil(inParams.operation_code_ids)) {
      missingRequiredInParams.push('\'operation_code_ids\'');
    }
    if (isNil(inParams.warehouse_ids)) {
      missingRequiredInParams.push('\'warehouse_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_inventory_report_details_by_operation_type_ids/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { project_ids: number[], from_date: string, to_date: string, operation_code_ids: number[], warehouse_ids: number[], material_ids?: number[], vendor_lot_ids?: number[], is_packaged_amount_ne_0?: boolean, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, CompletedDateTime?: string, GrossWeight?: number, LotId?: number, MaterialId?: number, NetWeight?: number, OrderId?: number, StatusId?: number, VendorLotId?: number, WarehouseId?: number, WeightUomId?: number, Lot?: { Id?: number, LookupCode?: string, MaterialId?: number, VendorLotId?: number }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string }, Project?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, Shipment?: { Id?: number, OrderLookups?: { OrderId?: number, Order?: { BillingAddresId?: number, LookupCode?: string, OwnerReference?: string, Account?: { Name?: string } } }[] }, OperationCode?: { Name?: string }, ActualPackagedPack?: { ShortName?: string }, ActualSourceLicensePlate?: { LookupCode?: string, ShippingContainer?: { LookupCode?: string } }, ActualTargetShippingContainer?: { LookupCode?: string }, ActualSourceShippingContainer?: { LookupCode?: string }, billing_address?: { Id?: number, City?: string, Country?: string, Line1?: string, Line2?: string, State?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.project_ids)) {
      missingRequiredInParams.push('\'project_ids\'');
    }
    if (isNil(inParams.from_date)) {
      missingRequiredInParams.push('\'from_date\'');
    }
    if (isNil(inParams.to_date)) {
      missingRequiredInParams.push('\'to_date\'');
    }
    if (isNil(inParams.operation_code_ids)) {
      missingRequiredInParams.push('\'operation_code_ids\'');
    }
    if (isNil(inParams.warehouse_ids)) {
      missingRequiredInParams.push('\'warehouse_ids\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_inventory_report_details_by_operation_type_ids/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      project_ids: number[], from_date: string, to_date: string, operation_code_ids: number[], warehouse_ids: number[], material_ids?: number[], vendor_lot_ids?: number[], is_packaged_amount_ne_0?: boolean    }) {
        if(isNil(inParams.project_ids)) {
          return true; 
        }
        if(isNil(inParams.from_date)) {
          return true; 
        }
        if(isNil(inParams.to_date)) {
          return true; 
        }
        if(isNil(inParams.operation_code_ids)) {
          return true; 
        }
        if(isNil(inParams.warehouse_ids)) {
          return true; 
        }
      return false;
    }
}
