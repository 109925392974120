import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_orders_manufacturing_production_components_grid_ComponentContextService } from './FootprintManager.manufacturing_orders_manufacturing_production_components_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_manufacturing_orders_manufacturing_production_components_gridComponentEntity {
  Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualSourceLicensePlateId?: number, ActualSourceLocationId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, AuxiliaryExternalIdentifier?: number, ChainHead?: number, CreatedSysDateTime?: string, ExpectedSourceLocationId?: number, LotId?: number, ManufacturingOrderId?: number, ManufacturingOrderLineId?: number, MaterialId?: number, OperationCodeId?: number, ProjectId?: number, SerialNumberId?: number, WarehouseId?: number, WorkShiftId?: number, Material?: { Name?: string, ProjectId?: number }, ActualPackagedPack?: { Name?: string }, ManufacturingOrder?: { DefaultStagingLocationId?: number }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, ExpirationDate?: string } }, WorkShift?: { Id?: number, Name?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }}

interface IFootprintManager_manufacturing_orders_manufacturing_production_components_gridComponentInParams {
  manufacturing_order_id?: number, manufacturing_order_line_id?: number, warehouse_id?: number, task_chain_head?: number}


class FootprintManager_manufacturing_orders_manufacturing_production_components_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_manufacturing_orders_manufacturing_production_components_gridComponent;
  entity: IFootprintManager_manufacturing_orders_manufacturing_production_components_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    lot_expiration_display: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  override cells = {
    component: null,
    consumed_quantity: null,
    scrapped_quantity: null,
    remaining_quantity: null,
    source_license_plate: null,
    lot: null,
    lot_expiration: null,
  }



  constructor(
    grid: FootprintManager_manufacturing_orders_manufacturing_production_components_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_manufacturing_orders_manufacturing_production_components_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.component = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.component,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.consumed_quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.consumed_quantity,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.scrapped_quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.scrapped_quantity,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.remaining_quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.remaining_quantity,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.source_license_plate = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.source_license_plate,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot_expiration = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot_expiration,
      new DateBoxModel(this.formGroup.controls['lot_expiration_display'] as DatexFormControl, null, true, false, '', 'date', null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_manufacturing_orders_manufacturing_production_components_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_manufacturing_orders_manufacturing_production_components_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      manufacturing_order_id:  $grid.inParams.manufacturing_order_id ,
      manufacturing_order_line_id:  $grid.inParams.manufacturing_order_line_id ,
      operation_code:  47 ,
      task_chain_head:  $grid.inParams.task_chain_head ,
    };
    const data = await this.$datasources.Manufacturing.ds_get_manufacturing_order_production_confirmation_data.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_manufacturing_orders_manufacturing_production_components_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.component.displayControl as TextModel).text = $row.entity.Material?.Name;
    (this.cells.consumed_quantity.displayControl as TextModel).text = $row.entity.ActualPackagedAmount.toString();
    (this.cells.source_license_plate.displayControl as TextModel).text = $row.entity.ActualSourceLicensePlate?.LookupCode;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.lot_expiration.displayControl as DateBoxModel).reset($row.entity.Lot?.VendorLot?.ExpirationDate);

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }


}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-manufacturing_orders_manufacturing_production_components_grid',
  templateUrl: './FootprintManager.manufacturing_orders_manufacturing_production_components_grid.component.html'
})
export class FootprintManager_manufacturing_orders_manufacturing_production_components_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_manufacturing_orders_manufacturing_production_components_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_manufacturing_orders_manufacturing_production_components_gridComponentInParams = { manufacturing_order_id: null, manufacturing_order_line_id: null, warehouse_id: null, task_chain_head: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     component: new GridHeaderModel(new HeaderStyles(null, null), 'component', 'Component', false, false, null, false),       consumed_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'consumed_quantity', 'Consumed quantity', false, false, null, false),       scrapped_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'scrapped_quantity', 'Scrapped quantity', false, false, null, false),       remaining_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'remaining_quantity', 'Remaining quantity', false, false, null, false),       source_license_plate: new GridHeaderModel(new HeaderStyles(null, null), 'source_license_plate', 'Source license plate', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, null, false),       lot_expiration: new GridHeaderModel(new HeaderStyles(null, null), 'lot_expiration', 'Lot expiration', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_manufacturing_orders_manufacturing_production_components_gridComponentRowModel[] = [];

  @Input('manufacturing_order_id') set $inParams_manufacturing_order_id(value: any) {
    this.inParams['manufacturing_order_id'] = value;
  }
  get $inParams_manufacturing_order_id(): any {
    return this.inParams['manufacturing_order_id'] ;
  }
  @Input('manufacturing_order_line_id') set $inParams_manufacturing_order_line_id(value: any) {
    this.inParams['manufacturing_order_line_id'] = value;
  }
  get $inParams_manufacturing_order_line_id(): any {
    return this.inParams['manufacturing_order_line_id'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('task_chain_head') set $inParams_task_chain_head(value: any) {
    this.inParams['task_chain_head'] = value;
  }
  get $inParams_task_chain_head(): any {
    return this.inParams['task_chain_head'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_manufacturing_orders_manufacturing_production_components_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Manufacturing order production components';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      manufacturing_order_id:  $grid.inParams.manufacturing_order_id ,
      manufacturing_order_line_id:  $grid.inParams.manufacturing_order_line_id ,
      operation_code:  47 ,
      task_chain_head:  $grid.inParams.task_chain_head ,
    };
    try {
      const data = await this.$datasources.Manufacturing.ds_get_manufacturing_order_production_confirmation_data.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_manufacturing_orders_manufacturing_production_components_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_manufacturing_orders_manufacturing_production_components_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootprintManager_manufacturing_orders_manufacturing_production_components_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_orders_manufacturing_production_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_orders_manufacturing_production_components_grid.on_grid_data_loaded');
  
  const components = await $datasources.Manufacturing.ds_get_manufacturing_order_lines_components_grid.get({finished_good_id: $grid.inParams.manufacturing_order_line_id, warehouse_id: $grid.inParams.warehouse_id});
  
  const confirmedProductionScrappedRows = await $datasources.Manufacturing.ds_get_manufacturing_order_production_confirmation_data.get({manufacturing_order_id: $grid.inParams.manufacturing_order_id
                                                                                                                              , manufacturing_order_line_id: $grid.inParams.manufacturing_order_line_id
                                                                                                                              , operation_code: 48
                                                                                                                              , task_chain_head: $grid.inParams.task_chain_head});
  
  $grid.rows.forEach( row => {
      let manufacturedScrappedComponent = null;
  
      if( $utils.isDefined(confirmedProductionScrappedRows.result) )
          manufacturedScrappedComponent = confirmedProductionScrappedRows.result.find(item => item.MaterialId == row.entity.MaterialId && item.LotId == row.entity.LotId);
  
      row.cells.scrapped_quantity.displayControl.text = manufacturedScrappedComponent?.ActualPackagedAmount?.toString();
  
      const consumedComponentSum = $grid.rows
      .filter(item => item.entity.MaterialId === row.entity.MaterialId)
      .reduce((acc, item) => acc + item.entity.ActualPackagedAmount, 0);
  
      const currentComponent = ( components.result.find( component => component.MaterialId == row.entity.MaterialId ).PackagedAmount * components.result.find( component => component.MaterialId == row.entity.MaterialId ).FinishedGoodLine?.PackagedAmount ) ?? 0;
  
      console.log( currentComponent );
      console.log( consumedComponentSum );
  
      const remainingQuantity = Math.max(currentComponent - (consumedComponentSum ?? 0), 0);
  
      row.cells.remaining_quantity.displayControl.text = '0';
  
      if( remainingQuantity > 0 )
      {
          row.cells.remaining_quantity.displayControl.text = remainingQuantity.toString();
      }
  })
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
