import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_bol_tasksService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { order_id?: number, operation_code_ids?: number[], actual_source_license_plate_ids?: number[], actual_target_license_plate_ids?: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, GrossWeight?: number, NetWeight?: number, ExpectedPackagedPack?: { Id?: number, ShortName?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string, ShippingContainer?: { Id?: number, LookupCode?: string } }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string } }, Material?: { Id?: number, Description?: string, LookupCode?: string, Project?: { DisplayLotLookupOnBOL?: boolean, DisplaySystemLotOnBOL?: string, DisplayVLotExpOnBOL?: boolean } } }[] }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_bol_tasks/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { order_id?: number, operation_code_ids?: number[], actual_source_license_plate_ids?: number[], actual_target_license_plate_ids?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, GrossWeight?: number, NetWeight?: number, ExpectedPackagedPack?: { Id?: number, ShortName?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string, ShippingContainer?: { Id?: number, LookupCode?: string } }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string } }, Material?: { Id?: number, Description?: string, LookupCode?: string, Project?: { DisplayLotLookupOnBOL?: boolean, DisplaySystemLotOnBOL?: string, DisplayVLotExpOnBOL?: boolean } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_bol_tasks/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { order_id?: number, operation_code_ids?: number[], actual_source_license_plate_ids?: number[], actual_target_license_plate_ids?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, GrossWeight?: number, NetWeight?: number, ExpectedPackagedPack?: { Id?: number, ShortName?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string, ShippingContainer?: { Id?: number, LookupCode?: string } }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string } }, Material?: { Id?: number, Description?: string, LookupCode?: string, Project?: { DisplayLotLookupOnBOL?: boolean, DisplaySystemLotOnBOL?: string, DisplayVLotExpOnBOL?: boolean } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_bol_tasks/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      order_id?: number, operation_code_ids?: number[], actual_source_license_plate_ids?: number[], actual_target_license_plate_ids?: number[]    }) {
      return false;
    }
}
