import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_footprint_generic_json_adjustment_export_dataService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { project?: string, material?: string, renderLots?: boolean, renderLicensePlates?: boolean, renderSerialNumbers?: boolean, hasTransmissions?: boolean, warehouse?: string, modifiedFrom?: string, modifiedTo?: string, take?: number, taskIds?: number[], typeIds?: number[], debug?: boolean, dateFormat?: string, operationCode?: string }): Promise<{ DatexAdjustmentInfo?: $frontendTypes.FootprintApiManager.DatexAdjustmentInfo, taskIds?: number[], messages?: any[] }> 
  {
    let url = `${environment.backendUrl}api/FootprintApiManager/functions/footprint_generic_json_adjustment_export_data`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

