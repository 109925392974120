import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_ds_base_order_editorService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { order_id: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Notes?: string, OwnerReference?: string, RequestedDeliveryDate?: string, VendorReference?: string, Account?: { Name?: string }, OrderClass?: { Name?: string }, Project?: { Name?: string, Owner?: { Name?: string } }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { LookupCode?: string, SealId?: string, TrackingIdentifier?: string, TrailerId?: string } }[], PreferredWarehouse?: { Name?: string }, Status?: { Name?: string }, PreferredCarrier?: { Name?: string }, PreferredCarrierServiceType?: { Name?: string } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.order_id)) {
      missingRequiredInParams.push('\'order_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootprintApiManager/datasources/ds_base_order_editor/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      order_id: number    }) {
        if(isNil(inParams.order_id)) {
          return true; 
        }
      return false;
    }
}
