import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { app_DatasourceService } from './app.datasource.index';

interface IInventory_inventory_by_project_reportServiceInParams {
  projectId: number}

interface IInventory_inventory_by_project_reportServiceData {
  Header1?: { result?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, LicensePlate?: { LookupCode?: string, NetWeight?: number, WeightUomId?: number, Location?: { Name?: string }, Status?: { Name?: string } }, Lot?: { LookupCode?: string, ReceiveDate?: string, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, MaterialGroup?: { Name?: string }, Project?: { Id?: number, LookupCode?: string } }, VendorLot?: { ExpirationDate?: string, LookupCode?: string } }, Packaged?: { Name?: string }, converted_license_plate_weight?: { convertedWeight?: number } }[] }
}

@Injectable({ providedIn: 'root' })
export class Inventory_inventory_by_project_reportService extends ReportBaseService<IInventory_inventory_by_project_reportServiceInParams, IInventory_inventory_by_project_reportServiceData> {

  protected reportReferenceName = 'custom_inventory_report_by_project';
  protected appReferenceName = 'app';

  constructor(
    $utils: UtilsService,
    private $datasources: app_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IInventory_inventory_by_project_reportServiceInParams) {
  }

  protected async getData(inParams: IInventory_inventory_by_project_reportServiceInParams): Promise<IInventory_inventory_by_project_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IInventory_inventory_by_project_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IInventory_inventory_by_project_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        projectId:  $report.inParams.projectId 
      };
      
      const dsData = await this.$datasources.app.custom_ds_inventory_report_by_project.get(dsParams);
      
      data.Header1 = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
