import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintApiManager_ShellService } from './FootprintApiManager.shell.service';
import { FootprintApiManager_OperationService } from './FootprintApiManager.operation.service';
import { FootprintApiManager_DatasourceService } from './FootprintApiManager.datasource.index';
import { FootprintApiManager_FlowService } from './FootprintApiManager.flow.index';
import { FootprintApiManager_ReportService } from './FootprintApiManager.report.index';
import { FootprintApiManager_LocalizationService } from './FootprintApiManager.localization.service';
import { FootprintApiManager_transaction_types_grid_ComponentContextService } from './FootprintApiManager.transaction_types_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintApiManager.frontend.types'
import { $frontendTypes as $types} from './FootprintApiManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintApiManager_transaction_types_gridComponentEntity {
  id?: string, transaction_type?: string}



class FootprintApiManager_transaction_types_gridComponentRowModel extends GridRowModel {
  grid: FootprintApiManager_transaction_types_gridComponent;
  entity: IFootprintApiManager_transaction_types_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    transaction_type: null,
  }



  constructor(
    grid: FootprintApiManager_transaction_types_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintApiManager_ShellService,
private $datasources: FootprintApiManager_DatasourceService,
private $flows: FootprintApiManager_FlowService,
private $reports: FootprintApiManager_ReportService,
private $localization: FootprintApiManager_LocalizationService,
private $operations: FootprintApiManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintApiManager_transaction_types_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.transaction_type = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.transaction_type,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintApiManager_transaction_types_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintApiManager_transaction_types_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.$datasources.FootprintApiManager.ds_storage_transaction_types.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintApiManager_transaction_types_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.transaction_type.displayControl as TextModel).text = $row.entity.transaction_type;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }


}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintApiManager-transaction_types_grid',
  templateUrl: './FootprintApiManager.transaction_types_grid.component.html'
})
export class FootprintApiManager_transaction_types_gridComponent extends BaseComponent implements OnInit, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintApiManager_transaction_types_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;



  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     transaction_type: new GridHeaderModel(new HeaderStyles(null, null), 'transaction_type', 'Transaction type', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintApiManager_transaction_types_gridComponentRowModel[] = [];


  topToolbar = {
      refresh_grid: new ToolModel(new ButtonModel('refresh_grid', new ButtonStyles(null, null), false, false, false, 'Refresh', 'icon-ic_fluent_arrow_clockwise_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      delete_selected: new ToolModel(new ButtonModel('delete_selected', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintApiManager_ShellService,
    private $datasources: FootprintApiManager_DatasourceService,
    private $flows: FootprintApiManager_FlowService,
    private $reports: FootprintApiManager_ReportService,
    private $localization: FootprintApiManager_LocalizationService,
    private $operations: FootprintApiManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintApiManager_transaction_types_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Transaction types grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
      const data = await this.$datasources.FootprintApiManager.ds_storage_transaction_types.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintApiManager_transaction_types_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintApiManager_transaction_types_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintApiManager_transaction_types_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootprintApiManager_transaction_types_gridComponent,
  
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_transaction_types_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/13/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { transaction_type: string, id: string, errorMessages: string[] }[] = [];
  let resubmitted_records = [];
  
  let logs = (await $flows.FootprintApiManager.storage_logs({action: "", integration_name: ""})).payload;
  let log_payload: typeof logs[0];
  
  if (selected_rows.length === 0) {
      $shell.FootprintApiManager.openErrorDialog('Delete error!', 'No records were selected!');
      return;
  } else {
  
      const confirm_purge = (await $shell.FootprintApiManager.openConfirmationDialog(`Confirm delete!`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let current_error: string;
              let success = false;
              current_error = null;
  
              try {
  
                  await $flows.FootprintApiManager.list_transaction_types({action: "Delete", transaction_type: row.entity.transaction_type});
  
                  log_payload = {
                      "process": "transaction_types_grid",
                      "level": 1,
                      "notes": `Deleted record! Transaction Type: [${row.entity.transaction_type}]`
                  };
  
                  await $flows.FootprintApiManager.storage_logs({ action: "Write", payload: log_payload, integration_name: "" });
  
                  success = true;
  
              } catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
                  if (!$utils.isDefined(target_error.message)) {
                      target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
                  };
  
                  current_error = target_error.message;
              };
  
              if (success) {
                  resubmitted_records.push(row.entity.id.toString())
              } else {
                  errors.push({
                      transaction_type: row.entity.transaction_type, id: row.entity.id, errorMessages: [current_error]
                  });
              };
          };
  
          if ($utils.isDefined(errors)) {
  
              await $shell.FootprintApiManager.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  resubmitted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: resubmitted_records })).formattedValue} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id} transaction_type: ${error.transaction_type}` })))
          };
      };
  };
  
  $grid.refresh();
  }
  on_refresh_clicked(event = null) {
    return this.on_refresh_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refresh_clickedInternal(
    $grid: FootprintApiManager_transaction_types_gridComponent,
  
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_transaction_types_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/31/2024
  
  await $flows.FootprintApiManager.list_transaction_types({action: "Refresh"});
  
  $grid.refresh();
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintApiManager_transaction_types_gridComponent,
  
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_transaction_types_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/31/2024
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_selected.control.readOnly = (selectedRowsCount > 0 ? false : true);
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintApiManager_transaction_types_gridComponent,
  
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_transaction_types_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/31/2024
  
  $grid.title = 'FootPrint API Manager Transaction Types Grid'
  
  $grid.headers.transaction_type.hidden = true;
  
  $grid.topToolbar.delete_selected.hidden = true;
  $grid.topToolbar.refresh_grid.hidden = true;
  
  $grid.topToolbar.delete_selected.control.readOnly = true;
  
  if (await $operations.FootprintApiManager.Enable_ApiManager_TransactionTypesGrid.isAuthorized()) {
      $grid.topToolbar.delete_selected.hidden = false;
      $grid.topToolbar.refresh_grid.hidden = false;
  };
  
  if (await $operations.FootprintApiManager.Enable_ApiManager_TransactionTypesGrid.isAuthorized()) {
      $grid.headers.transaction_type.hidden = false;
  };
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
