import { Component, OnInit, ElementRef, ViewChild, forwardRef, Input, SimpleChanges, OnChanges, HostListener, HostBinding, Output, EventEmitter, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil, set } from 'lodash-es';
import { Styles } from './models/style';
import { SelectorComponent } from './components/selector.component';
import { ESelectorType } from './components/selector.component';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintApiManager_ShellService } from './FootprintApiManager.shell.service';
import { FootprintApiManager_OperationService } from './FootprintApiManager.operation.service';
import { FootprintApiManager_DatasourceService } from './FootprintApiManager.datasource.index';
import { FootprintApiManager_FlowService } from './FootprintApiManager.flow.index';
import { FootprintApiManager_ReportService } from './FootprintApiManager.report.index';
import { FootprintApiManager_LocalizationService } from './FootprintApiManager.localization.service';
import { FootprintApiManager_transaction_types_dd_ComponentContextService } from './FootprintApiManager.transaction_types_dd.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintApiManager.frontend.types'
import { $frontendTypes as $types} from './FootprintApiManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintApiManager-transaction_types_dd_single',
  templateUrl: './FootprintApiManager.transaction_types_dd_single.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FootprintApiManager_transaction_types_dd_singleComponent),
      multi: true
    }
  ]
})
export class FootprintApiManager_transaction_types_dd_singleComponent extends BaseComponent implements OnInit, ControlValueAccessor {

  @Input() type?: ESelectorType;
  _disabled = false;
  @Input() get disabled() { 
      return this._disabled 
      ; }
  set disabled(val: any) { this._disabled = val; }

  @Input() placeholder = '';
  @Input() styles: Styles;
  @Input() tooltip = '';

  @Output() displayTextChange: EventEmitter<string> = new EventEmitter();

  @ViewChild(SelectorComponent) selector: SelectorComponent;
  
  @HostBinding('tabIndex') get tabIndex() { return -1; }
  @HostListener('focus')
  focus() {
    this.selector?.focus();
  }

  get isMulti() {
    return false;
  }

  get isSingle() {
    return true;
  }



  fullTextSearch: string;

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintApiManager_ShellService,
    private $datasources: FootprintApiManager_DatasourceService,
    private $flows: FootprintApiManager_FlowService,
    private $reports: FootprintApiManager_ReportService,
    private $localization: FootprintApiManager_LocalizationService,
    private $operations: FootprintApiManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintApiManager_transaction_types_dd_ComponentContextService,
    private _elementRef: ElementRef
    ) {
    super();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  
  async $init() {
    this.selector?.init();
  }

  onKeyDown($event: KeyboardEvent) {
    //TODO:KeyDown    
  }

  //ControlValueAccessor
  _innerValue: any;

  onChange: any = () => { };
  onTouch: any = () => { };

  get value(): string | string[] {
    return this._innerValue;
  }

  set value(val: string | string[]) {
    if (this._innerValue !== val) {
      this._innerValue = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  writeValue(value: any): void {
    this._innerValue = value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }



  displayWithFn(value: any): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {

      const $selector = this;
      const $utils = this.$utils;

      this.fullTextSearch = null;
      const $fullTextSearch = null;
      this.$datasources.FootprintApiManager.ds_storage_transaction_types.getByKeys({
        $keys: [value],
        fullTextSearch:  $selector.fullTextSearch ,
      }).then(result => {
        const found = result.result[0];
        const e: { id?: string, transaction_type?: string } = found ?? {};
        const $option = { entity: e };
         const $entity = e;
        const displayTextResult = found ? ($option.entity.transaction_type) : 'No data to display';
        this.displayTextChange.emit(displayTextResult);
        resolve(displayTextResult);
      });

    });
    return promise;
  }

  optionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {

    const promise = new Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }>((resolve, reject) => {

      const $selector = this;
      const $utils = this.$utils;

      this.fullTextSearch = filterText;
      const $fullTextSearch = filterText;
      this.$datasources.FootprintApiManager.ds_storage_transaction_types.getList({
        $top: 25,
        $skip: 0,
        fullTextSearch:  $selector.fullTextSearch ,
      }).then(result => {
        resolve({
          list: result.result.map(item => {
            const $option = { entity: item };
            const $entity = item;
            const $resultOption = { key: null, name: $option.entity.transaction_type, disabled: null };
            $resultOption.key = $option.entity.id;
            return $resultOption;
          }),
          totalCount: result.totalCount,
          top: 25
        });
      });

    });

    return promise;

  }

}
