import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Orderful.frontend.types'

@Injectable({ providedIn: 'root' })
export class Orderful_orderful_generic_serial_numbersService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { project_lookup_code?: string, material_lookup_code?: string, packaged_amount?: number, packaging_short_name?: string, net_weight?: number, gross_weight?: number, weight_uom?: string }): Promise<{ serial_numbers?: $frontendTypes.Orderful.SerialNumber[] }> 
  {
    let url = `${environment.backendUrl}api/Orderful/functions/orderful_generic_serial_numbers`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

