<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.order_udfs.hasTabToShow" class="tab" data-cy="tab-order_udfs">
              <h2 [className]="tabs.order_udfs.active? 'active': ''" (click)="tabs.order_udfs.activate()">{{tabs.order_udfs.title}}</h2>
            </div>
            <div *ngIf="tabs.shipment_udfs.hasTabToShow" class="tab" data-cy="tab-shipment_udfs">
              <h2 [className]="tabs.shipment_udfs.active? 'active': ''" (click)="tabs.shipment_udfs.activate()">{{tabs.shipment_udfs.title}}</h2>
            </div>
          </div>
        
              <FootprintManager-entity_user_defined_field_values_grid *ngIf="tabs.order_udfs.active"
              #$tabs_order_udfs
              [entityType]="$tabs_order_udfs_entity_user_defined_field_values_grid_inParams_entityType"
              [entityKeys]="$tabs_order_udfs_entity_user_defined_field_values_grid_inParams_entityKeys"
              ($refreshEvent)="refresh(false, false, '$tabs_order_udfs')">
              </FootprintManager-entity_user_defined_field_values_grid>
              <FootprintManager-entity_user_defined_field_values_grid *ngIf="tabs.shipment_udfs.active"
              #$tabs_shipment_udfs
              [entityType]="$tabs_shipment_udfs_entity_user_defined_field_values_grid_inParams_entityType"
              [entityKeys]="$tabs_shipment_udfs_entity_user_defined_field_values_grid_inParams_entityKeys"
              ($refreshEvent)="refresh(false, false, '$tabs_shipment_udfs')">
              </FootprintManager-entity_user_defined_field_values_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>