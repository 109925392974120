<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="delete_message" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-only_current" *ngIf="!filters.only_current.hidden && !filters.only_current.removed" 
            class="field-container standard {{filters.only_current.invalid ? 'invalid' : ''}} {{filters.only_current.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.only_current.styles.style"
            [ngClass]="filters.only_current.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.only_current.label + (filters.only_current.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.only_current.label}}<span *ngIf="filters.only_current.required" class="required-asterisk">*</span>
          </label>
        </div>
        <mat-slide-toggle data-cy="slideToggle" formControlName="only_current"
                      color="primary"
                      class="datex-toggle"
                      [ngStyle]="filters.only_current.control.styles.style"
                      [ngClass]="filters.only_current.control.styles.classes"
                      [matTooltip]="filters.only_current.control.tooltip"
                      matTooltipClass="datex-control-tooltip">{{filters.only_current.control.label}}</mat-slide-toggle>
        <ng-container *ngIf="filters.only_current.invalid">
          <ng-container *ngFor="let error of filters.only_current.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-integration_name" *ngIf="!filters.integration_name.hidden && !filters.integration_name.removed" 
            class="field-container double {{filters.integration_name.invalid ? 'invalid' : ''}} {{filters.integration_name.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.integration_name.styles.style"
            [ngClass]="filters.integration_name.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.integration_name.label + (filters.integration_name.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.integration_name.label}}<span *ngIf="filters.integration_name.required" class="required-asterisk">*</span>
          </label>
        </div>
        <FootprintApiManager-integrations_dd_single 
            data-cy="selector"
            [type]="filters.integration_name.control.type"
            formControlName="integration_name"
            (displayTextChange)="filters.integration_name.control.displayText=$event"
            [placeholder]="filters.integration_name.control.placeholder"
            [styles]="filters.integration_name.control.styles"
            [tooltip]="filters.integration_name.control.tooltip"
        >
        </FootprintApiManager-integrations_dd_single>
        <ng-container *ngIf="filters.integration_name.invalid">
          <ng-container *ngFor="let error of filters.integration_name.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-direction" *ngIf="!filters.direction.hidden && !filters.direction.removed" 
            class="field-container standard {{filters.direction.invalid ? 'invalid' : ''}} {{filters.direction.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.direction.styles.style"
            [ngClass]="filters.direction.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.direction.label + (filters.direction.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.direction.label}}<span *ngIf="filters.direction.required" class="required-asterisk">*</span>
          </label>
        </div>
        <FootprintApiManager-directions_dd_single 
            data-cy="selector"
            [type]="filters.direction.control.type"
            formControlName="direction"
            (displayTextChange)="filters.direction.control.displayText=$event"
            [placeholder]="filters.direction.control.placeholder"
            [styles]="filters.direction.control.styles"
            [tooltip]="filters.direction.control.tooltip"
        >
        </FootprintApiManager-directions_dd_single>
        <ng-container *ngIf="filters.direction.invalid">
          <ng-container *ngFor="let error of filters.direction.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes"
          [matTooltip]="row.cells.id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="integration_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.integration_name.displayControl.styles.style"
          [ngClass]="row.cells.integration_name.displayControl.styles.classes"
          [matTooltip]="row.cells.integration_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.integration_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['integration_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.integration_name.editControl.readOnly || row.cells.integration_name.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.integration_name.editControl.placeholder}}"
            [ngStyle]="row.cells.integration_name.editControl.styles.style"
            [ngClass]="row.cells.integration_name.editControl.styles.classes"
            [matTooltip]="row.cells.integration_name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="user_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.user_name.displayControl.styles.style"
          [ngClass]="row.cells.user_name.displayControl.styles.classes"
          [matTooltip]="row.cells.user_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.user_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['user_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.user_name.editControl.readOnly || row.cells.user_name.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.user_name.editControl.placeholder}}"
            [ngStyle]="row.cells.user_name.editControl.styles.style"
            [ngClass]="row.cells.user_name.editControl.styles.classes"
            [matTooltip]="row.cells.user_name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.type.displayControl.styles.style"
          [ngClass]="row.cells.type.displayControl.styles.classes"
          [matTooltip]="row.cells.type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['type_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.type.editControl.readOnly || row.cells.type.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.type.editControl.placeholder}}"
            [ngStyle]="row.cells.type.editControl.styles.style"
            [ngClass]="row.cells.type.editControl.styles.classes"
            [matTooltip]="row.cells.type.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="identifier">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.identifier.displayControl.styles.style"
          [ngClass]="row.cells.identifier.displayControl.styles.classes"
          [matTooltip]="row.cells.identifier.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.identifier.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['identifier_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.identifier.editControl.readOnly || row.cells.identifier.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.identifier.editControl.placeholder}}"
            [ngStyle]="row.cells.identifier.editControl.styles.style"
            [ngClass]="row.cells.identifier.editControl.styles.classes"
            [matTooltip]="row.cells.identifier.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="direction">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.direction.displayControl.styles.style"
          [ngClass]="row.cells.direction.displayControl.styles.classes"
          [matTooltip]="row.cells.direction.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.direction.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['direction_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.direction.editControl.readOnly || row.cells.direction.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.direction.editControl.placeholder}}"
            [ngStyle]="row.cells.direction.editControl.styles.style"
            [ngClass]="row.cells.direction.editControl.styles.classes"
            [matTooltip]="row.cells.direction.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="count">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.count.displayControl.styles.style"
          [ngClass]="row.cells.count.displayControl.styles.classes"
          [matTooltip]="row.cells.count.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.count.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['count_edit']"
            matInput
            numberBox
            [format]="row.cells.count.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.count.editControl.readOnly || row.cells.count.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.count.editControl.placeholder}}"
            [ngStyle]="row.cells.count.editControl.styles.style"
            [ngClass]="row.cells.count.editControl.styles.classes"
            [matTooltip]="row.cells.count.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_date.displayControl.styles.style"
          [ngClass]="row.cells.created_date.displayControl.styles.classes"
          [matTooltip]="row.cells.created_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.modified_date.displayControl.styles.style"
          [ngClass]="row.cells.modified_date.displayControl.styles.classes"
          [matTooltip]="row.cells.modified_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.modified_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
