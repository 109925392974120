import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_ds_custom_inventory_reportService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { project_ids?: number[], owner_ids?: number[], from_date: string, to_date: string, operation_code_ids: number[] }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, CompletedDateTime?: string, GrossWeight?: number, LotId?: number, MaterialId?: number, NetWeight?: number, OrderId?: number, StatusId?: number, WarehouseId?: number, WeightUomId?: number, Lot?: { Id?: number, LookupCode?: string, MaterialId?: number, VendorLotId?: number }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string }, Project?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string, LicensePlates?: { Id?: number, LookupCode?: string }[] }, Order?: { Id?: number, OwnerReference?: string, VendorReference?: string, Account?: { Name?: string }, Addresses?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }[], ShipmentOrderLookups?: { OrderId?: number }[] } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.from_date)) {
      missingRequiredInParams.push('\'from_date\'');
    }
    if (isNil(inParams.to_date)) {
      missingRequiredInParams.push('\'to_date\'');
    }
    if (isNil(inParams.operation_code_ids)) {
      missingRequiredInParams.push('\'operation_code_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/ds_custom_inventory_report/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { project_ids?: number[], owner_ids?: number[], from_date: string, to_date: string, operation_code_ids: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, CompletedDateTime?: string, GrossWeight?: number, LotId?: number, MaterialId?: number, NetWeight?: number, OrderId?: number, StatusId?: number, WarehouseId?: number, WeightUomId?: number, Lot?: { Id?: number, LookupCode?: string, MaterialId?: number, VendorLotId?: number }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string }, Project?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string, LicensePlates?: { Id?: number, LookupCode?: string }[] }, Order?: { Id?: number, OwnerReference?: string, VendorReference?: string, Account?: { Name?: string }, Addresses?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }[], ShipmentOrderLookups?: { OrderId?: number }[] } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.from_date)) {
      missingRequiredInParams.push('\'from_date\'');
    }
    if (isNil(inParams.to_date)) {
      missingRequiredInParams.push('\'to_date\'');
    }
    if (isNil(inParams.operation_code_ids)) {
      missingRequiredInParams.push('\'operation_code_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/ds_custom_inventory_report/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { project_ids?: number[], owner_ids?: number[], from_date: string, to_date: string, operation_code_ids: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, CompletedDateTime?: string, GrossWeight?: number, LotId?: number, MaterialId?: number, NetWeight?: number, OrderId?: number, StatusId?: number, WarehouseId?: number, WeightUomId?: number, Lot?: { Id?: number, LookupCode?: string, MaterialId?: number, VendorLotId?: number }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string }, Project?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string, LicensePlates?: { Id?: number, LookupCode?: string }[] }, Order?: { Id?: number, OwnerReference?: string, VendorReference?: string, Account?: { Name?: string }, Addresses?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }[], ShipmentOrderLookups?: { OrderId?: number }[] } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.from_date)) {
      missingRequiredInParams.push('\'from_date\'');
    }
    if (isNil(inParams.to_date)) {
      missingRequiredInParams.push('\'to_date\'');
    }
    if (isNil(inParams.operation_code_ids)) {
      missingRequiredInParams.push('\'operation_code_ids\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/ds_custom_inventory_report/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      project_ids?: number[], owner_ids?: number[], from_date: string, to_date: string, operation_code_ids: number[]    }) {
        if(isNil(inParams.from_date)) {
          return true; 
        }
        if(isNil(inParams.to_date)) {
          return true; 
        }
        if(isNil(inParams.operation_code_ids)) {
          return true; 
        }
      return false;
    }
}
