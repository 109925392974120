import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_ds_storage_controlService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { type?: string, direction?: string, fullTextSearch?: string, integration_name?: string, user_name?: string, current?: boolean, $top?: number, $skip?: number }): Promise<{ result?: { id?: string, type?: string, identifier?: string, direction?: string, count?: number, created_date?: string, modified_date?: string, integration_name?: string, user_name?: string }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/FootprintApiManager/datasources/ds_storage_control/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { type?: string, direction?: string, fullTextSearch?: string, integration_name?: string, user_name?: string, current?: boolean, $keys: string[] }): Promise<{ result?: { id?: string, type?: string, identifier?: string, direction?: string, count?: number, created_date?: string, modified_date?: string, integration_name?: string, user_name?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootprintApiManager/datasources/ds_storage_control/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      type?: string, direction?: string, fullTextSearch?: string, integration_name?: string, user_name?: string, current?: boolean    }) {
      return false;
    }
}
