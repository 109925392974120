import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_inventory_report_shipping_detailsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { project_ids: number[], warehouse_ids: number[], material_ids?: number[], vendor_lot_ids?: number[], licenseplate_ids?: number[] }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number, LicensePlate?: { GrossWeight?: number, LookupCode?: string, NetWeight?: number, ShipmentId?: number, WeightUomId?: number, Warehouse?: { Id?: number, Name?: string }, Shipment?: { Id?: number, OrderLookups?: { OrderId?: number, Order?: { BillingAddresId?: number, LookupCode?: string, OwnerReference?: string, Account?: { Name?: string } } }[] }, ShippingContainer?: { Id?: number, LookupCode?: string } }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string, Project?: { LookupCode?: string, Name?: string } } }, Packaged?: { Id?: number, Name?: string, ShortName?: string }, serial_numbers?: { total_net_weight?: number, total_gross_weight?: number, weight_uom?: string, LicensePlateId?: number, LotId?: number, PackagingId?: number }, pick_task_id?: { Id?: number }, billing_address?: { Id?: number, City?: string, Country?: string, Line1?: string, Line2?: string, State?: string }, actual_packaged_amount?: number, actual_inventory_amount?: number, net_weight?: number, gross_weight?: number, operation_type_name?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.project_ids)) {
      missingRequiredInParams.push('\'project_ids\'');
    }
    if (isNil(inParams.warehouse_ids)) {
      missingRequiredInParams.push('\'warehouse_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_inventory_report_shipping_details/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { project_ids: number[], warehouse_ids: number[], material_ids?: number[], vendor_lot_ids?: number[], licenseplate_ids?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number, LicensePlate?: { GrossWeight?: number, LookupCode?: string, NetWeight?: number, ShipmentId?: number, WeightUomId?: number, Warehouse?: { Id?: number, Name?: string }, Shipment?: { Id?: number, OrderLookups?: { OrderId?: number, Order?: { BillingAddresId?: number, LookupCode?: string, OwnerReference?: string, Account?: { Name?: string } } }[] }, ShippingContainer?: { Id?: number, LookupCode?: string } }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string, Project?: { LookupCode?: string, Name?: string } } }, Packaged?: { Id?: number, Name?: string, ShortName?: string }, serial_numbers?: { total_net_weight?: number, total_gross_weight?: number, weight_uom?: string, LicensePlateId?: number, LotId?: number, PackagingId?: number }, pick_task_id?: { Id?: number }, billing_address?: { Id?: number, City?: string, Country?: string, Line1?: string, Line2?: string, State?: string }, actual_packaged_amount?: number, actual_inventory_amount?: number, net_weight?: number, gross_weight?: number, operation_type_name?: string }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.project_ids)) {
      missingRequiredInParams.push('\'project_ids\'');
    }
    if (isNil(inParams.warehouse_ids)) {
      missingRequiredInParams.push('\'warehouse_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_inventory_report_shipping_details/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { project_ids: number[], warehouse_ids: number[], material_ids?: number[], vendor_lot_ids?: number[], licenseplate_ids?: number[], $keys: { LicensePlateId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number, LicensePlate?: { GrossWeight?: number, LookupCode?: string, NetWeight?: number, ShipmentId?: number, WeightUomId?: number, Warehouse?: { Id?: number, Name?: string }, Shipment?: { Id?: number, OrderLookups?: { OrderId?: number, Order?: { BillingAddresId?: number, LookupCode?: string, OwnerReference?: string, Account?: { Name?: string } } }[] }, ShippingContainer?: { Id?: number, LookupCode?: string } }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string, Project?: { LookupCode?: string, Name?: string } } }, Packaged?: { Id?: number, Name?: string, ShortName?: string }, serial_numbers?: { total_net_weight?: number, total_gross_weight?: number, weight_uom?: string, LicensePlateId?: number, LotId?: number, PackagingId?: number }, pick_task_id?: { Id?: number }, billing_address?: { Id?: number, City?: string, Country?: string, Line1?: string, Line2?: string, State?: string }, actual_packaged_amount?: number, actual_inventory_amount?: number, net_weight?: number, gross_weight?: number, operation_type_name?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.project_ids)) {
      missingRequiredInParams.push('\'project_ids\'');
    }
    if (isNil(inParams.warehouse_ids)) {
      missingRequiredInParams.push('\'warehouse_ids\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_inventory_report_shipping_details/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      project_ids: number[], warehouse_ids: number[], material_ids?: number[], vendor_lot_ids?: number[], licenseplate_ids?: number[]    }) {
        if(isNil(inParams.project_ids)) {
          return true; 
        }
        if(isNil(inParams.warehouse_ids)) {
          return true; 
        }
      return false;
    }
}
