import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_move_serial_numbers_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { serial_numbers: $frontendTypes.FootprintApiManager.input_move_serial_number[], target_license_plate: { lookup: string, warehouse?: string } }): Promise<{ errors?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.serial_numbers)) {
      missingRequiredInParams.push('\'serial_numbers\'');
    }
    if (isNil(inParams.target_license_plate)) {
      missingRequiredInParams.push('\'target_license_plate\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootprintApiManager/functions/move_serial_numbers_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

