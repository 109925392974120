import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { EndeavorLabs_DatasourceService } from './EndeavorLabs.datasource.index';

interface IEndeavorLabs_predictions_reportServiceInParams {
  FromDate: string, ToDate: string, projectid?: number, whid?: number[]}

interface IEndeavorLabs_predictions_reportServiceData {
  source?: { result?: { prediction?: { ShipmentId?: number, Picktime?: number, Predict?: number, Load?: number, OrderLookup?: string, Owner?: string, pickstart?: string, pickend?: string, loadstart?: string, loadend?: string, Weight?: number, UOM?: string, warehouse?: string, project?: string, Actual?: number, shipmin?: string, shipmax?: string, loadmin?: string, loadmax?: string }[] } }
}

@Injectable({ providedIn: 'root' })
export class EndeavorLabs_predictions_reportService extends ReportBaseService<IEndeavorLabs_predictions_reportServiceInParams, IEndeavorLabs_predictions_reportServiceData> {

  protected reportReferenceName = 'predictions_report';
  protected appReferenceName = 'EndeavorLabs';

  constructor(
    $utils: UtilsService,
    private $datasources: EndeavorLabs_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IEndeavorLabs_predictions_reportServiceInParams) {
  }

  protected async getData(inParams: IEndeavorLabs_predictions_reportServiceInParams): Promise<IEndeavorLabs_predictions_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IEndeavorLabs_predictions_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IEndeavorLabs_predictions_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        fr:  $report.inParams.FromDate ,
        to:  $report.inParams.ToDate ,
        projectid:  $report.inParams.projectid ,
        whid:  $report.inParams.whid 
      };
      
      const dsData = await this.$datasources.EndeavorLabs.ds_prediction_flow_report.get(dsParams);
      
      data.source = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
