import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { app_DatasourceService } from './app.datasource.index';

interface Iapp_custom_inventory_reportServiceInParams {
  projectId: number}

interface Iapp_custom_inventory_reportServiceData {
  Header?: { result?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, LicensePlate?: { LookupCode?: string, NetWeight?: number, WeightUomId?: number, Location?: { Name?: string }, Status?: { Name?: string } }, Lot?: { LookupCode?: string, ReceiveDate?: string, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, MaterialGroup?: { Name?: string }, Project?: { LookupCode?: string } }, VendorLot?: { ExpirationDate?: string, LookupCode?: string } }, Packaged?: { Name?: string }, converted_license_plate_weight?: { convertedWeight?: number } }[] }
}

@Injectable({ providedIn: 'root' })
export class app_custom_inventory_reportService extends ReportBaseService<Iapp_custom_inventory_reportServiceInParams, Iapp_custom_inventory_reportServiceData> {

  protected reportReferenceName = 'custom_inventory_report';
  protected appReferenceName = 'app';

  constructor(
    $utils: UtilsService,
    private $datasources: app_DatasourceService,
    private $logger: CleanupLoggerService
    ) {
      super($utils);
      $logger.log('app', 'custom_inventory_report');
  }

  override throwIfMissingRequiredInParams (inParams: Iapp_custom_inventory_reportServiceInParams) {
  }

  protected async getData(inParams: Iapp_custom_inventory_reportServiceInParams): Promise<Iapp_custom_inventory_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: Iapp_custom_inventory_reportServiceInParams } = {
      inParams: inParams
    };

    const data: Iapp_custom_inventory_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        projectId:  $report.inParams.projectId 
      };
      
      const dsData = await this.$datasources.app.custom_ds_inventory_report.get(dsParams);
      
      data.Header = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
