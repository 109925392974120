import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { EndeavorLabs_endeavorlabs_library_homeComponent } from './EndeavorLabs.endeavorlabs_library_home.component';
import { EndeavorLabs_annotations_gridComponent } from './EndeavorLabs.annotations_grid.component';
import { EndeavorLabs_predictions_reportComponent } from './EndeavorLabs.predictions_report.component';
import { EndeavorLabs_PredictionsComponent } from './EndeavorLabs.Predictions.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class EndeavorLabs_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public EndeavorLabs: EndeavorLabs_ShellService = this;

  public openendeavorlabs_library_home(replaceCurrentView?: boolean) {
    this.$logger.log('EndeavorLabs', 'endeavorlabs_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'EndeavorLabs_endeavorlabs_library_home',
        component: EndeavorLabs_endeavorlabs_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openendeavorlabs_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('EndeavorLabs', 'endeavorlabs_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      EndeavorLabs_endeavorlabs_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openannotations_grid(inParams:{ OrderId?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('EndeavorLabs', 'annotations_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Annotations',
        referenceName: 'EndeavorLabs_annotations_grid',
        component: EndeavorLabs_annotations_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openannotations_gridDialog(
    inParams:{ OrderId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('EndeavorLabs', 'annotations_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      EndeavorLabs_annotations_gridComponent,
      'Annotations',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpredictions_report(inParams:{ FromDate: string, ToDate: string, projectid?: number, whid?: number[] }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'predictions report',
        referenceName: 'EndeavorLabs_predictions_report',
        component: EndeavorLabs_predictions_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpredictions_reportDialog(
    inParams:{ FromDate: string, ToDate: string, projectid?: number, whid?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      EndeavorLabs_predictions_reportComponent,
      'predictions report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'EndeavorLabs_endeavorlabs_library_home') {
      this.$logger.log('EndeavorLabs', 'endeavorlabs_library_home');
      const title = 'Home';
      const component = EndeavorLabs_endeavorlabs_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EndeavorLabs_annotations_grid') {
      this.$logger.log('EndeavorLabs', 'annotations_grid');
      const title = 'Annotations';
      const component = EndeavorLabs_annotations_gridComponent;
      const inParams:{ OrderId?: number } = { OrderId: null };
      if (!isNil(params.get('OrderId'))) {
        const paramValueString = params.get('OrderId');
        inParams.OrderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EndeavorLabs_predictions_report') {
      const title = 'predictions report';
      const component = EndeavorLabs_predictions_reportComponent;
      const inParams:{ FromDate: string, ToDate: string, projectid?: number, whid?: number[] } = { FromDate: null, ToDate: null, projectid: null, whid: [] };
      if (!isNil(params.get('FromDate'))) {
        const paramValueString = params.get('FromDate');
        // TODO: date
        inParams.FromDate = paramValueString;
              }
      if (!isNil(params.get('ToDate'))) {
        const paramValueString = params.get('ToDate');
        // TODO: date
        inParams.ToDate = paramValueString;
              }
      if (!isNil(params.get('projectid'))) {
        const paramValueString = params.get('projectid');
        inParams.projectid = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('whid'))) {
        const paramValueString = params.get('whid');
        inParams.whid = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EndeavorLabs_Predictions') {
      this.$logger.log('EndeavorLabs', 'Predictions');
      const title = 'Predictions';
      const component = EndeavorLabs_PredictionsComponent;
      const inParams:{ OrderId?: number } = { OrderId: null };
      if (!isNil(params.get('OrderId'))) {
        const paramValueString = params.get('OrderId');
        inParams.OrderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
