import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_ds_order_total_pickedService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number }): 
  Promise<{ result: { quantity?: number, TotalOrder?: number, net_weight?: number, gross_weight?: number, ShipmentLine?: { OrderId?: number }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string }, Material?: { LookupCode?: string, Description?: string, Project?: { DisplayLotLookupOnBOL?: boolean } } }, LicensePlate?: { Id?: number }, lot_lookup?: string, vendor_lot_lookup?: string, material_lookup?: string, description?: string, actual_target_license_plate?: number, display_system_lot_on_bol?: boolean }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/ds_order_total_picked/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { quantity?: number, TotalOrder?: number, net_weight?: number, gross_weight?: number, ShipmentLine?: { OrderId?: number }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string }, Material?: { LookupCode?: string, Description?: string, Project?: { DisplayLotLookupOnBOL?: boolean } } }, LicensePlate?: { Id?: number }, lot_lookup?: string, vendor_lot_lookup?: string, material_lookup?: string, description?: string, actual_target_license_plate?: number, display_system_lot_on_bol?: boolean }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/ds_order_total_picked/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, $keys: { ShipmentLine?: { OrderId?: number }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string }, Material?: { LookupCode?: string, Description?: string, Project?: { DisplayLotLookupOnBOL?: boolean } } }, LicensePlate?: { Id?: number } }[] }): 
  Promise<{ result: { quantity?: number, TotalOrder?: number, net_weight?: number, gross_weight?: number, ShipmentLine?: { OrderId?: number }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string }, Material?: { LookupCode?: string, Description?: string, Project?: { DisplayLotLookupOnBOL?: boolean } } }, LicensePlate?: { Id?: number }, lot_lookup?: string, vendor_lot_lookup?: string, material_lookup?: string, description?: string, actual_target_license_plate?: number, display_system_lot_on_bol?: boolean }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/ds_order_total_picked/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
      return false;
    }
}
