import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Reports_reports_homeComponent } from './Reports.reports_home.component';
import { Reports_custom_bol_reportComponent } from './Reports.custom_bol_report.component';
import { Reports_custom_case_pick_labelComponent } from './Reports.custom_case_pick_label.component';
import { Reports_custom_dock_appointment_reportComponent } from './Reports.custom_dock_appointment_report.component';
import { Reports_custom_inventory_activity_reportComponent } from './Reports.custom_inventory_activity_report.component';
import { Reports_custom_master_bol_reportComponent } from './Reports.custom_master_bol_report.component';
import { Reports_custom_pick_slip_by_orderId_reportComponent } from './Reports.custom_pick_slip_by_orderId_report.component';
import { Reports_custom_pick_slip_by_wave_id_reportComponent } from './Reports.custom_pick_slip_by_wave_id_report.component';
import { Reports_custom_shipping_label_reportComponent } from './Reports.custom_shipping_label_report.component';
import { Reports_custom_user_activity_reportComponent } from './Reports.custom_user_activity_report.component';


@Injectable({ providedIn: 'root' })
export class Reports_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
  ) {
    super(dialog, toastr);
  }

  public Reports: Reports_ShellService = this;

  public openreports_home(replaceCurrentView?: boolean) {
    this.$logger.log('Reports', 'reports_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Reports',
        referenceName: 'Reports_reports_home',
        component: Reports_reports_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openreports_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Reports', 'reports_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Reports_reports_homeComponent,
      'Reports',
      mode,
      dialogSize
    )
  }
  public opencustom_bol_report(inParams:{ orderId: number, shipmentId?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom BOL report',
        referenceName: 'Reports_custom_bol_report',
        component: Reports_custom_bol_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_bol_reportDialog(
    inParams:{ orderId: number, shipmentId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_bol_reportComponent,
      'Custom BOL report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_case_pick_label(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Case pick label',
        referenceName: 'Reports_custom_case_pick_label',
        component: Reports_custom_case_pick_labelComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_case_pick_labelDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_case_pick_labelComponent,
      'Case pick label',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_dock_appointment_report(inParams:{ dock_appointment_id?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Dock appointment report',
        referenceName: 'Reports_custom_dock_appointment_report',
        component: Reports_custom_dock_appointment_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_dock_appointment_reportDialog(
    inParams:{ dock_appointment_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_dock_appointment_reportComponent,
      'Dock appointment report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_activity_report(inParams:{ from_date: string, to_date: string, warehouse_ids: number[], project_ids: number[], material_ids?: number[], vendor_lot_ids?: number[] }, replaceCurrentView?: boolean) {
    this.$logger.log('Reports', 'custom_inventory_activity_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inventory Activity Report',
        referenceName: 'Reports_custom_inventory_activity_report',
        component: Reports_custom_inventory_activity_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_activity_reportDialog(
    inParams:{ from_date: string, to_date: string, warehouse_ids: number[], project_ids: number[], material_ids?: number[], vendor_lot_ids?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Reports', 'custom_inventory_activity_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_inventory_activity_reportComponent,
      'Inventory Activity Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_master_bol_report(inParams:{ load_container_id: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom Master BOL Report',
        referenceName: 'Reports_custom_master_bol_report',
        component: Reports_custom_master_bol_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_master_bol_reportDialog(
    inParams:{ load_container_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_master_bol_reportComponent,
      'Custom Master BOL Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_pick_slip_by_orderId_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom Pick Slip',
        referenceName: 'Reports_custom_pick_slip_by_orderId_report',
        component: Reports_custom_pick_slip_by_orderId_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_pick_slip_by_orderId_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_pick_slip_by_orderId_reportComponent,
      'Custom Pick Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_pick_slip_by_wave_id_report(inParams:{ waveId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom Pick Slip',
        referenceName: 'Reports_custom_pick_slip_by_wave_id_report',
        component: Reports_custom_pick_slip_by_wave_id_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_pick_slip_by_wave_id_reportDialog(
    inParams:{ waveId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_pick_slip_by_wave_id_reportComponent,
      'Custom Pick Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_shipping_label_report(inParams:{ order_ids: number[] }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Shipping label',
        referenceName: 'Reports_custom_shipping_label_report',
        component: Reports_custom_shipping_label_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_shipping_label_reportDialog(
    inParams:{ order_ids: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_shipping_label_reportComponent,
      'Shipping label',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_user_activity_report(inParams:{ from_date: string, to_date: string, warehouse_id?: number, operation_code_ids?: number[] }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'User Activity Report',
        referenceName: 'Reports_custom_user_activity_report',
        component: Reports_custom_user_activity_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_user_activity_reportDialog(
    inParams:{ from_date: string, to_date: string, warehouse_id?: number, operation_code_ids?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_user_activity_reportComponent,
      'User Activity Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Reports_reports_home') {
      this.$logger.log('Reports', 'reports_home');
      const title = 'Reports';
      const component = Reports_reports_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_bol_report') {
      const title = 'Custom BOL report';
      const component = Reports_custom_bol_reportComponent;
      const inParams:{ orderId: number, shipmentId?: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_case_pick_label') {
      const title = 'Case pick label';
      const component = Reports_custom_case_pick_labelComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_dock_appointment_report') {
      const title = 'Dock appointment report';
      const component = Reports_custom_dock_appointment_reportComponent;
      const inParams:{ dock_appointment_id?: number } = { dock_appointment_id: null };
      if (!isNil(params.get('dock_appointment_id'))) {
        const paramValueString = params.get('dock_appointment_id');
        inParams.dock_appointment_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_inventory_activity_report') {
      this.$logger.log('Reports', 'custom_inventory_activity_report');
      const title = 'Inventory Activity Report';
      const component = Reports_custom_inventory_activity_reportComponent;
      const inParams:{ from_date: string, to_date: string, warehouse_ids: number[], project_ids: number[], material_ids?: number[], vendor_lot_ids?: number[] } = { from_date: null, to_date: null, warehouse_ids: [], project_ids: [], material_ids: [], vendor_lot_ids: [] };
      if (!isNil(params.get('from_date'))) {
        const paramValueString = params.get('from_date');
        // TODO: date
        inParams.from_date = paramValueString;
              }
      if (!isNil(params.get('to_date'))) {
        const paramValueString = params.get('to_date');
        // TODO: date
        inParams.to_date = paramValueString;
              }
      if (!isNil(params.get('warehouse_ids'))) {
        const paramValueString = params.get('warehouse_ids');
        inParams.warehouse_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('project_ids'))) {
        const paramValueString = params.get('project_ids');
        inParams.project_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('material_ids'))) {
        const paramValueString = params.get('material_ids');
        inParams.material_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('vendor_lot_ids'))) {
        const paramValueString = params.get('vendor_lot_ids');
        inParams.vendor_lot_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_master_bol_report') {
      const title = 'Custom Master BOL Report';
      const component = Reports_custom_master_bol_reportComponent;
      const inParams:{ load_container_id: number } = { load_container_id: null };
      if (!isNil(params.get('load_container_id'))) {
        const paramValueString = params.get('load_container_id');
        inParams.load_container_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_pick_slip_by_orderId_report') {
      const title = 'Custom Pick Slip';
      const component = Reports_custom_pick_slip_by_orderId_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_pick_slip_by_wave_id_report') {
      const title = 'Custom Pick Slip';
      const component = Reports_custom_pick_slip_by_wave_id_reportComponent;
      const inParams:{ waveId: number } = { waveId: null };
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_shipping_label_report') {
      const title = 'Shipping label';
      const component = Reports_custom_shipping_label_reportComponent;
      const inParams:{ order_ids: number[] } = { order_ids: [] };
      if (!isNil(params.get('order_ids'))) {
        const paramValueString = params.get('order_ids');
        inParams.order_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_user_activity_report') {
      const title = 'User Activity Report';
      const component = Reports_custom_user_activity_reportComponent;
      const inParams:{ from_date: string, to_date: string, warehouse_id?: number, operation_code_ids?: number[] } = { from_date: null, to_date: null, warehouse_id: null, operation_code_ids: [] };
      if (!isNil(params.get('from_date'))) {
        const paramValueString = params.get('from_date');
        // TODO: date
        inParams.from_date = paramValueString;
              }
      if (!isNil(params.get('to_date'))) {
        const paramValueString = params.get('to_date');
        // TODO: date
        inParams.to_date = paramValueString;
              }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('operation_code_ids'))) {
        const paramValueString = params.get('operation_code_ids');
        inParams.operation_code_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    return result;
  }
}
