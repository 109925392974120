import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_shipping_label_reportServiceInParams {
  order_ids: number[]}

interface IReports_custom_shipping_label_reportServiceData {
  data?: { result?: { data?: { first_name?: string, line_1?: string, city?: string, state?: string, postal_code?: string, order_lookupcode?: string, owner_reference?: string, vendor_reference?: string, shipping_container_lookupcode?: string }[] } }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_shipping_label_reportService extends ReportBaseService<IReports_custom_shipping_label_reportServiceInParams, IReports_custom_shipping_label_reportServiceData> {

  protected reportReferenceName = 'custom_shipping_label_report';
  protected appReferenceName = 'Reports';

  constructor(
    $utils: UtilsService,
    private $datasources: Reports_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_shipping_label_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_shipping_label_reportServiceInParams): Promise<IReports_custom_shipping_label_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IReports_custom_shipping_label_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_shipping_label_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        order_id:  $report.inParams.order_ids 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_shipping_label_flow.get(dsParams);
      
      data.data = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
