import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_dock_appointments_dashboard_grid_load_container_dataService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { dock_appointment_ids: number[] }): 
  Promise<{ result: { DockAppointmentId?: number, LoadContainerId?: number, LoadContainer?: { Id?: number, LookupCode?: string, OrderType?: { Id?: number, Name?: string }, Shipments?: { Id?: number, LookupCode?: string, Type?: { Name?: string }, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, Project?: { LookupCode?: string }, OrderClass?: { Name?: string } } }[] }[] }, DockAppointment?: { Id?: number, CheckedInOn?: string, LookupCode?: string, ScheduledArrival?: string, AssignedLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledProject?: { LookupCode?: string }, Type?: { Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.dock_appointment_ids)) {
      missingRequiredInParams.push('\'dock_appointment_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_dock_appointments_dashboard_grid_load_container_data/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { dock_appointment_ids: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { DockAppointmentId?: number, LoadContainerId?: number, LoadContainer?: { Id?: number, LookupCode?: string, OrderType?: { Id?: number, Name?: string }, Shipments?: { Id?: number, LookupCode?: string, Type?: { Name?: string }, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, Project?: { LookupCode?: string }, OrderClass?: { Name?: string } } }[] }[] }, DockAppointment?: { Id?: number, CheckedInOn?: string, LookupCode?: string, ScheduledArrival?: string, AssignedLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledProject?: { LookupCode?: string }, Type?: { Name?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.dock_appointment_ids)) {
      missingRequiredInParams.push('\'dock_appointment_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_dock_appointments_dashboard_grid_load_container_data/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { dock_appointment_ids: number[], $keys: { DockAppointmentId?: number, LoadContainerId?: number }[] }): 
  Promise<{ result: { DockAppointmentId?: number, LoadContainerId?: number, LoadContainer?: { Id?: number, LookupCode?: string, OrderType?: { Id?: number, Name?: string }, Shipments?: { Id?: number, LookupCode?: string, Type?: { Name?: string }, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, Project?: { LookupCode?: string }, OrderClass?: { Name?: string } } }[] }[] }, DockAppointment?: { Id?: number, CheckedInOn?: string, LookupCode?: string, ScheduledArrival?: string, AssignedLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledLocation?: { Name?: string, Warehouse?: { Name?: string } }, ScheduledProject?: { LookupCode?: string }, Type?: { Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.dock_appointment_ids)) {
      missingRequiredInParams.push('\'dock_appointment_ids\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_dock_appointments_dashboard_grid_load_container_data/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      dock_appointment_ids: number[]    }) {
        if(isNil(inParams.dock_appointment_ids)) {
          return true; 
        }
      return false;
    }
}
