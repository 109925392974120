import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Orderful_ds_find_addressService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { Name?: string, LookupCode?: string, AccountType?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string, AttentionOf?: string, FirstName?: string, LastName?: string, ReferenceCode?: string, ContactType?: string }): 
  Promise<{ result: { Id?: number, AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string, Contacts?: { Id?: number, FirstName?: string, LastName?: string, ReferenceCode?: string, AccountsContactsLookup?: { AccountId?: number, Account?: { LookupCode?: string, Name?: string, Type?: { Name?: string } } }[], Type?: { Name?: string } }[] } }> 
  {
    let url = `${environment.backendUrl}api/Orderful/datasources/ds_find_address/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      Name?: string, LookupCode?: string, AccountType?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string, AttentionOf?: string, FirstName?: string, LastName?: string, ReferenceCode?: string, ContactType?: string    }) {
      return false;
    }
}
