import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_print_cloud_reportService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { packageName: string, reportName: string, reportInputs: any, printerName: string }): Promise<{ reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.packageName)) {
      missingRequiredInParams.push('\'packageName\'');
    }
    if (isNil(inParams.reportName)) {
      missingRequiredInParams.push('\'reportName\'');
    }
    if (isNil(inParams.reportInputs)) {
      missingRequiredInParams.push('\'reportInputs\'');
    }
    if (isNil(inParams.printerName)) {
      missingRequiredInParams.push('\'printerName\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootprintApiManager/functions/print_cloud_report`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

