<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="linecount" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
            >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="toolContainer">
      <label class="import-button">
        <input type="file" class="hidden" #excelFileInput (change)="$importExcel(excelFileInput)" multiple="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv" />
        <div class="button-label">
          <div class="button-icon"><i class="icon icon-ic_fluent_cloud_arrow_up_20_regular"></i></div>
          <div class="button-text">Import</div>
        </div>
      </label>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="order_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.order_id.displayControl.styles.style"
          [ngClass]="row.cells.order_id.displayControl.styles.classes"
          [matTooltip]="row.cells.order_id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.order_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="line_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.line_number.displayControl.styles.style"
          [ngClass]="row.cells.line_number.displayControl.styles.classes"
          [matTooltip]="row.cells.line_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.line_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['lookupcode_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.lookupcode.editControl.readOnly || row.cells.lookupcode.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.lookupcode.editControl.placeholder}}"
            [ngStyle]="row.cells.lookupcode.editControl.styles.style"
            [ngClass]="row.cells.lookupcode.editControl.styles.classes"
            [matTooltip]="row.cells.lookupcode.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.type.displayControl.styles.style"
          [ngClass]="row.cells.type.displayControl.styles.classes"
          [matTooltip]="row.cells.type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelOrderImport-inbound_outbound_dd_single 
        data-cy="selector"
        [type]="row.cells.type.editControl.type"
        [formControl]="row.formGroup.controls['type_edit']"
        (displayTextChange)="row.cells.type.editControl.displayText=$event"
        [placeholder]="row.cells.type.editControl.placeholder"
        [styles]="row.cells.type.editControl.styles"
        [tooltip]="row.cells.type.editControl.tooltip"
    >
    </ExcelOrderImport-inbound_outbound_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes"
          [matTooltip]="row.cells.project.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelOrderImport-projects_dd_single 
        data-cy="selector"
        [type]="row.cells.project.editControl.type"
        [formControl]="row.formGroup.controls['project_edit']"
        (displayTextChange)="row.cells.project.editControl.displayText=$event"
        [placeholder]="row.cells.project.editControl.placeholder"
        [styles]="row.cells.project.editControl.styles"
        [tooltip]="row.cells.project.editControl.tooltip"
      [statusId]="row.$fields_project_selector_inParams_statusId"
    >
    </ExcelOrderImport-projects_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material.displayControl.styles.style"
          [ngClass]="row.cells.material.displayControl.styles.classes"
          [matTooltip]="row.cells.material.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelOrderImport-materials_by_project_dd_single 
        data-cy="selector"
        [type]="row.cells.material.editControl.type"
        [formControl]="row.formGroup.controls['material_edit']"
        (displayTextChange)="row.cells.material.editControl.displayText=$event"
        [placeholder]="row.cells.material.editControl.placeholder"
        [styles]="row.cells.material.editControl.styles"
        [tooltip]="row.cells.material.editControl.tooltip"
      [projectId]="row.$fields_material_selector_inParams_projectId"
    >
    </ExcelOrderImport-materials_by_project_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.quantity.displayControl.styles.style"
          [ngClass]="row.cells.quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['quantity_edit']"
            matInput
            numberBox
            [format]="row.cells.quantity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.quantity.editControl.readOnly || row.cells.quantity.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.quantity.editControl.placeholder}}"
            [ngStyle]="row.cells.quantity.editControl.styles.style"
            [ngClass]="row.cells.quantity.editControl.styles.classes"
            [matTooltip]="row.cells.quantity.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packaging.displayControl.styles.style"
          [ngClass]="row.cells.packaging.displayControl.styles.classes"
          [matTooltip]="row.cells.packaging.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelOrderImport-material_packagings_dd_single 
        data-cy="selector"
        [type]="row.cells.packaging.editControl.type"
        [formControl]="row.formGroup.controls['packaging_edit']"
        (displayTextChange)="row.cells.packaging.editControl.displayText=$event"
        [placeholder]="row.cells.packaging.editControl.placeholder"
        [styles]="row.cells.packaging.editControl.styles"
        [tooltip]="row.cells.packaging.editControl.tooltip"
      [materialId]="row.$fields_packaging_selector_inParams_materialId"
    >
    </ExcelOrderImport-material_packagings_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate.displayControl.styles.classes"
          [matTooltip]="row.cells.licenseplate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['licenseplate_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.licenseplate.editControl.readOnly || row.cells.licenseplate.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.licenseplate.editControl.placeholder}}"
            [ngStyle]="row.cells.licenseplate.editControl.styles.style"
            [ngClass]="row.cells.licenseplate.editControl.styles.classes"
            [matTooltip]="row.cells.licenseplate.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.reference.displayControl.styles.style"
          [ngClass]="row.cells.reference.displayControl.styles.classes"
          [matTooltip]="row.cells.reference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['reference_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.reference.editControl.readOnly || row.cells.reference.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.reference.editControl.placeholder}}"
            [ngStyle]="row.cells.reference.editControl.styles.style"
            [ngClass]="row.cells.reference.editControl.styles.classes"
            [matTooltip]="row.cells.reference.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendor_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendor_reference.displayControl.styles.style"
          [ngClass]="row.cells.vendor_reference.displayControl.styles.classes"
          [matTooltip]="row.cells.vendor_reference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendor_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['vendor_reference_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.vendor_reference.editControl.readOnly || row.cells.vendor_reference.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.vendor_reference.editControl.placeholder}}"
            [ngStyle]="row.cells.vendor_reference.editControl.styles.style"
            [ngClass]="row.cells.vendor_reference.editControl.styles.classes"
            [matTooltip]="row.cells.vendor_reference.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes"
          [matTooltip]="row.cells.warehouse.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelOrderImport-warehouses_dd_single 
        data-cy="selector"
        [type]="row.cells.warehouse.editControl.type"
        [formControl]="row.formGroup.controls['warehouse_edit']"
        (displayTextChange)="row.cells.warehouse.editControl.displayText=$event"
        [placeholder]="row.cells.warehouse.editControl.placeholder"
        [styles]="row.cells.warehouse.editControl.styles"
        [tooltip]="row.cells.warehouse.editControl.tooltip"
      [typeId]="row.$fields_warehouse_selector_inParams_typeId"
    >
    </ExcelOrderImport-warehouses_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="error">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.error.displayControl.styles.style"
          [ngClass]="row.cells.error.displayControl.styles.classes"
          [matTooltip]="row.cells.error.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.error.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelOrderImport-lots_dd_single 
        data-cy="selector"
        [type]="row.cells.lot.editControl.type"
        [formControl]="row.formGroup.controls['lot_edit']"
        (displayTextChange)="row.cells.lot.editControl.displayText=$event"
        [placeholder]="row.cells.lot.editControl.placeholder"
        [styles]="row.cells.lot.editControl.styles"
        [tooltip]="row.cells.lot.editControl.tooltip"
      [materialId]="row.$fields_lot_selector_inParams_materialId"
    >
    </ExcelOrderImport-lots_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendor_lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendor_lot.displayControl.styles.style"
          [ngClass]="row.cells.vendor_lot.displayControl.styles.classes"
          [matTooltip]="row.cells.vendor_lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendor_lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelOrderImport-vendorLots_dd_single 
        data-cy="selector"
        [type]="row.cells.vendor_lot.editControl.type"
        [formControl]="row.formGroup.controls['vendor_lot_edit']"
        (displayTextChange)="row.cells.vendor_lot.editControl.displayText=$event"
        [placeholder]="row.cells.vendor_lot.editControl.placeholder"
        [styles]="row.cells.vendor_lot.editControl.styles"
        [tooltip]="row.cells.vendor_lot.editControl.tooltip"
      [materialId]="row.$fields_vendor_lot_selector_inParams_materialId"
    >
    </ExcelOrderImport-vendorLots_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="first_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.first_name.displayControl.styles.style"
          [ngClass]="row.cells.first_name.displayControl.styles.classes"
          [matTooltip]="row.cells.first_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.first_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['first_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.first_name.editControl.readOnly || row.cells.first_name.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.first_name.editControl.placeholder}}"
            [ngStyle]="row.cells.first_name.editControl.styles.style"
            [ngClass]="row.cells.first_name.editControl.styles.classes"
            [matTooltip]="row.cells.first_name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="last_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.last_name.displayControl.styles.style"
          [ngClass]="row.cells.last_name.displayControl.styles.classes"
          [matTooltip]="row.cells.last_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.last_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['last_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.last_name.editControl.readOnly || row.cells.last_name.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.last_name.editControl.placeholder}}"
            [ngStyle]="row.cells.last_name.editControl.styles.style"
            [ngClass]="row.cells.last_name.editControl.styles.classes"
            [matTooltip]="row.cells.last_name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="line1">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.line1.displayControl.styles.style"
          [ngClass]="row.cells.line1.displayControl.styles.classes"
          [matTooltip]="row.cells.line1.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.line1.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['line1_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.line1.editControl.readOnly || row.cells.line1.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.line1.editControl.placeholder}}"
            [ngStyle]="row.cells.line1.editControl.styles.style"
            [ngClass]="row.cells.line1.editControl.styles.classes"
            [matTooltip]="row.cells.line1.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="line2">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.line2.displayControl.styles.style"
          [ngClass]="row.cells.line2.displayControl.styles.classes"
          [matTooltip]="row.cells.line2.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.line2.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['line2_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.line2.editControl.readOnly || row.cells.line2.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.line2.editControl.placeholder}}"
            [ngStyle]="row.cells.line2.editControl.styles.style"
            [ngClass]="row.cells.line2.editControl.styles.classes"
            [matTooltip]="row.cells.line2.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="city">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.city.displayControl.styles.style"
          [ngClass]="row.cells.city.displayControl.styles.classes"
          [matTooltip]="row.cells.city.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.city.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['city_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.city.editControl.readOnly || row.cells.city.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.city.editControl.placeholder}}"
            [ngStyle]="row.cells.city.editControl.styles.style"
            [ngClass]="row.cells.city.editControl.styles.classes"
            [matTooltip]="row.cells.city.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="state">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.state.displayControl.styles.style"
          [ngClass]="row.cells.state.displayControl.styles.classes"
          [matTooltip]="row.cells.state.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.state.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['state_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.state.editControl.readOnly || row.cells.state.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.state.editControl.placeholder}}"
            [ngStyle]="row.cells.state.editControl.styles.style"
            [ngClass]="row.cells.state.editControl.styles.classes"
            [matTooltip]="row.cells.state.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="zip">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.zip.displayControl.styles.style"
          [ngClass]="row.cells.zip.displayControl.styles.classes"
          [matTooltip]="row.cells.zip.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.zip.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['zip_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.zip.editControl.readOnly || row.cells.zip.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.zip.editControl.placeholder}}"
            [ngStyle]="row.cells.zip.editControl.styles.style"
            [ngClass]="row.cells.zip.editControl.styles.classes"
            [matTooltip]="row.cells.zip.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="country">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.country.displayControl.styles.style"
          [ngClass]="row.cells.country.displayControl.styles.classes"
          [matTooltip]="row.cells.country.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.country.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['country_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.country.editControl.readOnly || row.cells.country.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.country.editControl.placeholder}}"
            [ngStyle]="row.cells.country.editControl.styles.style"
            [ngClass]="row.cells.country.editControl.styles.classes"
            [matTooltip]="row.cells.country.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="phone_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.phone_number.displayControl.styles.style"
          [ngClass]="row.cells.phone_number.displayControl.styles.classes"
          [matTooltip]="row.cells.phone_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.phone_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['phone_number_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.phone_number.editControl.readOnly || row.cells.phone_number.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.phone_number.editControl.placeholder}}"
            [ngStyle]="row.cells.phone_number.editControl.styles.style"
            [ngClass]="row.cells.phone_number.editControl.styles.classes"
            [matTooltip]="row.cells.phone_number.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="line_notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.line_notes.displayControl.styles.style"
          [ngClass]="row.cells.line_notes.displayControl.styles.classes"
          [matTooltip]="row.cells.line_notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.line_notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['line_notes_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.line_notes.editControl.readOnly || row.cells.line_notes.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.line_notes.editControl.placeholder}}"
            [ngStyle]="row.cells.line_notes.editControl.styles.style"
            [ngClass]="row.cells.line_notes.editControl.styles.classes"
            [matTooltip]="row.cells.line_notes.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
