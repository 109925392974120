import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_inventory_activity_report_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { from_date: string, to_date: string, warehouse_ids: number[], project_ids: number[], material_ids?: number[], vendor_lot_ids?: number[] }): Promise<{ result?: { object?: { completed_date_time?: string, operation_code?: string, owner_reference?: string, materialLookupCode?: string, vendorLotLookupCode?: string, actualPackagedAmount?: number, packageUOM?: string, materialDescription?: string, orderAccount?: string, address?: string, city?: string, state?: string, Country?: string, ExpirationDate?: string, licenseplate_lookup?: string, shipping_container_lookup?: string, netWeight?: number, grossWeight?: number, warehouseName?: string, projectName?: string, taskId?: number }[] } }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.from_date)) {
      missingRequiredInParams.push('\'from_date\'');
    }
    if (isNil(inParams.to_date)) {
      missingRequiredInParams.push('\'to_date\'');
    }
    if (isNil(inParams.warehouse_ids)) {
      missingRequiredInParams.push('\'warehouse_ids\'');
    }
    if (isNil(inParams.project_ids)) {
      missingRequiredInParams.push('\'project_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_inventory_activity_report_flow/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      from_date: string, to_date: string, warehouse_ids: number[], project_ids: number[], material_ids?: number[], vendor_lot_ids?: number[]    }) {
        if(isNil(inParams.from_date)) {
          return true; 
        }
        if(isNil(inParams.to_date)) {
          return true; 
        }
        if(isNil(inParams.warehouse_ids)) {
          return true; 
        }
        if(isNil(inParams.project_ids)) {
          return true; 
        }
      return false;
    }
}
