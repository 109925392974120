import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintApiManager_ShellService } from './FootprintApiManager.shell.service';
import { FootprintApiManager_OperationService } from './FootprintApiManager.operation.service';
import { FootprintApiManager_DatasourceService } from './FootprintApiManager.datasource.index';
import { FootprintApiManager_FlowService } from './FootprintApiManager.flow.index';
import { FootprintApiManager_ReportService } from './FootprintApiManager.report.index';
import { FootprintApiManager_LocalizationService } from './FootprintApiManager.localization.service';
import { FootprintApiManager_insert_log_form_ComponentContextService } from './FootprintApiManager.insert_log_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintApiManager.frontend.types'
import { $frontendTypes as $types} from './FootprintApiManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintApiManager-insert_log_form',
  templateUrl: './FootprintApiManager.insert_log_form.component.html'
})
export class FootprintApiManager_insert_log_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    level: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    process: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    level: new FieldModel(new NumberBoxModel(this.formGroup.controls['level'] as DatexFormControl, null, false, false, '', '3 and above will trigger alerts.', null)
, new ControlContainerStyles(null, null), 'Log level', true, false),
    process: new FieldModel(new TextBoxModel(this.formGroup.controls['process'] as DatexFormControl, null, false, false, 'Used to identify the source of the log entry.', null)
, new ControlContainerStyles(null, null), 'Process', true, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, false, 'These are the details of your log entry.', null)
, new ControlContainerStyles(null, null), 'Notes', true, false),
  };

  fieldsets = {
    log_insert: new FieldsetModel(
      'Lot insert',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    level: this.fields.level.control.valueChanges
    ,
    process: this.fields.process.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintApiManager_ShellService,
private $datasources: FootprintApiManager_DatasourceService,
private $flows: FootprintApiManager_FlowService,
private $reports: FootprintApiManager_ReportService,
private $localization: FootprintApiManager_LocalizationService,
private $operations: FootprintApiManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintApiManager_insert_log_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'insert_log_form';
  
    const $form = this;
    const $utils = this.$utils;

    
    


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .level
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .process
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel(event = null) {
    return this.on_cancelInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancelInternal(
    $form: FootprintApiManager_insert_log_formComponent,
  
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_insert_log_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintApiManager', 'insert_log_form.on_cancel');
  $form.close()
  }
  on_confirm(event = null) {
    return this.on_confirmInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirmInternal(
    $form: FootprintApiManager_insert_log_formComponent,
  
    $shell: FootprintApiManager_ShellService,
    $datasources: FootprintApiManager_DatasourceService,
    $flows: FootprintApiManager_FlowService,
    $reports: FootprintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintApiManager_OperationService,
    $utils: UtilsService,
    $context: FootprintApiManager_insert_log_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintApiManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintApiManager', 'insert_log_form.on_confirm');
  await $flows.FootprintApiManager.insert_log({
      level: $form.fields.level.control.value,
      process: $form.fields.process.control.value,
      notes: $form.fields.notes.control.value
  })
  $form.close()
  }
  //#endregion private flows
}
