<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="check_out" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_check_out($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="save_signature" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_check_out($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="refresh" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_refresh($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="separator1" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <div class="tool-separator"></div>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="appointment" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_appointment_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden && !filtersets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-search" *ngIf="!filters.search.hidden && !filters.search.removed" 
                                  class="field-container double {{filters.search.invalid ? 'invalid' : ''}} {{filters.search.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.search.styles.style"
                                  [ngClass]="filters.search.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.search.label + (filters.search.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.search.label}}<span *ngIf="filters.search.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="search"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{ (filters.search.control.readOnly || filters.search.control.disabled) ? 'readonly ' : ''}}"
                                      placeholder="{{filters.search.control.placeholder}}"
                                      [ngStyle]="filters.search.control.styles.style"
                                      [ngClass]="filters.search.control.styles.classes"
                                      [matTooltip]="filters.search.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.search.invalid">
                                <ng-container *ngFor="let error of filters.search.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-appointment_details" *ngIf="!filters.appointment_details.hidden && !filters.appointment_details.removed" 
                                  class="field-container double {{filters.appointment_details.invalid ? 'invalid' : ''}} {{filters.appointment_details.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.appointment_details.styles.style"
                                  [ngClass]="filters.appointment_details.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.appointment_details.label + (filters.appointment_details.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.appointment_details.label}}<span *ngIf="filters.appointment_details.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <div data-cy="text" class="datex-text" 
                                    [ngStyle]="filters.appointment_details.control.styles.style"
                                    [ngClass]="filters.appointment_details.control.styles.classes"
                                    [matTooltip]="filters.appointment_details.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">{{filters.appointment_details.control.text }}</div>
                              <ng-container *ngIf="filters.appointment_details.invalid">
                                <ng-container *ngFor="let error of filters.appointment_details.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-driver_details" *ngIf="!filters.driver_details.hidden && !filters.driver_details.removed" 
                                  class="field-container double {{filters.driver_details.invalid ? 'invalid' : ''}} {{filters.driver_details.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.driver_details.styles.style"
                                  [ngClass]="filters.driver_details.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.driver_details.label + (filters.driver_details.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.driver_details.label}}<span *ngIf="filters.driver_details.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <div data-cy="text" class="datex-text" 
                                    [ngStyle]="filters.driver_details.control.styles.style"
                                    [ngClass]="filters.driver_details.control.styles.classes"
                                    [matTooltip]="filters.driver_details.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">{{filters.driver_details.control.text }}</div>
                              <ng-container *ngIf="filters.driver_details.invalid">
                                <ng-container *ngFor="let error of filters.driver_details.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-signature_capture" *ngIf="!filters.signature_capture.hidden && !filters.signature_capture.removed" 
                                  class="field-container double {{filters.signature_capture.invalid ? 'invalid' : ''}} {{filters.signature_capture.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.signature_capture.styles.style"
                                  [ngClass]="filters.signature_capture.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.signature_capture.label + (filters.signature_capture.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.signature_capture.label}}<span *ngIf="filters.signature_capture.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <app-draw-box [options]="filters.signature_capture.control.options"
                                      formControlName="signature_capture"
                                      [tooltip]="filters.signature_capture.control.tooltip">
                              </app-draw-box>
                              <ng-container *ngIf="filters.signature_capture.invalid">
                                <ng-container *ngFor="let error of filters.signature_capture.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
              
                          <div class="fieldset-details" *ngIf="!filtersets.newGroup1.expanded">
              
              
                          </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.wait_time.hidden && !widgets.wait_time.removed" class="widget-container">
            <DockAppointments-wait_time_widget
            #$widgets_wait_time 
              [waitTime]="$widgets_wait_time_inParams_waitTime"
            ($refreshEvent)="refresh(false, false, '$widgets_wait_time')"
            >
            </DockAppointments-wait_time_widget>
          </div>
          <div *ngIf="!widgets.order_picked_percentage_widget.hidden && !widgets.order_picked_percentage_widget.removed" class="widget-container">
            <SalesOrders-order_picked_percentage_widget
            #$widgets_order_picked_percentage_widget 
              [orderId]="$widgets_order_picked_percentage_widget_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_picked_percentage_widget')"
            >
            </SalesOrders-order_picked_percentage_widget>
          </div>
          <div *ngIf="!widgets.order_loaded_percentage_widget.hidden && !widgets.order_loaded_percentage_widget.removed" class="widget-container">
            <SalesOrders-order_loaded_percentage_widget
            #$widgets_order_loaded_percentage_widget 
              [orderId]="$widgets_order_loaded_percentage_widget_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_loaded_percentage_widget')"
            >
            </SalesOrders-order_loaded_percentage_widget>
          </div>
          <div *ngIf="!widgets.order_total_pallet_count_widget.hidden && !widgets.order_total_pallet_count_widget.removed" class="widget-container">
            <SalesOrders-order_total_pallet_count_widget
            #$widgets_order_total_pallet_count_widget 
              [orderId]="$widgets_order_total_pallet_count_widget_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_total_pallet_count_widget')"
            >
            </SalesOrders-order_total_pallet_count_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.bill_of_lading.hasTabToShow" class="tab" data-cy="tab-bill_of_lading">
              <h2 [className]="tabs.bill_of_lading.active? 'active': ''" (click)="tabs.bill_of_lading.activate()">{{tabs.bill_of_lading.title}}</h2>
            </div>
            <div *ngIf="tabs.receiving_report.hasTabToShow" class="tab" data-cy="tab-receiving_report">
              <h2 [className]="tabs.receiving_report.active? 'active': ''" (click)="tabs.receiving_report.activate()">{{tabs.receiving_report.title}}</h2>
            </div>
          </div>
        
              <SalesOrders-outbound_bill_of_lading_report *ngIf="tabs.bill_of_lading.active"
              #$tabs_bill_of_lading
              [orderId]="$tabs_bill_of_lading_outbound_bill_of_lading_report_inParams_orderId"
              ($refreshEvent)="refresh(false, false, '$tabs_bill_of_lading')">
              </SalesOrders-outbound_bill_of_lading_report>
              <PurchaseOrders-receiving_report *ngIf="tabs.receiving_report.active"
              #$tabs_receiving_report
              [orderId]="$tabs_receiving_report_receiving_report_inParams_orderId"
              ($refreshEvent)="refresh(false, false, '$tabs_receiving_report')">
              </PurchaseOrders-receiving_report>
        </div>

      </ng-container>
    </div>
  </div>
</div>