import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Orderful_ds_get_completed_shipmentsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { project_lookup_code?: string, order_class?: string, shipped_date?: string, shipment_type?: number, has_order_transmissions?: boolean }): 
  Promise<{ result: { Id?: number, LookupCode?: string, ShippedDate?: string, StatusId?: number, TypeId?: number, OrderLookups?: { OrderId?: number, Order?: { IntegrationHubId?: string, IntegrationHubSourceId?: string, IntegrationHubSourceLookupCode?: string, IntegrationHubSourcePlatform?: string, LookupCode?: string, OrderStatusId?: number, OwnerReference?: string, VendorReference?: string, OrderClass?: { LookupCode?: string, Name?: string }, Status?: { Name?: string }, WavelengthConnectorOrderTransmissions?: { Id?: number, DirectionId?: number, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, TransactionId?: string, TypeId?: number, WavelengthConnectorTransmissionDirection?: { Name?: string }, WavelengthConnectorTransmissionType?: { Name?: string } }[], Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } } } }[], Status?: { Name?: string }, Type?: { Name?: string }, ActualWarehouse?: { Name?: string }, ExpectedWarehouse?: { Name?: string }, WavelengthConnectorShipmentTransmissions?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, DirectionId?: number, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, ShipmentId?: number, TransactionId?: string, TypeId?: number }[] }[] }> 
  {
    let url = `${environment.backendUrl}api/Orderful/datasources/ds_get_completed_shipments/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { project_lookup_code?: string, order_class?: string, shipped_date?: string, shipment_type?: number, has_order_transmissions?: boolean, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, ShippedDate?: string, StatusId?: number, TypeId?: number, OrderLookups?: { OrderId?: number, Order?: { IntegrationHubId?: string, IntegrationHubSourceId?: string, IntegrationHubSourceLookupCode?: string, IntegrationHubSourcePlatform?: string, LookupCode?: string, OrderStatusId?: number, OwnerReference?: string, VendorReference?: string, OrderClass?: { LookupCode?: string, Name?: string }, Status?: { Name?: string }, WavelengthConnectorOrderTransmissions?: { Id?: number, DirectionId?: number, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, TransactionId?: string, TypeId?: number, WavelengthConnectorTransmissionDirection?: { Name?: string }, WavelengthConnectorTransmissionType?: { Name?: string } }[], Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } } } }[], Status?: { Name?: string }, Type?: { Name?: string }, ActualWarehouse?: { Name?: string }, ExpectedWarehouse?: { Name?: string }, WavelengthConnectorShipmentTransmissions?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, DirectionId?: number, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, ShipmentId?: number, TransactionId?: string, TypeId?: number }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Orderful/datasources/ds_get_completed_shipments/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { project_lookup_code?: string, order_class?: string, shipped_date?: string, shipment_type?: number, has_order_transmissions?: boolean, $keys: number[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, ShippedDate?: string, StatusId?: number, TypeId?: number, OrderLookups?: { OrderId?: number, Order?: { IntegrationHubId?: string, IntegrationHubSourceId?: string, IntegrationHubSourceLookupCode?: string, IntegrationHubSourcePlatform?: string, LookupCode?: string, OrderStatusId?: number, OwnerReference?: string, VendorReference?: string, OrderClass?: { LookupCode?: string, Name?: string }, Status?: { Name?: string }, WavelengthConnectorOrderTransmissions?: { Id?: number, DirectionId?: number, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, TransactionId?: string, TypeId?: number, WavelengthConnectorTransmissionDirection?: { Name?: string }, WavelengthConnectorTransmissionType?: { Name?: string } }[], Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } } } }[], Status?: { Name?: string }, Type?: { Name?: string }, ActualWarehouse?: { Name?: string }, ExpectedWarehouse?: { Name?: string }, WavelengthConnectorShipmentTransmissions?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, DirectionId?: number, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, ShipmentId?: number, TransactionId?: string, TypeId?: number }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Orderful/datasources/ds_get_completed_shipments/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      project_lookup_code?: string, order_class?: string, shipped_date?: string, shipment_type?: number, has_order_transmissions?: boolean    }) {
      return false;
    }
}
