import { Inject, Injectable, Injector }from '@angular/core';


import { Reports_custom_bol_content_loadService } from './Reports.datasource.index';
import { Reports_custom_bol_shipping_content_flowService } from './Reports.datasource.index';
import { Reports_custom_bol_shipping_lp_container_detailsService } from './Reports.datasource.index';
import { Reports_custom_bol_shipping_lp_container_details_flowService } from './Reports.datasource.index';
import { Reports_custom_bol_shipping_lp_detailsService } from './Reports.datasource.index';
import { Reports_custom_bol_shipping_lp_details_flowService } from './Reports.datasource.index';
import { Reports_custom_bol_shipping_pallets_flowService } from './Reports.datasource.index';
import { Reports_custom_bol_tasksService } from './Reports.datasource.index';
import { Reports_custom_ds_bill_of_lading_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_bill_of_lading_reportCloneService } from './Reports.datasource.index';
import { Reports_custom_ds_bol_report_details_shService } from './Reports.datasource.index';
import { Reports_custom_ds_dock_appointment_flow_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_dock_appointment_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_account_address_by_orderIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_account_contact_by_orderId_top1Service } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_address_by_orderId_top1Service } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_address_by_orderId_typeId_top1Service } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_owner_contact_top1Service } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_ship_to_account_vs_order_addressService } from './Reports.datasource.index';
import { Reports_custom_ds_get_shipping_orders_details_by_shipping_container_idService } from './Reports.datasource.index';
import { Reports_custom_ds_get_tasks_by_warehouse_operation_codes_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_inventory_activity_report_flowService } from './Reports.datasource.index';
import { Reports_custom_ds_inventory_report_details_billing_addressService } from './Reports.datasource.index';
import { Reports_custom_ds_inventory_report_details_by_operation_type_idsService } from './Reports.datasource.index';
import { Reports_custom_ds_inventory_report_details_serial_numbersService } from './Reports.datasource.index';
import { Reports_custom_ds_inventory_report_details_task_dateService } from './Reports.datasource.index';
import { Reports_custom_ds_inventory_report_details_task_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_inventory_report_details_task_idService } from './Reports.datasource.index';
import { Reports_custom_ds_inventory_report_shipping_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_details_bill_of_lading_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_details_bill_of_lading_report_advancedService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_header_bill_of_lading_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_pick_slip_by_orderId_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_pick_slip_by_wave_id_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_ship_to_address_by_orderIdService } from './Reports.datasource.index';
import { Reports_custom_ds_ship_to_address_by_orderIdCloneService } from './Reports.datasource.index';
import { Reports_custom_ds_shipping_label_dataService } from './Reports.datasource.index';
import { Reports_custom_ds_shipping_label_flowService } from './Reports.datasource.index';
import { Reports_custom_master_bol_report_bol_details_flowService } from './Reports.datasource.index';
import { Reports_custom_master_bol_report_details_flowService } from './Reports.datasource.index';
import { Reports_custom_master_bol_report_headerService } from './Reports.datasource.index';
import { Reports_custom_vg_get_order_account_address_by_orderId_shService } from './Reports.datasource.index';
import { Reports_custom_vg_get_shipping_account_address_by_orderId_shService } from './Reports.datasource.index';
import { Reports_ds_custom_inventory_reportService } from './Reports.datasource.index';
import { Reports_ds_dock_appointment_editorService } from './Reports.datasource.index';
import { Reports_ds_get_current_date_from_utcService } from './Reports.datasource.index';
import { Reports_ds_get_material_packaging_by_materialId_and_packagingId_top1_sales_ordersService } from './Reports.datasource.index';
import { Reports_ds_get_material_packaging_by_materialId_and_packagingId_top1_wavesService } from './Reports.datasource.index';
import { Reports_ds_get_non_fixed_weight_details_by_shipmentIdService } from './Reports.datasource.index';
import { Reports_ds_get_non_fixed_weight_details_by_shipmentId_completedService } from './Reports.datasource.index';
import { Reports_ds_get_order_account_address_by_orderId_sales_ordersService } from './Reports.datasource.index';
import { Reports_ds_get_order_account_address_by_orderId_wavesService } from './Reports.datasource.index';
import { Reports_ds_get_order_address_by_orderId_top1_sales_ordersService } from './Reports.datasource.index';
import { Reports_ds_get_order_address_by_orderId_top1_wavesService } from './Reports.datasource.index';
import { Reports_ds_get_order_owner_contact_top1_sales_ordersService } from './Reports.datasource.index';
import { Reports_ds_get_order_owner_contact_top1_wavesService } from './Reports.datasource.index';
import { Reports_ds_get_order_ship_to_account_vs_order_address_sales_ordersService } from './Reports.datasource.index';
import { Reports_ds_get_order_ship_to_account_vs_order_address_wavesService } from './Reports.datasource.index';
import { Reports_ds_get_order_signature_attachmentService } from './Reports.datasource.index';
import { Reports_ds_get_shipment_by_orderId_top1Service } from './Reports.datasource.index';
import { Reports_ds_get_shipment_signature_attachmentService } from './Reports.datasource.index';
import { Reports_ds_get_warehouse_by_warehouseIdService } from './Reports.datasource.index';
import { Reports_ds_order_total_pickedService } from './Reports.datasource.index';
import { Reports_ds_sales_order_editorService } from './Reports.datasource.index';

@Injectable({ providedIn: 'root' })
export class Reports_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public Reports: Reports_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_bol_content_load: Reports_custom_bol_content_loadService;
  public get custom_bol_content_load(): Reports_custom_bol_content_loadService {
    if(!this._custom_bol_content_load) {
      this._custom_bol_content_load = this.injector.get(Reports_custom_bol_content_loadService);
    }
    return this._custom_bol_content_load;
  }
  private _custom_bol_shipping_content_flow: Reports_custom_bol_shipping_content_flowService;
  public get custom_bol_shipping_content_flow(): Reports_custom_bol_shipping_content_flowService {
    if(!this._custom_bol_shipping_content_flow) {
      this._custom_bol_shipping_content_flow = this.injector.get(Reports_custom_bol_shipping_content_flowService);
    }
    return this._custom_bol_shipping_content_flow;
  }
  private _custom_bol_shipping_lp_container_details: Reports_custom_bol_shipping_lp_container_detailsService;
  public get custom_bol_shipping_lp_container_details(): Reports_custom_bol_shipping_lp_container_detailsService {
    if(!this._custom_bol_shipping_lp_container_details) {
      this._custom_bol_shipping_lp_container_details = this.injector.get(Reports_custom_bol_shipping_lp_container_detailsService);
    }
    return this._custom_bol_shipping_lp_container_details;
  }
  private _custom_bol_shipping_lp_container_details_flow: Reports_custom_bol_shipping_lp_container_details_flowService;
  public get custom_bol_shipping_lp_container_details_flow(): Reports_custom_bol_shipping_lp_container_details_flowService {
    if(!this._custom_bol_shipping_lp_container_details_flow) {
      this._custom_bol_shipping_lp_container_details_flow = this.injector.get(Reports_custom_bol_shipping_lp_container_details_flowService);
    }
    return this._custom_bol_shipping_lp_container_details_flow;
  }
  private _custom_bol_shipping_lp_details: Reports_custom_bol_shipping_lp_detailsService;
  public get custom_bol_shipping_lp_details(): Reports_custom_bol_shipping_lp_detailsService {
    if(!this._custom_bol_shipping_lp_details) {
      this._custom_bol_shipping_lp_details = this.injector.get(Reports_custom_bol_shipping_lp_detailsService);
    }
    return this._custom_bol_shipping_lp_details;
  }
  private _custom_bol_shipping_lp_details_flow: Reports_custom_bol_shipping_lp_details_flowService;
  public get custom_bol_shipping_lp_details_flow(): Reports_custom_bol_shipping_lp_details_flowService {
    if(!this._custom_bol_shipping_lp_details_flow) {
      this._custom_bol_shipping_lp_details_flow = this.injector.get(Reports_custom_bol_shipping_lp_details_flowService);
    }
    return this._custom_bol_shipping_lp_details_flow;
  }
  private _custom_bol_shipping_pallets_flow: Reports_custom_bol_shipping_pallets_flowService;
  public get custom_bol_shipping_pallets_flow(): Reports_custom_bol_shipping_pallets_flowService {
    if(!this._custom_bol_shipping_pallets_flow) {
      this._custom_bol_shipping_pallets_flow = this.injector.get(Reports_custom_bol_shipping_pallets_flowService);
    }
    return this._custom_bol_shipping_pallets_flow;
  }
  private _custom_bol_tasks: Reports_custom_bol_tasksService;
  public get custom_bol_tasks(): Reports_custom_bol_tasksService {
    if(!this._custom_bol_tasks) {
      this._custom_bol_tasks = this.injector.get(Reports_custom_bol_tasksService);
    }
    return this._custom_bol_tasks;
  }
  private _custom_ds_bill_of_lading_report: Reports_custom_ds_bill_of_lading_reportService;
  public get custom_ds_bill_of_lading_report(): Reports_custom_ds_bill_of_lading_reportService {
    if(!this._custom_ds_bill_of_lading_report) {
      this._custom_ds_bill_of_lading_report = this.injector.get(Reports_custom_ds_bill_of_lading_reportService);
    }
    return this._custom_ds_bill_of_lading_report;
  }
  private _custom_ds_bill_of_lading_reportClone: Reports_custom_ds_bill_of_lading_reportCloneService;
  public get custom_ds_bill_of_lading_reportClone(): Reports_custom_ds_bill_of_lading_reportCloneService {
    if(!this._custom_ds_bill_of_lading_reportClone) {
      this._custom_ds_bill_of_lading_reportClone = this.injector.get(Reports_custom_ds_bill_of_lading_reportCloneService);
    }
    return this._custom_ds_bill_of_lading_reportClone;
  }
  private _custom_ds_bol_report_details_sh: Reports_custom_ds_bol_report_details_shService;
  public get custom_ds_bol_report_details_sh(): Reports_custom_ds_bol_report_details_shService {
    if(!this._custom_ds_bol_report_details_sh) {
      this._custom_ds_bol_report_details_sh = this.injector.get(Reports_custom_ds_bol_report_details_shService);
    }
    return this._custom_ds_bol_report_details_sh;
  }
  private _custom_ds_dock_appointment_flow_report: Reports_custom_ds_dock_appointment_flow_reportService;
  public get custom_ds_dock_appointment_flow_report(): Reports_custom_ds_dock_appointment_flow_reportService {
    if(!this._custom_ds_dock_appointment_flow_report) {
      this._custom_ds_dock_appointment_flow_report = this.injector.get(Reports_custom_ds_dock_appointment_flow_reportService);
    }
    return this._custom_ds_dock_appointment_flow_report;
  }
  private _custom_ds_dock_appointment_report: Reports_custom_ds_dock_appointment_reportService;
  public get custom_ds_dock_appointment_report(): Reports_custom_ds_dock_appointment_reportService {
    if(!this._custom_ds_dock_appointment_report) {
      this._custom_ds_dock_appointment_report = this.injector.get(Reports_custom_ds_dock_appointment_reportService);
    }
    return this._custom_ds_dock_appointment_report;
  }
  private _custom_ds_get_order_account_address_by_orderId: Reports_custom_ds_get_order_account_address_by_orderIdService;
  public get custom_ds_get_order_account_address_by_orderId(): Reports_custom_ds_get_order_account_address_by_orderIdService {
    if(!this._custom_ds_get_order_account_address_by_orderId) {
      this._custom_ds_get_order_account_address_by_orderId = this.injector.get(Reports_custom_ds_get_order_account_address_by_orderIdService);
    }
    return this._custom_ds_get_order_account_address_by_orderId;
  }
  private _custom_ds_get_order_account_contact_by_orderId_top1: Reports_custom_ds_get_order_account_contact_by_orderId_top1Service;
  public get custom_ds_get_order_account_contact_by_orderId_top1(): Reports_custom_ds_get_order_account_contact_by_orderId_top1Service {
    if(!this._custom_ds_get_order_account_contact_by_orderId_top1) {
      this._custom_ds_get_order_account_contact_by_orderId_top1 = this.injector.get(Reports_custom_ds_get_order_account_contact_by_orderId_top1Service);
    }
    return this._custom_ds_get_order_account_contact_by_orderId_top1;
  }
  private _custom_ds_get_order_address_by_orderId_top1: Reports_custom_ds_get_order_address_by_orderId_top1Service;
  public get custom_ds_get_order_address_by_orderId_top1(): Reports_custom_ds_get_order_address_by_orderId_top1Service {
    if(!this._custom_ds_get_order_address_by_orderId_top1) {
      this._custom_ds_get_order_address_by_orderId_top1 = this.injector.get(Reports_custom_ds_get_order_address_by_orderId_top1Service);
    }
    return this._custom_ds_get_order_address_by_orderId_top1;
  }
  private _custom_ds_get_order_address_by_orderId_typeId_top1: Reports_custom_ds_get_order_address_by_orderId_typeId_top1Service;
  public get custom_ds_get_order_address_by_orderId_typeId_top1(): Reports_custom_ds_get_order_address_by_orderId_typeId_top1Service {
    if(!this._custom_ds_get_order_address_by_orderId_typeId_top1) {
      this._custom_ds_get_order_address_by_orderId_typeId_top1 = this.injector.get(Reports_custom_ds_get_order_address_by_orderId_typeId_top1Service);
    }
    return this._custom_ds_get_order_address_by_orderId_typeId_top1;
  }
  private _custom_ds_get_order_owner_contact_top1: Reports_custom_ds_get_order_owner_contact_top1Service;
  public get custom_ds_get_order_owner_contact_top1(): Reports_custom_ds_get_order_owner_contact_top1Service {
    if(!this._custom_ds_get_order_owner_contact_top1) {
      this._custom_ds_get_order_owner_contact_top1 = this.injector.get(Reports_custom_ds_get_order_owner_contact_top1Service);
    }
    return this._custom_ds_get_order_owner_contact_top1;
  }
  private _custom_ds_get_order_ship_to_account_vs_order_address: Reports_custom_ds_get_order_ship_to_account_vs_order_addressService;
  public get custom_ds_get_order_ship_to_account_vs_order_address(): Reports_custom_ds_get_order_ship_to_account_vs_order_addressService {
    if(!this._custom_ds_get_order_ship_to_account_vs_order_address) {
      this._custom_ds_get_order_ship_to_account_vs_order_address = this.injector.get(Reports_custom_ds_get_order_ship_to_account_vs_order_addressService);
    }
    return this._custom_ds_get_order_ship_to_account_vs_order_address;
  }
  private _custom_ds_get_shipping_orders_details_by_shipping_container_id: Reports_custom_ds_get_shipping_orders_details_by_shipping_container_idService;
  public get custom_ds_get_shipping_orders_details_by_shipping_container_id(): Reports_custom_ds_get_shipping_orders_details_by_shipping_container_idService {
    if(!this._custom_ds_get_shipping_orders_details_by_shipping_container_id) {
      this._custom_ds_get_shipping_orders_details_by_shipping_container_id = this.injector.get(Reports_custom_ds_get_shipping_orders_details_by_shipping_container_idService);
    }
    return this._custom_ds_get_shipping_orders_details_by_shipping_container_id;
  }
  private _custom_ds_get_tasks_by_warehouse_operation_codes_details: Reports_custom_ds_get_tasks_by_warehouse_operation_codes_detailsService;
  public get custom_ds_get_tasks_by_warehouse_operation_codes_details(): Reports_custom_ds_get_tasks_by_warehouse_operation_codes_detailsService {
    if(!this._custom_ds_get_tasks_by_warehouse_operation_codes_details) {
      this._custom_ds_get_tasks_by_warehouse_operation_codes_details = this.injector.get(Reports_custom_ds_get_tasks_by_warehouse_operation_codes_detailsService);
    }
    return this._custom_ds_get_tasks_by_warehouse_operation_codes_details;
  }
  private _custom_ds_inventory_activity_report_flow: Reports_custom_ds_inventory_activity_report_flowService;
  public get custom_ds_inventory_activity_report_flow(): Reports_custom_ds_inventory_activity_report_flowService {
    if(!this._custom_ds_inventory_activity_report_flow) {
      this._custom_ds_inventory_activity_report_flow = this.injector.get(Reports_custom_ds_inventory_activity_report_flowService);
    }
    return this._custom_ds_inventory_activity_report_flow;
  }
  private _custom_ds_inventory_report_details_billing_address: Reports_custom_ds_inventory_report_details_billing_addressService;
  public get custom_ds_inventory_report_details_billing_address(): Reports_custom_ds_inventory_report_details_billing_addressService {
    if(!this._custom_ds_inventory_report_details_billing_address) {
      this._custom_ds_inventory_report_details_billing_address = this.injector.get(Reports_custom_ds_inventory_report_details_billing_addressService);
    }
    return this._custom_ds_inventory_report_details_billing_address;
  }
  private _custom_ds_inventory_report_details_by_operation_type_ids: Reports_custom_ds_inventory_report_details_by_operation_type_idsService;
  public get custom_ds_inventory_report_details_by_operation_type_ids(): Reports_custom_ds_inventory_report_details_by_operation_type_idsService {
    if(!this._custom_ds_inventory_report_details_by_operation_type_ids) {
      this._custom_ds_inventory_report_details_by_operation_type_ids = this.injector.get(Reports_custom_ds_inventory_report_details_by_operation_type_idsService);
    }
    return this._custom_ds_inventory_report_details_by_operation_type_ids;
  }
  private _custom_ds_inventory_report_details_serial_numbers: Reports_custom_ds_inventory_report_details_serial_numbersService;
  public get custom_ds_inventory_report_details_serial_numbers(): Reports_custom_ds_inventory_report_details_serial_numbersService {
    if(!this._custom_ds_inventory_report_details_serial_numbers) {
      this._custom_ds_inventory_report_details_serial_numbers = this.injector.get(Reports_custom_ds_inventory_report_details_serial_numbersService);
    }
    return this._custom_ds_inventory_report_details_serial_numbers;
  }
  private _custom_ds_inventory_report_details_task_date: Reports_custom_ds_inventory_report_details_task_dateService;
  public get custom_ds_inventory_report_details_task_date(): Reports_custom_ds_inventory_report_details_task_dateService {
    if(!this._custom_ds_inventory_report_details_task_date) {
      this._custom_ds_inventory_report_details_task_date = this.injector.get(Reports_custom_ds_inventory_report_details_task_dateService);
    }
    return this._custom_ds_inventory_report_details_task_date;
  }
  private _custom_ds_inventory_report_details_task_details: Reports_custom_ds_inventory_report_details_task_detailsService;
  public get custom_ds_inventory_report_details_task_details(): Reports_custom_ds_inventory_report_details_task_detailsService {
    if(!this._custom_ds_inventory_report_details_task_details) {
      this._custom_ds_inventory_report_details_task_details = this.injector.get(Reports_custom_ds_inventory_report_details_task_detailsService);
    }
    return this._custom_ds_inventory_report_details_task_details;
  }
  private _custom_ds_inventory_report_details_task_id: Reports_custom_ds_inventory_report_details_task_idService;
  public get custom_ds_inventory_report_details_task_id(): Reports_custom_ds_inventory_report_details_task_idService {
    if(!this._custom_ds_inventory_report_details_task_id) {
      this._custom_ds_inventory_report_details_task_id = this.injector.get(Reports_custom_ds_inventory_report_details_task_idService);
    }
    return this._custom_ds_inventory_report_details_task_id;
  }
  private _custom_ds_inventory_report_shipping_details: Reports_custom_ds_inventory_report_shipping_detailsService;
  public get custom_ds_inventory_report_shipping_details(): Reports_custom_ds_inventory_report_shipping_detailsService {
    if(!this._custom_ds_inventory_report_shipping_details) {
      this._custom_ds_inventory_report_shipping_details = this.injector.get(Reports_custom_ds_inventory_report_shipping_detailsService);
    }
    return this._custom_ds_inventory_report_shipping_details;
  }
  private _custom_ds_outbound_details_bill_of_lading_report: Reports_custom_ds_outbound_details_bill_of_lading_reportService;
  public get custom_ds_outbound_details_bill_of_lading_report(): Reports_custom_ds_outbound_details_bill_of_lading_reportService {
    if(!this._custom_ds_outbound_details_bill_of_lading_report) {
      this._custom_ds_outbound_details_bill_of_lading_report = this.injector.get(Reports_custom_ds_outbound_details_bill_of_lading_reportService);
    }
    return this._custom_ds_outbound_details_bill_of_lading_report;
  }
  private _custom_ds_outbound_details_bill_of_lading_report_advanced: Reports_custom_ds_outbound_details_bill_of_lading_report_advancedService;
  public get custom_ds_outbound_details_bill_of_lading_report_advanced(): Reports_custom_ds_outbound_details_bill_of_lading_report_advancedService {
    if(!this._custom_ds_outbound_details_bill_of_lading_report_advanced) {
      this._custom_ds_outbound_details_bill_of_lading_report_advanced = this.injector.get(Reports_custom_ds_outbound_details_bill_of_lading_report_advancedService);
    }
    return this._custom_ds_outbound_details_bill_of_lading_report_advanced;
  }
  private _custom_ds_outbound_header_bill_of_lading_report: Reports_custom_ds_outbound_header_bill_of_lading_reportService;
  public get custom_ds_outbound_header_bill_of_lading_report(): Reports_custom_ds_outbound_header_bill_of_lading_reportService {
    if(!this._custom_ds_outbound_header_bill_of_lading_report) {
      this._custom_ds_outbound_header_bill_of_lading_report = this.injector.get(Reports_custom_ds_outbound_header_bill_of_lading_reportService);
    }
    return this._custom_ds_outbound_header_bill_of_lading_report;
  }
  private _custom_ds_outbound_pick_slip_by_orderId_report: Reports_custom_ds_outbound_pick_slip_by_orderId_reportService;
  public get custom_ds_outbound_pick_slip_by_orderId_report(): Reports_custom_ds_outbound_pick_slip_by_orderId_reportService {
    if(!this._custom_ds_outbound_pick_slip_by_orderId_report) {
      this._custom_ds_outbound_pick_slip_by_orderId_report = this.injector.get(Reports_custom_ds_outbound_pick_slip_by_orderId_reportService);
    }
    return this._custom_ds_outbound_pick_slip_by_orderId_report;
  }
  private _custom_ds_outbound_pick_slip_by_wave_id_report: Reports_custom_ds_outbound_pick_slip_by_wave_id_reportService;
  public get custom_ds_outbound_pick_slip_by_wave_id_report(): Reports_custom_ds_outbound_pick_slip_by_wave_id_reportService {
    if(!this._custom_ds_outbound_pick_slip_by_wave_id_report) {
      this._custom_ds_outbound_pick_slip_by_wave_id_report = this.injector.get(Reports_custom_ds_outbound_pick_slip_by_wave_id_reportService);
    }
    return this._custom_ds_outbound_pick_slip_by_wave_id_report;
  }
  private _custom_ds_ship_to_address_by_orderId: Reports_custom_ds_ship_to_address_by_orderIdService;
  public get custom_ds_ship_to_address_by_orderId(): Reports_custom_ds_ship_to_address_by_orderIdService {
    if(!this._custom_ds_ship_to_address_by_orderId) {
      this._custom_ds_ship_to_address_by_orderId = this.injector.get(Reports_custom_ds_ship_to_address_by_orderIdService);
    }
    return this._custom_ds_ship_to_address_by_orderId;
  }
  private _custom_ds_ship_to_address_by_orderIdClone: Reports_custom_ds_ship_to_address_by_orderIdCloneService;
  public get custom_ds_ship_to_address_by_orderIdClone(): Reports_custom_ds_ship_to_address_by_orderIdCloneService {
    if(!this._custom_ds_ship_to_address_by_orderIdClone) {
      this._custom_ds_ship_to_address_by_orderIdClone = this.injector.get(Reports_custom_ds_ship_to_address_by_orderIdCloneService);
    }
    return this._custom_ds_ship_to_address_by_orderIdClone;
  }
  private _custom_ds_shipping_label_data: Reports_custom_ds_shipping_label_dataService;
  public get custom_ds_shipping_label_data(): Reports_custom_ds_shipping_label_dataService {
    if(!this._custom_ds_shipping_label_data) {
      this._custom_ds_shipping_label_data = this.injector.get(Reports_custom_ds_shipping_label_dataService);
    }
    return this._custom_ds_shipping_label_data;
  }
  private _custom_ds_shipping_label_flow: Reports_custom_ds_shipping_label_flowService;
  public get custom_ds_shipping_label_flow(): Reports_custom_ds_shipping_label_flowService {
    if(!this._custom_ds_shipping_label_flow) {
      this._custom_ds_shipping_label_flow = this.injector.get(Reports_custom_ds_shipping_label_flowService);
    }
    return this._custom_ds_shipping_label_flow;
  }
  private _custom_master_bol_report_bol_details_flow: Reports_custom_master_bol_report_bol_details_flowService;
  public get custom_master_bol_report_bol_details_flow(): Reports_custom_master_bol_report_bol_details_flowService {
    if(!this._custom_master_bol_report_bol_details_flow) {
      this._custom_master_bol_report_bol_details_flow = this.injector.get(Reports_custom_master_bol_report_bol_details_flowService);
    }
    return this._custom_master_bol_report_bol_details_flow;
  }
  private _custom_master_bol_report_details_flow: Reports_custom_master_bol_report_details_flowService;
  public get custom_master_bol_report_details_flow(): Reports_custom_master_bol_report_details_flowService {
    if(!this._custom_master_bol_report_details_flow) {
      this._custom_master_bol_report_details_flow = this.injector.get(Reports_custom_master_bol_report_details_flowService);
    }
    return this._custom_master_bol_report_details_flow;
  }
  private _custom_master_bol_report_header: Reports_custom_master_bol_report_headerService;
  public get custom_master_bol_report_header(): Reports_custom_master_bol_report_headerService {
    if(!this._custom_master_bol_report_header) {
      this._custom_master_bol_report_header = this.injector.get(Reports_custom_master_bol_report_headerService);
    }
    return this._custom_master_bol_report_header;
  }
  private _custom_vg_get_order_account_address_by_orderId_sh: Reports_custom_vg_get_order_account_address_by_orderId_shService;
  public get custom_vg_get_order_account_address_by_orderId_sh(): Reports_custom_vg_get_order_account_address_by_orderId_shService {
    if(!this._custom_vg_get_order_account_address_by_orderId_sh) {
      this._custom_vg_get_order_account_address_by_orderId_sh = this.injector.get(Reports_custom_vg_get_order_account_address_by_orderId_shService);
    }
    return this._custom_vg_get_order_account_address_by_orderId_sh;
  }
  private _custom_vg_get_shipping_account_address_by_orderId_sh: Reports_custom_vg_get_shipping_account_address_by_orderId_shService;
  public get custom_vg_get_shipping_account_address_by_orderId_sh(): Reports_custom_vg_get_shipping_account_address_by_orderId_shService {
    if(!this._custom_vg_get_shipping_account_address_by_orderId_sh) {
      this._custom_vg_get_shipping_account_address_by_orderId_sh = this.injector.get(Reports_custom_vg_get_shipping_account_address_by_orderId_shService);
    }
    return this._custom_vg_get_shipping_account_address_by_orderId_sh;
  }
  private _ds_custom_inventory_report: Reports_ds_custom_inventory_reportService;
  public get ds_custom_inventory_report(): Reports_ds_custom_inventory_reportService {
    if(!this._ds_custom_inventory_report) {
      this._ds_custom_inventory_report = this.injector.get(Reports_ds_custom_inventory_reportService);
    }
    return this._ds_custom_inventory_report;
  }
  private _ds_dock_appointment_editor: Reports_ds_dock_appointment_editorService;
  public get ds_dock_appointment_editor(): Reports_ds_dock_appointment_editorService {
    if(!this._ds_dock_appointment_editor) {
      this._ds_dock_appointment_editor = this.injector.get(Reports_ds_dock_appointment_editorService);
    }
    return this._ds_dock_appointment_editor;
  }
  private _ds_get_current_date_from_utc: Reports_ds_get_current_date_from_utcService;
  public get ds_get_current_date_from_utc(): Reports_ds_get_current_date_from_utcService {
    if(!this._ds_get_current_date_from_utc) {
      this._ds_get_current_date_from_utc = this.injector.get(Reports_ds_get_current_date_from_utcService);
    }
    return this._ds_get_current_date_from_utc;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId_top1_sales_orders: Reports_ds_get_material_packaging_by_materialId_and_packagingId_top1_sales_ordersService;
  public get ds_get_material_packaging_by_materialId_and_packagingId_top1_sales_orders(): Reports_ds_get_material_packaging_by_materialId_and_packagingId_top1_sales_ordersService {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId_top1_sales_orders) {
      this._ds_get_material_packaging_by_materialId_and_packagingId_top1_sales_orders = this.injector.get(Reports_ds_get_material_packaging_by_materialId_and_packagingId_top1_sales_ordersService);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId_top1_sales_orders;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId_top1_waves: Reports_ds_get_material_packaging_by_materialId_and_packagingId_top1_wavesService;
  public get ds_get_material_packaging_by_materialId_and_packagingId_top1_waves(): Reports_ds_get_material_packaging_by_materialId_and_packagingId_top1_wavesService {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId_top1_waves) {
      this._ds_get_material_packaging_by_materialId_and_packagingId_top1_waves = this.injector.get(Reports_ds_get_material_packaging_by_materialId_and_packagingId_top1_wavesService);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId_top1_waves;
  }
  private _ds_get_non_fixed_weight_details_by_shipmentId: Reports_ds_get_non_fixed_weight_details_by_shipmentIdService;
  public get ds_get_non_fixed_weight_details_by_shipmentId(): Reports_ds_get_non_fixed_weight_details_by_shipmentIdService {
    if(!this._ds_get_non_fixed_weight_details_by_shipmentId) {
      this._ds_get_non_fixed_weight_details_by_shipmentId = this.injector.get(Reports_ds_get_non_fixed_weight_details_by_shipmentIdService);
    }
    return this._ds_get_non_fixed_weight_details_by_shipmentId;
  }
  private _ds_get_non_fixed_weight_details_by_shipmentId_completed: Reports_ds_get_non_fixed_weight_details_by_shipmentId_completedService;
  public get ds_get_non_fixed_weight_details_by_shipmentId_completed(): Reports_ds_get_non_fixed_weight_details_by_shipmentId_completedService {
    if(!this._ds_get_non_fixed_weight_details_by_shipmentId_completed) {
      this._ds_get_non_fixed_weight_details_by_shipmentId_completed = this.injector.get(Reports_ds_get_non_fixed_weight_details_by_shipmentId_completedService);
    }
    return this._ds_get_non_fixed_weight_details_by_shipmentId_completed;
  }
  private _ds_get_order_account_address_by_orderId_sales_orders: Reports_ds_get_order_account_address_by_orderId_sales_ordersService;
  public get ds_get_order_account_address_by_orderId_sales_orders(): Reports_ds_get_order_account_address_by_orderId_sales_ordersService {
    if(!this._ds_get_order_account_address_by_orderId_sales_orders) {
      this._ds_get_order_account_address_by_orderId_sales_orders = this.injector.get(Reports_ds_get_order_account_address_by_orderId_sales_ordersService);
    }
    return this._ds_get_order_account_address_by_orderId_sales_orders;
  }
  private _ds_get_order_account_address_by_orderId_waves: Reports_ds_get_order_account_address_by_orderId_wavesService;
  public get ds_get_order_account_address_by_orderId_waves(): Reports_ds_get_order_account_address_by_orderId_wavesService {
    if(!this._ds_get_order_account_address_by_orderId_waves) {
      this._ds_get_order_account_address_by_orderId_waves = this.injector.get(Reports_ds_get_order_account_address_by_orderId_wavesService);
    }
    return this._ds_get_order_account_address_by_orderId_waves;
  }
  private _ds_get_order_address_by_orderId_top1_sales_orders: Reports_ds_get_order_address_by_orderId_top1_sales_ordersService;
  public get ds_get_order_address_by_orderId_top1_sales_orders(): Reports_ds_get_order_address_by_orderId_top1_sales_ordersService {
    if(!this._ds_get_order_address_by_orderId_top1_sales_orders) {
      this._ds_get_order_address_by_orderId_top1_sales_orders = this.injector.get(Reports_ds_get_order_address_by_orderId_top1_sales_ordersService);
    }
    return this._ds_get_order_address_by_orderId_top1_sales_orders;
  }
  private _ds_get_order_address_by_orderId_top1_waves: Reports_ds_get_order_address_by_orderId_top1_wavesService;
  public get ds_get_order_address_by_orderId_top1_waves(): Reports_ds_get_order_address_by_orderId_top1_wavesService {
    if(!this._ds_get_order_address_by_orderId_top1_waves) {
      this._ds_get_order_address_by_orderId_top1_waves = this.injector.get(Reports_ds_get_order_address_by_orderId_top1_wavesService);
    }
    return this._ds_get_order_address_by_orderId_top1_waves;
  }
  private _ds_get_order_owner_contact_top1_sales_orders: Reports_ds_get_order_owner_contact_top1_sales_ordersService;
  public get ds_get_order_owner_contact_top1_sales_orders(): Reports_ds_get_order_owner_contact_top1_sales_ordersService {
    if(!this._ds_get_order_owner_contact_top1_sales_orders) {
      this._ds_get_order_owner_contact_top1_sales_orders = this.injector.get(Reports_ds_get_order_owner_contact_top1_sales_ordersService);
    }
    return this._ds_get_order_owner_contact_top1_sales_orders;
  }
  private _ds_get_order_owner_contact_top1_waves: Reports_ds_get_order_owner_contact_top1_wavesService;
  public get ds_get_order_owner_contact_top1_waves(): Reports_ds_get_order_owner_contact_top1_wavesService {
    if(!this._ds_get_order_owner_contact_top1_waves) {
      this._ds_get_order_owner_contact_top1_waves = this.injector.get(Reports_ds_get_order_owner_contact_top1_wavesService);
    }
    return this._ds_get_order_owner_contact_top1_waves;
  }
  private _ds_get_order_ship_to_account_vs_order_address_sales_orders: Reports_ds_get_order_ship_to_account_vs_order_address_sales_ordersService;
  public get ds_get_order_ship_to_account_vs_order_address_sales_orders(): Reports_ds_get_order_ship_to_account_vs_order_address_sales_ordersService {
    if(!this._ds_get_order_ship_to_account_vs_order_address_sales_orders) {
      this._ds_get_order_ship_to_account_vs_order_address_sales_orders = this.injector.get(Reports_ds_get_order_ship_to_account_vs_order_address_sales_ordersService);
    }
    return this._ds_get_order_ship_to_account_vs_order_address_sales_orders;
  }
  private _ds_get_order_ship_to_account_vs_order_address_waves: Reports_ds_get_order_ship_to_account_vs_order_address_wavesService;
  public get ds_get_order_ship_to_account_vs_order_address_waves(): Reports_ds_get_order_ship_to_account_vs_order_address_wavesService {
    if(!this._ds_get_order_ship_to_account_vs_order_address_waves) {
      this._ds_get_order_ship_to_account_vs_order_address_waves = this.injector.get(Reports_ds_get_order_ship_to_account_vs_order_address_wavesService);
    }
    return this._ds_get_order_ship_to_account_vs_order_address_waves;
  }
  private _ds_get_order_signature_attachment: Reports_ds_get_order_signature_attachmentService;
  public get ds_get_order_signature_attachment(): Reports_ds_get_order_signature_attachmentService {
    if(!this._ds_get_order_signature_attachment) {
      this._ds_get_order_signature_attachment = this.injector.get(Reports_ds_get_order_signature_attachmentService);
    }
    return this._ds_get_order_signature_attachment;
  }
  private _ds_get_shipment_by_orderId_top1: Reports_ds_get_shipment_by_orderId_top1Service;
  public get ds_get_shipment_by_orderId_top1(): Reports_ds_get_shipment_by_orderId_top1Service {
    if(!this._ds_get_shipment_by_orderId_top1) {
      this._ds_get_shipment_by_orderId_top1 = this.injector.get(Reports_ds_get_shipment_by_orderId_top1Service);
    }
    return this._ds_get_shipment_by_orderId_top1;
  }
  private _ds_get_shipment_signature_attachment: Reports_ds_get_shipment_signature_attachmentService;
  public get ds_get_shipment_signature_attachment(): Reports_ds_get_shipment_signature_attachmentService {
    if(!this._ds_get_shipment_signature_attachment) {
      this._ds_get_shipment_signature_attachment = this.injector.get(Reports_ds_get_shipment_signature_attachmentService);
    }
    return this._ds_get_shipment_signature_attachment;
  }
  private _ds_get_warehouse_by_warehouseId: Reports_ds_get_warehouse_by_warehouseIdService;
  public get ds_get_warehouse_by_warehouseId(): Reports_ds_get_warehouse_by_warehouseIdService {
    if(!this._ds_get_warehouse_by_warehouseId) {
      this._ds_get_warehouse_by_warehouseId = this.injector.get(Reports_ds_get_warehouse_by_warehouseIdService);
    }
    return this._ds_get_warehouse_by_warehouseId;
  }
  private _ds_order_total_picked: Reports_ds_order_total_pickedService;
  public get ds_order_total_picked(): Reports_ds_order_total_pickedService {
    if(!this._ds_order_total_picked) {
      this._ds_order_total_picked = this.injector.get(Reports_ds_order_total_pickedService);
    }
    return this._ds_order_total_picked;
  }
  private _ds_sales_order_editor: Reports_ds_sales_order_editorService;
  public get ds_sales_order_editor(): Reports_ds_sales_order_editorService {
    if(!this._ds_sales_order_editor) {
      this._ds_sales_order_editor = this.injector.get(Reports_ds_sales_order_editorService);
    }
    return this._ds_sales_order_editor;
  }
}

