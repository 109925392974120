<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-alert_insert"
                     *ngIf="!fieldsets.alert_insert.hidden && !fieldsets.alert_insert.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.alert_insert.collapsible }">
                    <div *ngIf="!fieldsets.alert_insert.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.alert_insert.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.alert_insert.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.alert_insert.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.alert_insert.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.alert_insert.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.alert_insert.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-enabled" *ngIf="!fields.enabled.hidden && !fields.enabled.removed" 
                            class="field-container full {{fields.enabled.invalid ? 'invalid' : ''}} {{fields.enabled.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.enabled.styles.style"
                            [ngClass]="fields.enabled.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.enabled.label + (fields.enabled.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.enabled.label}}<span *ngIf="fields.enabled.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="enabled"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.enabled.control.styles.style"
                                      [ngClass]="fields.enabled.control.styles.classes"
                                      [matTooltip]="fields.enabled.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.enabled.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.enabled.invalid">
                          <ng-container *ngFor="let error of fields.enabled.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-pdf" *ngIf="!fields.pdf.hidden && !fields.pdf.removed" 
                            class="field-container full {{fields.pdf.invalid ? 'invalid' : ''}} {{fields.pdf.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.pdf.styles.style"
                            [ngClass]="fields.pdf.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.pdf.label + (fields.pdf.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.pdf.label}}<span *ngIf="fields.pdf.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="pdf"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.pdf.control.styles.style"
                                      [ngClass]="fields.pdf.control.styles.classes"
                                      [matTooltip]="fields.pdf.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.pdf.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.pdf.invalid">
                          <ng-container *ngFor="let error of fields.pdf.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-integration_name" *ngIf="!fields.integration_name.hidden && !fields.integration_name.removed" 
                            class="field-container full {{fields.integration_name.invalid ? 'invalid' : ''}} {{fields.integration_name.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.integration_name.styles.style"
                            [ngClass]="fields.integration_name.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.integration_name.label + (fields.integration_name.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.integration_name.label}}<span *ngIf="fields.integration_name.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <FootprintApiManager-integrations_dd_single 
                            data-cy="selector"
                            [type]="fields.integration_name.control.type"
                            formControlName="integration_name"
                            (displayTextChange)="fields.integration_name.control.displayText=$event"
                            [placeholder]="fields.integration_name.control.placeholder"
                            [styles]="fields.integration_name.control.styles"
                            [tooltip]="fields.integration_name.control.tooltip"
                        >
                        </FootprintApiManager-integrations_dd_single>
                        <ng-container *ngIf="fields.integration_name.invalid">
                          <ng-container *ngFor="let error of fields.integration_name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-alert_name" *ngIf="!fields.alert_name.hidden && !fields.alert_name.removed" 
                            class="field-container full {{fields.alert_name.invalid ? 'invalid' : ''}} {{fields.alert_name.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.alert_name.styles.style"
                            [ngClass]="fields.alert_name.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.alert_name.label + (fields.alert_name.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.alert_name.label}}<span *ngIf="fields.alert_name.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="alert_name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.alert_name.control.readOnly || fields.alert_name.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.alert_name.control.placeholder}}"
                                [ngStyle]="fields.alert_name.control.styles.style"
                                [ngClass]="fields.alert_name.control.styles.classes"
                                [matTooltip]="fields.alert_name.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.alert_name.invalid">
                          <ng-container *ngFor="let error of fields.alert_name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-log_level" *ngIf="!fields.log_level.hidden && !fields.log_level.removed" 
                            class="field-container full {{fields.log_level.invalid ? 'invalid' : ''}} {{fields.log_level.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.log_level.styles.style"
                            [ngClass]="fields.log_level.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.log_level.label + (fields.log_level.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.log_level.label}}<span *ngIf="fields.log_level.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="log_level"
                                matInput
                                numberBox
                                [format]="fields.log_level.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.log_level.control.readOnly || fields.log_level.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.log_level.control.placeholder}}"
                                [ngStyle]="fields.log_level.control.styles.style"
                                [ngClass]="fields.log_level.control.styles.classes"
                                [matTooltip]="fields.log_level.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.log_level.invalid">
                          <ng-container *ngFor="let error of fields.log_level.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-subject" *ngIf="!fields.subject.hidden && !fields.subject.removed" 
                            class="field-container full {{fields.subject.invalid ? 'invalid' : ''}} {{fields.subject.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.subject.styles.style"
                            [ngClass]="fields.subject.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.subject.label + (fields.subject.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.subject.label}}<span *ngIf="fields.subject.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="subject"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.subject.control.readOnly || fields.subject.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.subject.control.placeholder}}"
                                [ngStyle]="fields.subject.control.styles.style"
                                [ngClass]="fields.subject.control.styles.classes"
                                [matTooltip]="fields.subject.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.subject.invalid">
                          <ng-container *ngFor="let error of fields.subject.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-to" *ngIf="!fields.to.hidden && !fields.to.removed" 
                            class="field-container full {{fields.to.invalid ? 'invalid' : ''}} {{fields.to.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.to.styles.style"
                            [ngClass]="fields.to.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.to.label + (fields.to.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.to.label}}<span *ngIf="fields.to.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="to"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.to.control.readOnly || fields.to.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.to.control.placeholder}}"
                                [ngStyle]="fields.to.control.styles.style"
                                [ngClass]="fields.to.control.styles.classes"
                                [matTooltip]="fields.to.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.to.invalid">
                          <ng-container *ngFor="let error of fields.to.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-cc" *ngIf="!fields.cc.hidden && !fields.cc.removed" 
                            class="field-container full {{fields.cc.invalid ? 'invalid' : ''}} {{fields.cc.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.cc.styles.style"
                            [ngClass]="fields.cc.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.cc.label + (fields.cc.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.cc.label}}<span *ngIf="fields.cc.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="cc"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.cc.control.readOnly || fields.cc.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.cc.control.placeholder}}"
                                [ngStyle]="fields.cc.control.styles.style"
                                [ngClass]="fields.cc.control.styles.classes"
                                [matTooltip]="fields.cc.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.cc.invalid">
                          <ng-container *ngFor="let error of fields.cc.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-bcc" *ngIf="!fields.bcc.hidden && !fields.bcc.removed" 
                            class="field-container full {{fields.bcc.invalid ? 'invalid' : ''}} {{fields.bcc.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.bcc.styles.style"
                            [ngClass]="fields.bcc.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.bcc.label + (fields.bcc.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.bcc.label}}<span *ngIf="fields.bcc.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="bcc"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.bcc.control.readOnly || fields.bcc.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.bcc.control.placeholder}}"
                                [ngStyle]="fields.bcc.control.styles.style"
                                [ngClass]="fields.bcc.control.styles.classes"
                                [matTooltip]="fields.bcc.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.bcc.invalid">
                          <ng-container *ngFor="let error of fields.bcc.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.alert_insert.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>