import { Inject, Injectable, Injector }from '@angular/core';

import { Document360_DatasourceService } from './Document360.datasource.index';
import { Usersnap_DatasourceService } from './Usersnap.datasource.index';
import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Addresses_DatasourceService } from './Addresses.datasource.index';
import { BarTender_DatasourceService } from './BarTender.datasource.index';
import { Discussions_DatasourceService } from './Discussions.datasource.index';
import { DockAppointments_DatasourceService } from './DockAppointments.datasource.index';
import { EndeavorLabs_DatasourceService } from './EndeavorLabs.datasource.index';
import { EntityImport_DatasourceService } from './EntityImport.datasource.index';
import { ExcelInventoryImport_DatasourceService } from './ExcelInventoryImport.datasource.index';
import { ExcelLocationImport_DatasourceService } from './ExcelLocationImport.datasource.index';
import { ExcelMaterialImport_DatasourceService } from './ExcelMaterialImport.datasource.index';
import { LoadContainers_DatasourceService } from './LoadContainers.datasource.index';
import { Owners_DatasourceService } from './Owners.datasource.index';
import { Surveys_DatasourceService } from './Surveys.datasource.index';
import { Waves_DatasourceService } from './Waves.datasource.index';
import { Instructions_DatasourceService } from './Instructions.datasource.index';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Materials_DatasourceService } from './Materials.datasource.index';
import { Carriers_DatasourceService } from './Carriers.datasource.index';
import { Locations_DatasourceService } from './Locations.datasource.index';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';
import { InventoryTransformations_DatasourceService } from './InventoryTransformations.datasource.index';
import { SerialNumbers_DatasourceService } from './SerialNumbers.datasource.index';
import { Manifesting_DatasourceService } from './Manifesting.datasource.index';
import { Lots_DatasourceService } from './Lots.datasource.index';
import { Inventory_DatasourceService } from './Inventory.datasource.index';
import { AsnOrders_DatasourceService } from './AsnOrders.datasource.index';
import { ExcelOrderImport_DatasourceService } from './ExcelOrderImport.datasource.index';
import { Attachments_DatasourceService } from './Attachments.datasource.index';
import { FootprintApiManager_DatasourceService } from './FootprintApiManager.datasource.index';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { Orderful_DatasourceService } from './Orderful.datasource.index';
import { Reports_DatasourceService } from './Reports.datasource.index';

import { app_custom_bol_content_detailsService } from './app.datasource.index';
import { app_custom_ds_activity_gridService } from './app.datasource.index';
import { app_custom_ds_bill_of_lading_reportService } from './app.datasource.index';
import { app_custom_ds_bol_report_details_shService } from './app.datasource.index';
import { app_custom_ds_dock_appointments_dashboard_baseService } from './app.datasource.index';
import { app_custom_ds_dock_appointments_dashboard_gridService } from './app.datasource.index';
import { app_custom_ds_dock_appointments_dashboard_grid_dataService } from './app.datasource.index';
import { app_custom_ds_dock_appointments_dashboard_grid_load_container_dataService } from './app.datasource.index';
import { app_custom_ds_dock_appointments_dashboard_grid_order_dataService } from './app.datasource.index';
import { app_custom_ds_dock_appointments_dashboard_last_task_userService } from './app.datasource.index';
import { app_custom_ds_dock_appointments_dashboard_task_amountsService } from './app.datasource.index';
import { app_custom_ds_dock_appointments_dashboard_tasks_pallet_detailsService } from './app.datasource.index';
import { app_custom_ds_get_licenseplate_count_lot_idService } from './app.datasource.index';
import { app_custom_ds_get_licenseplate_count_material_idService } from './app.datasource.index';
import { app_custom_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentIdService } from './app.datasource.index';
import { app_custom_ds_get_serialnumbers_non_fixed_weight_by_shipmentIdService } from './app.datasource.index';
import { app_custom_ds_inventory_licenseplate_gridService } from './app.datasource.index';
import { app_custom_ds_inventory_lot_gridService } from './app.datasource.index';
import { app_custom_ds_inventory_material_gridService } from './app.datasource.index';
import { app_custom_ds_inventory_reportService } from './app.datasource.index';
import { app_custom_ds_inventory_report_by_projectService } from './app.datasource.index';
import { app_custom_ds_inventory_report_get_converted_weightService } from './app.datasource.index';
import { app_custom_ds_load_diagram_reportService } from './app.datasource.index';
import { app_custom_ds_load_diagram_report_shipments_completed_cancelledService } from './app.datasource.index';
import { app_custom_ds_load_diagram_report_shipments_executingService } from './app.datasource.index';
import { app_custom_ds_load_diagram_report_task_picking_status_completedService } from './app.datasource.index';
import { app_custom_ds_load_diagram_report_task_shipment_loadingService } from './app.datasource.index';
import { app_custom_ds_order_total_picked_net_widgetService } from './app.datasource.index';
import { app_custom_ds_prediction_flow_load_containerService } from './app.datasource.index';
import { app_custom_ds_prediction_load_container_filterService } from './app.datasource.index';
import { app_custom_ds_sales_order_editorService } from './app.datasource.index';
import { app_custom_ds_sales_order_lines_gridService } from './app.datasource.index';
import { app_custom_ds_total_picked_by_orderids_shipmentidsService } from './app.datasource.index';
import { app_custom_ds_total_received_by_orderids_shipmentidsService } from './app.datasource.index';
import { app_custom_get_shipping_container_by_shipment_idService } from './app.datasource.index';
import { app_customized_ds_sales_order_lines_gridService } from './app.datasource.index';
import { app_customized_sales_order_editor___ds_get_order_infoService } from './app.datasource.index';
import { app_ds_get_license_plates_by_shipmentIdService } from './app.datasource.index';
import { app_ds_get_material_packaging_info_by_name_and_project_and_csService } from './app.datasource.index';
import { app_ds_get_materials_by_name_and_projectService } from './app.datasource.index';
import { app_ds_get_shipment_by_idService } from './app.datasource.index';
import { app_test_ds_prediction_max_ship_timeService } from './app.datasource.index';
import { app_vg_get_order_account_address_by_orderId_shService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Document360 = this.injector.get(Document360_DatasourceService);
    this.Usersnap = this.injector.get(Usersnap_DatasourceService);
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Addresses = this.injector.get(Addresses_DatasourceService);
    this.BarTender = this.injector.get(BarTender_DatasourceService);
    this.Discussions = this.injector.get(Discussions_DatasourceService);
    this.DockAppointments = this.injector.get(DockAppointments_DatasourceService);
    this.EndeavorLabs = this.injector.get(EndeavorLabs_DatasourceService);
    this.EntityImport = this.injector.get(EntityImport_DatasourceService);
    this.ExcelInventoryImport = this.injector.get(ExcelInventoryImport_DatasourceService);
    this.ExcelLocationImport = this.injector.get(ExcelLocationImport_DatasourceService);
    this.ExcelMaterialImport = this.injector.get(ExcelMaterialImport_DatasourceService);
    this.LoadContainers = this.injector.get(LoadContainers_DatasourceService);
    this.Owners = this.injector.get(Owners_DatasourceService);
    this.Surveys = this.injector.get(Surveys_DatasourceService);
    this.Waves = this.injector.get(Waves_DatasourceService);
    this.Instructions = this.injector.get(Instructions_DatasourceService);
    this.Notifications = this.injector.get(Notifications_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
    this.Materials = this.injector.get(Materials_DatasourceService);
    this.Carriers = this.injector.get(Carriers_DatasourceService);
    this.Locations = this.injector.get(Locations_DatasourceService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_DatasourceService);
    this.SalesOrders = this.injector.get(SalesOrders_DatasourceService);
    this.InventoryTransformations = this.injector.get(InventoryTransformations_DatasourceService);
    this.SerialNumbers = this.injector.get(SerialNumbers_DatasourceService);
    this.Manifesting = this.injector.get(Manifesting_DatasourceService);
    this.Lots = this.injector.get(Lots_DatasourceService);
    this.Inventory = this.injector.get(Inventory_DatasourceService);
    this.AsnOrders = this.injector.get(AsnOrders_DatasourceService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_DatasourceService);
    this.Attachments = this.injector.get(Attachments_DatasourceService);
    this.FootprintApiManager = this.injector.get(FootprintApiManager_DatasourceService);
    this.FootprintManager = this.injector.get(FootprintManager_DatasourceService);
    this.Orderful = this.injector.get(Orderful_DatasourceService);
    this.Reports = this.injector.get(Reports_DatasourceService);
  }

    public Document360: Document360_DatasourceService;
    public Usersnap: Usersnap_DatasourceService;
    public Utilities: Utilities_DatasourceService;
    public Addresses: Addresses_DatasourceService;
    public BarTender: BarTender_DatasourceService;
    public Discussions: Discussions_DatasourceService;
    public DockAppointments: DockAppointments_DatasourceService;
    public EndeavorLabs: EndeavorLabs_DatasourceService;
    public EntityImport: EntityImport_DatasourceService;
    public ExcelInventoryImport: ExcelInventoryImport_DatasourceService;
    public ExcelLocationImport: ExcelLocationImport_DatasourceService;
    public ExcelMaterialImport: ExcelMaterialImport_DatasourceService;
    public LoadContainers: LoadContainers_DatasourceService;
    public Owners: Owners_DatasourceService;
    public Surveys: Surveys_DatasourceService;
    public Waves: Waves_DatasourceService;
    public Instructions: Instructions_DatasourceService;
    public Notifications: Notifications_DatasourceService;
    public Invoices: Invoices_DatasourceService;
    public Materials: Materials_DatasourceService;
    public Carriers: Carriers_DatasourceService;
    public Locations: Locations_DatasourceService;
    public PurchaseOrders: PurchaseOrders_DatasourceService;
    public SalesOrders: SalesOrders_DatasourceService;
    public InventoryTransformations: InventoryTransformations_DatasourceService;
    public SerialNumbers: SerialNumbers_DatasourceService;
    public Manifesting: Manifesting_DatasourceService;
    public Lots: Lots_DatasourceService;
    public Inventory: Inventory_DatasourceService;
    public AsnOrders: AsnOrders_DatasourceService;
    public ExcelOrderImport: ExcelOrderImport_DatasourceService;
    public Attachments: Attachments_DatasourceService;
    public FootprintApiManager: FootprintApiManager_DatasourceService;
    public FootprintManager: FootprintManager_DatasourceService;
    public Orderful: Orderful_DatasourceService;
    public Reports: Reports_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_bol_content_details: app_custom_bol_content_detailsService;
  public get custom_bol_content_details(): app_custom_bol_content_detailsService {
    if(!this._custom_bol_content_details) {
      this._custom_bol_content_details = this.injector.get(app_custom_bol_content_detailsService);
    }
    return this._custom_bol_content_details;
  }
  private _custom_ds_activity_grid: app_custom_ds_activity_gridService;
  public get custom_ds_activity_grid(): app_custom_ds_activity_gridService {
    if(!this._custom_ds_activity_grid) {
      this._custom_ds_activity_grid = this.injector.get(app_custom_ds_activity_gridService);
    }
    return this._custom_ds_activity_grid;
  }
  private _custom_ds_bill_of_lading_report: app_custom_ds_bill_of_lading_reportService;
  public get custom_ds_bill_of_lading_report(): app_custom_ds_bill_of_lading_reportService {
    if(!this._custom_ds_bill_of_lading_report) {
      this._custom_ds_bill_of_lading_report = this.injector.get(app_custom_ds_bill_of_lading_reportService);
    }
    return this._custom_ds_bill_of_lading_report;
  }
  private _custom_ds_bol_report_details_sh: app_custom_ds_bol_report_details_shService;
  public get custom_ds_bol_report_details_sh(): app_custom_ds_bol_report_details_shService {
    if(!this._custom_ds_bol_report_details_sh) {
      this._custom_ds_bol_report_details_sh = this.injector.get(app_custom_ds_bol_report_details_shService);
    }
    return this._custom_ds_bol_report_details_sh;
  }
  private _custom_ds_dock_appointments_dashboard_base: app_custom_ds_dock_appointments_dashboard_baseService;
  public get custom_ds_dock_appointments_dashboard_base(): app_custom_ds_dock_appointments_dashboard_baseService {
    if(!this._custom_ds_dock_appointments_dashboard_base) {
      this._custom_ds_dock_appointments_dashboard_base = this.injector.get(app_custom_ds_dock_appointments_dashboard_baseService);
    }
    return this._custom_ds_dock_appointments_dashboard_base;
  }
  private _custom_ds_dock_appointments_dashboard_grid: app_custom_ds_dock_appointments_dashboard_gridService;
  public get custom_ds_dock_appointments_dashboard_grid(): app_custom_ds_dock_appointments_dashboard_gridService {
    if(!this._custom_ds_dock_appointments_dashboard_grid) {
      this._custom_ds_dock_appointments_dashboard_grid = this.injector.get(app_custom_ds_dock_appointments_dashboard_gridService);
    }
    return this._custom_ds_dock_appointments_dashboard_grid;
  }
  private _custom_ds_dock_appointments_dashboard_grid_data: app_custom_ds_dock_appointments_dashboard_grid_dataService;
  public get custom_ds_dock_appointments_dashboard_grid_data(): app_custom_ds_dock_appointments_dashboard_grid_dataService {
    if(!this._custom_ds_dock_appointments_dashboard_grid_data) {
      this._custom_ds_dock_appointments_dashboard_grid_data = this.injector.get(app_custom_ds_dock_appointments_dashboard_grid_dataService);
    }
    return this._custom_ds_dock_appointments_dashboard_grid_data;
  }
  private _custom_ds_dock_appointments_dashboard_grid_load_container_data: app_custom_ds_dock_appointments_dashboard_grid_load_container_dataService;
  public get custom_ds_dock_appointments_dashboard_grid_load_container_data(): app_custom_ds_dock_appointments_dashboard_grid_load_container_dataService {
    if(!this._custom_ds_dock_appointments_dashboard_grid_load_container_data) {
      this._custom_ds_dock_appointments_dashboard_grid_load_container_data = this.injector.get(app_custom_ds_dock_appointments_dashboard_grid_load_container_dataService);
    }
    return this._custom_ds_dock_appointments_dashboard_grid_load_container_data;
  }
  private _custom_ds_dock_appointments_dashboard_grid_order_data: app_custom_ds_dock_appointments_dashboard_grid_order_dataService;
  public get custom_ds_dock_appointments_dashboard_grid_order_data(): app_custom_ds_dock_appointments_dashboard_grid_order_dataService {
    if(!this._custom_ds_dock_appointments_dashboard_grid_order_data) {
      this._custom_ds_dock_appointments_dashboard_grid_order_data = this.injector.get(app_custom_ds_dock_appointments_dashboard_grid_order_dataService);
    }
    return this._custom_ds_dock_appointments_dashboard_grid_order_data;
  }
  private _custom_ds_dock_appointments_dashboard_last_task_user: app_custom_ds_dock_appointments_dashboard_last_task_userService;
  public get custom_ds_dock_appointments_dashboard_last_task_user(): app_custom_ds_dock_appointments_dashboard_last_task_userService {
    if(!this._custom_ds_dock_appointments_dashboard_last_task_user) {
      this._custom_ds_dock_appointments_dashboard_last_task_user = this.injector.get(app_custom_ds_dock_appointments_dashboard_last_task_userService);
    }
    return this._custom_ds_dock_appointments_dashboard_last_task_user;
  }
  private _custom_ds_dock_appointments_dashboard_task_amounts: app_custom_ds_dock_appointments_dashboard_task_amountsService;
  public get custom_ds_dock_appointments_dashboard_task_amounts(): app_custom_ds_dock_appointments_dashboard_task_amountsService {
    if(!this._custom_ds_dock_appointments_dashboard_task_amounts) {
      this._custom_ds_dock_appointments_dashboard_task_amounts = this.injector.get(app_custom_ds_dock_appointments_dashboard_task_amountsService);
    }
    return this._custom_ds_dock_appointments_dashboard_task_amounts;
  }
  private _custom_ds_dock_appointments_dashboard_tasks_pallet_details: app_custom_ds_dock_appointments_dashboard_tasks_pallet_detailsService;
  public get custom_ds_dock_appointments_dashboard_tasks_pallet_details(): app_custom_ds_dock_appointments_dashboard_tasks_pallet_detailsService {
    if(!this._custom_ds_dock_appointments_dashboard_tasks_pallet_details) {
      this._custom_ds_dock_appointments_dashboard_tasks_pallet_details = this.injector.get(app_custom_ds_dock_appointments_dashboard_tasks_pallet_detailsService);
    }
    return this._custom_ds_dock_appointments_dashboard_tasks_pallet_details;
  }
  private _custom_ds_get_licenseplate_count_lot_id: app_custom_ds_get_licenseplate_count_lot_idService;
  public get custom_ds_get_licenseplate_count_lot_id(): app_custom_ds_get_licenseplate_count_lot_idService {
    if(!this._custom_ds_get_licenseplate_count_lot_id) {
      this._custom_ds_get_licenseplate_count_lot_id = this.injector.get(app_custom_ds_get_licenseplate_count_lot_idService);
    }
    return this._custom_ds_get_licenseplate_count_lot_id;
  }
  private _custom_ds_get_licenseplate_count_material_id: app_custom_ds_get_licenseplate_count_material_idService;
  public get custom_ds_get_licenseplate_count_material_id(): app_custom_ds_get_licenseplate_count_material_idService {
    if(!this._custom_ds_get_licenseplate_count_material_id) {
      this._custom_ds_get_licenseplate_count_material_id = this.injector.get(app_custom_ds_get_licenseplate_count_material_idService);
    }
    return this._custom_ds_get_licenseplate_count_material_id;
  }
  private _custom_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId: app_custom_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentIdService;
  public get custom_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId(): app_custom_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentIdService {
    if(!this._custom_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId) {
      this._custom_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId = this.injector.get(app_custom_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentIdService);
    }
    return this._custom_ds_get_serialnumbers_non_fixed_weight_by_completed_shipmentId;
  }
  private _custom_ds_get_serialnumbers_non_fixed_weight_by_shipmentId: app_custom_ds_get_serialnumbers_non_fixed_weight_by_shipmentIdService;
  public get custom_ds_get_serialnumbers_non_fixed_weight_by_shipmentId(): app_custom_ds_get_serialnumbers_non_fixed_weight_by_shipmentIdService {
    if(!this._custom_ds_get_serialnumbers_non_fixed_weight_by_shipmentId) {
      this._custom_ds_get_serialnumbers_non_fixed_weight_by_shipmentId = this.injector.get(app_custom_ds_get_serialnumbers_non_fixed_weight_by_shipmentIdService);
    }
    return this._custom_ds_get_serialnumbers_non_fixed_weight_by_shipmentId;
  }
  private _custom_ds_inventory_licenseplate_grid: app_custom_ds_inventory_licenseplate_gridService;
  public get custom_ds_inventory_licenseplate_grid(): app_custom_ds_inventory_licenseplate_gridService {
    if(!this._custom_ds_inventory_licenseplate_grid) {
      this._custom_ds_inventory_licenseplate_grid = this.injector.get(app_custom_ds_inventory_licenseplate_gridService);
    }
    return this._custom_ds_inventory_licenseplate_grid;
  }
  private _custom_ds_inventory_lot_grid: app_custom_ds_inventory_lot_gridService;
  public get custom_ds_inventory_lot_grid(): app_custom_ds_inventory_lot_gridService {
    if(!this._custom_ds_inventory_lot_grid) {
      this._custom_ds_inventory_lot_grid = this.injector.get(app_custom_ds_inventory_lot_gridService);
    }
    return this._custom_ds_inventory_lot_grid;
  }
  private _custom_ds_inventory_material_grid: app_custom_ds_inventory_material_gridService;
  public get custom_ds_inventory_material_grid(): app_custom_ds_inventory_material_gridService {
    if(!this._custom_ds_inventory_material_grid) {
      this._custom_ds_inventory_material_grid = this.injector.get(app_custom_ds_inventory_material_gridService);
    }
    return this._custom_ds_inventory_material_grid;
  }
  private _custom_ds_inventory_report: app_custom_ds_inventory_reportService;
  public get custom_ds_inventory_report(): app_custom_ds_inventory_reportService {
    if(!this._custom_ds_inventory_report) {
      this._custom_ds_inventory_report = this.injector.get(app_custom_ds_inventory_reportService);
    }
    return this._custom_ds_inventory_report;
  }
  private _custom_ds_inventory_report_by_project: app_custom_ds_inventory_report_by_projectService;
  public get custom_ds_inventory_report_by_project(): app_custom_ds_inventory_report_by_projectService {
    if(!this._custom_ds_inventory_report_by_project) {
      this._custom_ds_inventory_report_by_project = this.injector.get(app_custom_ds_inventory_report_by_projectService);
    }
    return this._custom_ds_inventory_report_by_project;
  }
  private _custom_ds_inventory_report_get_converted_weight: app_custom_ds_inventory_report_get_converted_weightService;
  public get custom_ds_inventory_report_get_converted_weight(): app_custom_ds_inventory_report_get_converted_weightService {
    if(!this._custom_ds_inventory_report_get_converted_weight) {
      this._custom_ds_inventory_report_get_converted_weight = this.injector.get(app_custom_ds_inventory_report_get_converted_weightService);
    }
    return this._custom_ds_inventory_report_get_converted_weight;
  }
  private _custom_ds_load_diagram_report: app_custom_ds_load_diagram_reportService;
  public get custom_ds_load_diagram_report(): app_custom_ds_load_diagram_reportService {
    if(!this._custom_ds_load_diagram_report) {
      this._custom_ds_load_diagram_report = this.injector.get(app_custom_ds_load_diagram_reportService);
    }
    return this._custom_ds_load_diagram_report;
  }
  private _custom_ds_load_diagram_report_shipments_completed_cancelled: app_custom_ds_load_diagram_report_shipments_completed_cancelledService;
  public get custom_ds_load_diagram_report_shipments_completed_cancelled(): app_custom_ds_load_diagram_report_shipments_completed_cancelledService {
    if(!this._custom_ds_load_diagram_report_shipments_completed_cancelled) {
      this._custom_ds_load_diagram_report_shipments_completed_cancelled = this.injector.get(app_custom_ds_load_diagram_report_shipments_completed_cancelledService);
    }
    return this._custom_ds_load_diagram_report_shipments_completed_cancelled;
  }
  private _custom_ds_load_diagram_report_shipments_executing: app_custom_ds_load_diagram_report_shipments_executingService;
  public get custom_ds_load_diagram_report_shipments_executing(): app_custom_ds_load_diagram_report_shipments_executingService {
    if(!this._custom_ds_load_diagram_report_shipments_executing) {
      this._custom_ds_load_diagram_report_shipments_executing = this.injector.get(app_custom_ds_load_diagram_report_shipments_executingService);
    }
    return this._custom_ds_load_diagram_report_shipments_executing;
  }
  private _custom_ds_load_diagram_report_task_picking_status_completed: app_custom_ds_load_diagram_report_task_picking_status_completedService;
  public get custom_ds_load_diagram_report_task_picking_status_completed(): app_custom_ds_load_diagram_report_task_picking_status_completedService {
    if(!this._custom_ds_load_diagram_report_task_picking_status_completed) {
      this._custom_ds_load_diagram_report_task_picking_status_completed = this.injector.get(app_custom_ds_load_diagram_report_task_picking_status_completedService);
    }
    return this._custom_ds_load_diagram_report_task_picking_status_completed;
  }
  private _custom_ds_load_diagram_report_task_shipment_loading: app_custom_ds_load_diagram_report_task_shipment_loadingService;
  public get custom_ds_load_diagram_report_task_shipment_loading(): app_custom_ds_load_diagram_report_task_shipment_loadingService {
    if(!this._custom_ds_load_diagram_report_task_shipment_loading) {
      this._custom_ds_load_diagram_report_task_shipment_loading = this.injector.get(app_custom_ds_load_diagram_report_task_shipment_loadingService);
    }
    return this._custom_ds_load_diagram_report_task_shipment_loading;
  }
  private _custom_ds_order_total_picked_net_widget: app_custom_ds_order_total_picked_net_widgetService;
  public get custom_ds_order_total_picked_net_widget(): app_custom_ds_order_total_picked_net_widgetService {
    if(!this._custom_ds_order_total_picked_net_widget) {
      this._custom_ds_order_total_picked_net_widget = this.injector.get(app_custom_ds_order_total_picked_net_widgetService);
    }
    return this._custom_ds_order_total_picked_net_widget;
  }
  private _custom_ds_prediction_flow_load_container: app_custom_ds_prediction_flow_load_containerService;
  public get custom_ds_prediction_flow_load_container(): app_custom_ds_prediction_flow_load_containerService {
    if(!this._custom_ds_prediction_flow_load_container) {
      this._custom_ds_prediction_flow_load_container = this.injector.get(app_custom_ds_prediction_flow_load_containerService);
    }
    return this._custom_ds_prediction_flow_load_container;
  }
  private _custom_ds_prediction_load_container_filter: app_custom_ds_prediction_load_container_filterService;
  public get custom_ds_prediction_load_container_filter(): app_custom_ds_prediction_load_container_filterService {
    if(!this._custom_ds_prediction_load_container_filter) {
      this._custom_ds_prediction_load_container_filter = this.injector.get(app_custom_ds_prediction_load_container_filterService);
    }
    return this._custom_ds_prediction_load_container_filter;
  }
  private _custom_ds_sales_order_editor: app_custom_ds_sales_order_editorService;
  public get custom_ds_sales_order_editor(): app_custom_ds_sales_order_editorService {
    if(!this._custom_ds_sales_order_editor) {
      this._custom_ds_sales_order_editor = this.injector.get(app_custom_ds_sales_order_editorService);
    }
    return this._custom_ds_sales_order_editor;
  }
  private _custom_ds_sales_order_lines_grid: app_custom_ds_sales_order_lines_gridService;
  public get custom_ds_sales_order_lines_grid(): app_custom_ds_sales_order_lines_gridService {
    if(!this._custom_ds_sales_order_lines_grid) {
      this._custom_ds_sales_order_lines_grid = this.injector.get(app_custom_ds_sales_order_lines_gridService);
    }
    return this._custom_ds_sales_order_lines_grid;
  }
  private _custom_ds_total_picked_by_orderids_shipmentids: app_custom_ds_total_picked_by_orderids_shipmentidsService;
  public get custom_ds_total_picked_by_orderids_shipmentids(): app_custom_ds_total_picked_by_orderids_shipmentidsService {
    if(!this._custom_ds_total_picked_by_orderids_shipmentids) {
      this._custom_ds_total_picked_by_orderids_shipmentids = this.injector.get(app_custom_ds_total_picked_by_orderids_shipmentidsService);
    }
    return this._custom_ds_total_picked_by_orderids_shipmentids;
  }
  private _custom_ds_total_received_by_orderids_shipmentids: app_custom_ds_total_received_by_orderids_shipmentidsService;
  public get custom_ds_total_received_by_orderids_shipmentids(): app_custom_ds_total_received_by_orderids_shipmentidsService {
    if(!this._custom_ds_total_received_by_orderids_shipmentids) {
      this._custom_ds_total_received_by_orderids_shipmentids = this.injector.get(app_custom_ds_total_received_by_orderids_shipmentidsService);
    }
    return this._custom_ds_total_received_by_orderids_shipmentids;
  }
  private _custom_get_shipping_container_by_shipment_id: app_custom_get_shipping_container_by_shipment_idService;
  public get custom_get_shipping_container_by_shipment_id(): app_custom_get_shipping_container_by_shipment_idService {
    if(!this._custom_get_shipping_container_by_shipment_id) {
      this._custom_get_shipping_container_by_shipment_id = this.injector.get(app_custom_get_shipping_container_by_shipment_idService);
    }
    return this._custom_get_shipping_container_by_shipment_id;
  }
  private _customized_ds_sales_order_lines_grid: app_customized_ds_sales_order_lines_gridService;
  public get customized_ds_sales_order_lines_grid(): app_customized_ds_sales_order_lines_gridService {
    if(!this._customized_ds_sales_order_lines_grid) {
      this._customized_ds_sales_order_lines_grid = this.injector.get(app_customized_ds_sales_order_lines_gridService);
    }
    return this._customized_ds_sales_order_lines_grid;
  }
  private _customized_sales_order_editor___ds_get_order_info: app_customized_sales_order_editor___ds_get_order_infoService;
  public get customized_sales_order_editor___ds_get_order_info(): app_customized_sales_order_editor___ds_get_order_infoService {
    if(!this._customized_sales_order_editor___ds_get_order_info) {
      this._customized_sales_order_editor___ds_get_order_info = this.injector.get(app_customized_sales_order_editor___ds_get_order_infoService);
    }
    return this._customized_sales_order_editor___ds_get_order_info;
  }
  private _ds_get_license_plates_by_shipmentId: app_ds_get_license_plates_by_shipmentIdService;
  public get ds_get_license_plates_by_shipmentId(): app_ds_get_license_plates_by_shipmentIdService {
    if(!this._ds_get_license_plates_by_shipmentId) {
      this._ds_get_license_plates_by_shipmentId = this.injector.get(app_ds_get_license_plates_by_shipmentIdService);
    }
    return this._ds_get_license_plates_by_shipmentId;
  }
  private _ds_get_material_packaging_info_by_name_and_project_and_cs: app_ds_get_material_packaging_info_by_name_and_project_and_csService;
  public get ds_get_material_packaging_info_by_name_and_project_and_cs(): app_ds_get_material_packaging_info_by_name_and_project_and_csService {
    if(!this._ds_get_material_packaging_info_by_name_and_project_and_cs) {
      this._ds_get_material_packaging_info_by_name_and_project_and_cs = this.injector.get(app_ds_get_material_packaging_info_by_name_and_project_and_csService);
    }
    return this._ds_get_material_packaging_info_by_name_and_project_and_cs;
  }
  private _ds_get_materials_by_name_and_project: app_ds_get_materials_by_name_and_projectService;
  public get ds_get_materials_by_name_and_project(): app_ds_get_materials_by_name_and_projectService {
    if(!this._ds_get_materials_by_name_and_project) {
      this._ds_get_materials_by_name_and_project = this.injector.get(app_ds_get_materials_by_name_and_projectService);
    }
    return this._ds_get_materials_by_name_and_project;
  }
  private _ds_get_shipment_by_id: app_ds_get_shipment_by_idService;
  public get ds_get_shipment_by_id(): app_ds_get_shipment_by_idService {
    if(!this._ds_get_shipment_by_id) {
      this._ds_get_shipment_by_id = this.injector.get(app_ds_get_shipment_by_idService);
    }
    return this._ds_get_shipment_by_id;
  }
  private _test_ds_prediction_max_ship_time: app_test_ds_prediction_max_ship_timeService;
  public get test_ds_prediction_max_ship_time(): app_test_ds_prediction_max_ship_timeService {
    if(!this._test_ds_prediction_max_ship_time) {
      this._test_ds_prediction_max_ship_time = this.injector.get(app_test_ds_prediction_max_ship_timeService);
    }
    return this._test_ds_prediction_max_ship_time;
  }
  private _vg_get_order_account_address_by_orderId_sh: app_vg_get_order_account_address_by_orderId_shService;
  public get vg_get_order_account_address_by_orderId_sh(): app_vg_get_order_account_address_by_orderId_shService {
    if(!this._vg_get_order_account_address_by_orderId_sh) {
      this._vg_get_order_account_address_by_orderId_sh = this.injector.get(app_vg_get_order_account_address_by_orderId_shService);
    }
    return this._vg_get_order_account_address_by_orderId_sh;
  }
}

