import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_case_pick_labelServiceInParams {
  shippingContainerId: number}

interface IReports_custom_case_pick_labelServiceData {
  ds_get_shipping_container_details?: { result?: { Id?: number, LookupCode?: string, Shipment?: { Id?: number, OrderLookups?: { OrderId?: number, Order?: { LookupCode?: string } }[] }, orderLookupCode?: string, shippingContainerLookupCode?: string }[] }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_case_pick_labelService extends ReportBaseService<IReports_custom_case_pick_labelServiceInParams, IReports_custom_case_pick_labelServiceData> {

  protected reportReferenceName = 'custom_case_pick_label';
  protected appReferenceName = 'Reports';

  constructor(
    $utils: UtilsService,
    private $datasources: Reports_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_case_pick_labelServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_case_pick_labelServiceInParams): Promise<IReports_custom_case_pick_labelServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IReports_custom_case_pick_labelServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_case_pick_labelServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        shippingContainerId:  $report.inParams.shippingContainerId 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_get_shipping_orders_details_by_shipping_container_id.get(dsParams);
      
      data.ds_get_shipping_container_details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
