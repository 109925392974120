import { Inject, Injectable, Injector } from '@angular/core';

import { Document360_FlowService } from './Document360.flow.index';
import { Usersnap_FlowService } from './Usersnap.flow.index';
import { Utilities_FlowService } from './Utilities.flow.index';
import { Addresses_FlowService } from './Addresses.flow.index';
import { BarTender_FlowService } from './BarTender.flow.index';
import { Discussions_FlowService } from './Discussions.flow.index';
import { DockAppointments_FlowService } from './DockAppointments.flow.index';
import { EndeavorLabs_FlowService } from './EndeavorLabs.flow.index';
import { EntityImport_FlowService } from './EntityImport.flow.index';
import { ExcelInventoryImport_FlowService } from './ExcelInventoryImport.flow.index';
import { ExcelLocationImport_FlowService } from './ExcelLocationImport.flow.index';
import { ExcelMaterialImport_FlowService } from './ExcelMaterialImport.flow.index';
import { LoadContainers_FlowService } from './LoadContainers.flow.index';
import { Owners_FlowService } from './Owners.flow.index';
import { Surveys_FlowService } from './Surveys.flow.index';
import { Waves_FlowService } from './Waves.flow.index';
import { Instructions_FlowService } from './Instructions.flow.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Materials_FlowService } from './Materials.flow.index';
import { Carriers_FlowService } from './Carriers.flow.index';
import { Locations_FlowService } from './Locations.flow.index';
import { PurchaseOrders_FlowService } from './PurchaseOrders.flow.index';
import { SalesOrders_FlowService } from './SalesOrders.flow.index';
import { InventoryTransformations_FlowService } from './InventoryTransformations.flow.index';
import { SerialNumbers_FlowService } from './SerialNumbers.flow.index';
import { Manifesting_FlowService } from './Manifesting.flow.index';
import { Lots_FlowService } from './Lots.flow.index';
import { Inventory_FlowService } from './Inventory.flow.index';
import { AsnOrders_FlowService } from './AsnOrders.flow.index';
import { ExcelOrderImport_FlowService } from './ExcelOrderImport.flow.index';
import { Attachments_FlowService } from './Attachments.flow.index';
import { FootprintApiManager_FlowService } from './FootprintApiManager.flow.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { Orderful_FlowService } from './Orderful.flow.index';
import { Reports_FlowService } from './Reports.flow.index';

import { app_convert_weight_flowService } from './app.flow.index';
import { app_custom_complete_asn_order_flowService } from './app.flow.index';
import { app_custom_is_asn_order_completable_flowService } from './app.flow.index';
import { app_custom_is_purchase_order_completable_flowService } from './app.flow.index';
import { app_custom_is_sales_order_completable_flowService } from './app.flow.index';
import { app_format_edi_dateService } from './app.flow.index';
import { app_orderful_846_mapService } from './app.flow.index';
import { app_orderful_940_mapService } from './app.flow.index';
import { app_orderful_943_mapService } from './app.flow.index';
import { app_orderful_944_mapService } from './app.flow.index';
import { app_orderful_945_mapService } from './app.flow.index';
import { app_orderful_apply_inventory_mapService } from './app.flow.index';
import { app_orderful_apply_order_mapService } from './app.flow.index';
import { app_orderful_bernatellos_940_mapService } from './app.flow.index';
import { app_orderful_bernatellos_943_mapService } from './app.flow.index';
import { app_orderful_bernatellos_944_mapService } from './app.flow.index';
import { app_orderful_bernatellos_945_mapService } from './app.flow.index';
import { app_orderful_birchwood_940_mapService } from './app.flow.index';
import { app_orderful_birchwood_943_mapService } from './app.flow.index';
import { app_orderful_birchwood_944_mapService } from './app.flow.index';
import { app_orderful_birchwood_945_mapService } from './app.flow.index';
import { app_orderful_birchwood_947_in_mapService } from './app.flow.index';
import { app_orderful_birchwood_947_out_mapService } from './app.flow.index';
import { app_orderful_crown_940_mapService } from './app.flow.index';
import { app_orderful_crown_943_mapService } from './app.flow.index';
import { app_orderful_crown_945_mapService } from './app.flow.index';
import { app_orderful_dsm_940_mapService } from './app.flow.index';
import { app_orderful_dsm_943_mapService } from './app.flow.index';
import { app_orderful_dsm_944_mapService } from './app.flow.index';
import { app_orderful_dsm_945_mapService } from './app.flow.index';
import { app_orderful_fair_oaks_940_mapService } from './app.flow.index';
import { app_orderful_fair_oaks_943_mapService } from './app.flow.index';
import { app_orderful_fair_oaks_944_mapService } from './app.flow.index';
import { app_orderful_fair_oaks_945_mapService } from './app.flow.index';
import { app_orderful_nortera_940_mapService } from './app.flow.index';
import { app_orderful_nortera_943_mapService } from './app.flow.index';
import { app_orderful_nortera_945_mapService } from './app.flow.index';
import { app_orderful_palermos_940_mapService } from './app.flow.index';
import { app_orderful_palermos_943_mapService } from './app.flow.index';
import { app_orderful_palermos_944_mapService } from './app.flow.index';
import { app_orderful_palermos_945_mapService } from './app.flow.index';
import { app_orderful_pretzilla_940_mapService } from './app.flow.index';
import { app_orderful_pretzilla_943_mapService } from './app.flow.index';
import { app_orderful_pretzilla_945_mapService } from './app.flow.index';
import { app_orderful_raos_940_mapService } from './app.flow.index';
import { app_orderful_raos_943_mapService } from './app.flow.index';
import { app_orderful_raos_944_mapService } from './app.flow.index';
import { app_orderful_raos_945_mapService } from './app.flow.index';
import { app_orderful_rhodes_940_mapService } from './app.flow.index';
import { app_orderful_rhodes_943_mapService } from './app.flow.index';
import { app_orderful_rhodes_945_mapService } from './app.flow.index';
import { app_orderful_richelieu_940_mapService } from './app.flow.index';
import { app_orderful_richelieu_943_mapService } from './app.flow.index';
import { app_orderful_richelieu_944_mapService } from './app.flow.index';
import { app_orderful_richelieu_945_mapService } from './app.flow.index';
import { app_orderful_saputo_940_mapService } from './app.flow.index';
import { app_orderful_saputo_943_mapService } from './app.flow.index';
import { app_orderful_saputo_944_mapService } from './app.flow.index';
import { app_orderful_saputo_945_mapService } from './app.flow.index';
import { app_orderful_saputo_947_in_mapService } from './app.flow.index';
import { app_orderful_saputo_947_out_mapService } from './app.flow.index';
import { app_orderful_sargento_940_mapService } from './app.flow.index';
import { app_orderful_sargento_943_mapService } from './app.flow.index';
import { app_orderful_sargento_944_mapService } from './app.flow.index';
import { app_orderful_sargento_944_map_oldService } from './app.flow.index';
import { app_orderful_sargento_945_mapService } from './app.flow.index';
import { app_orderful_vanskitchen_940_mapService } from './app.flow.index';
import { app_orderful_vanskitchen_945_mapService } from './app.flow.index';
import { app_orderful_walmart_856_out_mapService } from './app.flow.index';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Document360 = this.injector.get(Document360_FlowService);
    this.Usersnap = this.injector.get(Usersnap_FlowService);
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.Addresses = this.injector.get(Addresses_FlowService);
    this.BarTender = this.injector.get(BarTender_FlowService);
    this.Discussions = this.injector.get(Discussions_FlowService);
    this.DockAppointments = this.injector.get(DockAppointments_FlowService);
    this.EndeavorLabs = this.injector.get(EndeavorLabs_FlowService);
    this.EntityImport = this.injector.get(EntityImport_FlowService);
    this.ExcelInventoryImport = this.injector.get(ExcelInventoryImport_FlowService);
    this.ExcelLocationImport = this.injector.get(ExcelLocationImport_FlowService);
    this.ExcelMaterialImport = this.injector.get(ExcelMaterialImport_FlowService);
    this.LoadContainers = this.injector.get(LoadContainers_FlowService);
    this.Owners = this.injector.get(Owners_FlowService);
    this.Surveys = this.injector.get(Surveys_FlowService);
    this.Waves = this.injector.get(Waves_FlowService);
    this.Instructions = this.injector.get(Instructions_FlowService);
    this.Notifications = this.injector.get(Notifications_FlowService);
    this.Invoices = this.injector.get(Invoices_FlowService);
    this.Materials = this.injector.get(Materials_FlowService);
    this.Carriers = this.injector.get(Carriers_FlowService);
    this.Locations = this.injector.get(Locations_FlowService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_FlowService);
    this.SalesOrders = this.injector.get(SalesOrders_FlowService);
    this.InventoryTransformations = this.injector.get(InventoryTransformations_FlowService);
    this.SerialNumbers = this.injector.get(SerialNumbers_FlowService);
    this.Manifesting = this.injector.get(Manifesting_FlowService);
    this.Lots = this.injector.get(Lots_FlowService);
    this.Inventory = this.injector.get(Inventory_FlowService);
    this.AsnOrders = this.injector.get(AsnOrders_FlowService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_FlowService);
    this.Attachments = this.injector.get(Attachments_FlowService);
    this.FootprintApiManager = this.injector.get(FootprintApiManager_FlowService);
    this.FootprintManager = this.injector.get(FootprintManager_FlowService);
    this.Orderful = this.injector.get(Orderful_FlowService);
    this.Reports = this.injector.get(Reports_FlowService);
  }

    public Document360: Document360_FlowService;
    public Usersnap: Usersnap_FlowService;
    public Utilities: Utilities_FlowService;
    public Addresses: Addresses_FlowService;
    public BarTender: BarTender_FlowService;
    public Discussions: Discussions_FlowService;
    public DockAppointments: DockAppointments_FlowService;
    public EndeavorLabs: EndeavorLabs_FlowService;
    public EntityImport: EntityImport_FlowService;
    public ExcelInventoryImport: ExcelInventoryImport_FlowService;
    public ExcelLocationImport: ExcelLocationImport_FlowService;
    public ExcelMaterialImport: ExcelMaterialImport_FlowService;
    public LoadContainers: LoadContainers_FlowService;
    public Owners: Owners_FlowService;
    public Surveys: Surveys_FlowService;
    public Waves: Waves_FlowService;
    public Instructions: Instructions_FlowService;
    public Notifications: Notifications_FlowService;
    public Invoices: Invoices_FlowService;
    public Materials: Materials_FlowService;
    public Carriers: Carriers_FlowService;
    public Locations: Locations_FlowService;
    public PurchaseOrders: PurchaseOrders_FlowService;
    public SalesOrders: SalesOrders_FlowService;
    public InventoryTransformations: InventoryTransformations_FlowService;
    public SerialNumbers: SerialNumbers_FlowService;
    public Manifesting: Manifesting_FlowService;
    public Lots: Lots_FlowService;
    public Inventory: Inventory_FlowService;
    public AsnOrders: AsnOrders_FlowService;
    public ExcelOrderImport: ExcelOrderImport_FlowService;
    public Attachments: Attachments_FlowService;
    public FootprintApiManager: FootprintApiManager_FlowService;
    public FootprintManager: FootprintManager_FlowService;
    public Orderful: Orderful_FlowService;
    public Reports: Reports_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _convert_weight_flow: app_convert_weight_flowService;
  public async convert_weight_flow(inParams: { inputWeight: number, fromUom: number, toUom: number }): Promise< { convertedWeight?: number, reasons?: string[] }> {
    if(!this._convert_weight_flow) { 
      this._convert_weight_flow = this.injector.get(app_convert_weight_flowService);
    }
    return this._convert_weight_flow.run(inParams);
  }
   
   

   
 
  private _custom_complete_asn_order_flow: app_custom_complete_asn_order_flowService;
  public async custom_complete_asn_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._custom_complete_asn_order_flow) { 
      this._custom_complete_asn_order_flow = this.injector.get(app_custom_complete_asn_order_flowService);
    }
    return this._custom_complete_asn_order_flow.run(inParams);
  }
   
   

   
 
  private _custom_is_asn_order_completable_flow: app_custom_is_asn_order_completable_flowService;
  public async custom_is_asn_order_completable_flow(inParams: { orderId: number, optimalInputs?: { statusId?: number, hasCompletedReceivingTasks?: boolean, checkForPlannedReceivingTasks?: boolean } }): Promise< { reasons?: string[] }> {
    if(!this._custom_is_asn_order_completable_flow) { 
      this._custom_is_asn_order_completable_flow = this.injector.get(app_custom_is_asn_order_completable_flowService);
    }
    return this._custom_is_asn_order_completable_flow.run(inParams);
  }
   
   

   
 
  private _custom_is_purchase_order_completable_flow: app_custom_is_purchase_order_completable_flowService;
  public async custom_is_purchase_order_completable_flow(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._custom_is_purchase_order_completable_flow) { 
      this._custom_is_purchase_order_completable_flow = this.injector.get(app_custom_is_purchase_order_completable_flowService);
    }
    return this._custom_is_purchase_order_completable_flow.run(inParams);
  }
   
   

   
 
  private _custom_is_sales_order_completable_flow: app_custom_is_sales_order_completable_flowService;
  public async custom_is_sales_order_completable_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._custom_is_sales_order_completable_flow) { 
      this._custom_is_sales_order_completable_flow = this.injector.get(app_custom_is_sales_order_completable_flowService);
    }
    return this._custom_is_sales_order_completable_flow.run(inParams);
  }
   
   

   
 
  private _format_edi_date: app_format_edi_dateService;
  public async format_edi_date(inParams: { dateIn: string }): Promise< { dateOut: string }> {
    if(!this._format_edi_date) { 
      this._format_edi_date = this.injector.get(app_format_edi_dateService);
    }
    return this._format_edi_date.run(inParams);
  }
   
   

   
 
  private _orderful_846_map: app_orderful_846_mapService;
  public async orderful_846_map(inParams: { datex_inventory_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, mapping_summary?: boolean }): Promise< { payload?: any[], messages?: any[] }> {
    if(!this._orderful_846_map) { 
      this._orderful_846_map = this.injector.get(app_orderful_846_mapService);
    }
    return this._orderful_846_map.run(inParams);
  }
   
   

   
 
  private _orderful_940_map: app_orderful_940_mapService;
  public async orderful_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_940_map) { 
      this._orderful_940_map = this.injector.get(app_orderful_940_mapService);
    }
    return this._orderful_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_943_map: app_orderful_943_mapService;
  public async orderful_943_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_943_map) { 
      this._orderful_943_map = this.injector.get(app_orderful_943_mapService);
    }
    return this._orderful_943_map.run(inParams);
  }
   
   

   
 
  private _orderful_944_map: app_orderful_944_mapService;
  public async orderful_944_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_944_map) { 
      this._orderful_944_map = this.injector.get(app_orderful_944_mapService);
    }
    return this._orderful_944_map.run(inParams);
  }
   
   

   
 
  private _orderful_945_map: app_orderful_945_mapService;
  public async orderful_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_945_map) { 
      this._orderful_945_map = this.injector.get(app_orderful_945_mapService);
    }
    return this._orderful_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_apply_inventory_map: app_orderful_apply_inventory_mapService;
  public async orderful_apply_inventory_map(inParams: { inventory_payload?: any, sender?: string, receiver?: string, stream?: string, resulting_type?: string }): Promise< { inventory_maps?: { project_lookup_code?: string, sender?: string, receiver?: string, stream?: string, transaction_type?: string, resulting_type?: string, map?: string, render_lots?: boolean, render_license_plates?: boolean, render_serial_numbers?: boolean, only_inventory?: boolean, days?: number, taskId?: number, only_physical?: boolean }[], inventory?: any, map?: string }> {
    if(!this._orderful_apply_inventory_map) { 
      this._orderful_apply_inventory_map = this.injector.get(app_orderful_apply_inventory_mapService);
    }
    return this._orderful_apply_inventory_map.run(inParams);
  }
   
   

   
 
  private _orderful_apply_order_map: app_orderful_apply_order_mapService;
  public async orderful_apply_order_map(inParams: { order_payload?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number }): Promise< { result_payload?: { orders?: any[], transaction_type?: string, map?: string }[] }> {
    if(!this._orderful_apply_order_map) { 
      this._orderful_apply_order_map = this.injector.get(app_orderful_apply_order_mapService);
    }
    return this._orderful_apply_order_map.run(inParams);
  }
   
   

   
 
  private _orderful_bernatellos_940_map: app_orderful_bernatellos_940_mapService;
  public async orderful_bernatellos_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_bernatellos_940_map) { 
      this._orderful_bernatellos_940_map = this.injector.get(app_orderful_bernatellos_940_mapService);
    }
    return this._orderful_bernatellos_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_bernatellos_943_map: app_orderful_bernatellos_943_mapService;
  public async orderful_bernatellos_943_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_bernatellos_943_map) { 
      this._orderful_bernatellos_943_map = this.injector.get(app_orderful_bernatellos_943_mapService);
    }
    return this._orderful_bernatellos_943_map.run(inParams);
  }
   
   

   
 
  private _orderful_bernatellos_944_map: app_orderful_bernatellos_944_mapService;
  public async orderful_bernatellos_944_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_bernatellos_944_map) { 
      this._orderful_bernatellos_944_map = this.injector.get(app_orderful_bernatellos_944_mapService);
    }
    return this._orderful_bernatellos_944_map.run(inParams);
  }
   
   

   
 
  private _orderful_bernatellos_945_map: app_orderful_bernatellos_945_mapService;
  public async orderful_bernatellos_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_bernatellos_945_map) { 
      this._orderful_bernatellos_945_map = this.injector.get(app_orderful_bernatellos_945_mapService);
    }
    return this._orderful_bernatellos_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_birchwood_940_map: app_orderful_birchwood_940_mapService;
  public async orderful_birchwood_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_birchwood_940_map) { 
      this._orderful_birchwood_940_map = this.injector.get(app_orderful_birchwood_940_mapService);
    }
    return this._orderful_birchwood_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_birchwood_943_map: app_orderful_birchwood_943_mapService;
  public async orderful_birchwood_943_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_birchwood_943_map) { 
      this._orderful_birchwood_943_map = this.injector.get(app_orderful_birchwood_943_mapService);
    }
    return this._orderful_birchwood_943_map.run(inParams);
  }
   
   

   
 
  private _orderful_birchwood_944_map: app_orderful_birchwood_944_mapService;
  public async orderful_birchwood_944_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_birchwood_944_map) { 
      this._orderful_birchwood_944_map = this.injector.get(app_orderful_birchwood_944_mapService);
    }
    return this._orderful_birchwood_944_map.run(inParams);
  }
   
   

   
 
  private _orderful_birchwood_945_map: app_orderful_birchwood_945_mapService;
  public async orderful_birchwood_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_birchwood_945_map) { 
      this._orderful_birchwood_945_map = this.injector.get(app_orderful_birchwood_945_mapService);
    }
    return this._orderful_birchwood_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_birchwood_947_in_map: app_orderful_birchwood_947_in_mapService;
  public async orderful_birchwood_947_in_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, mapping_summary?: boolean }): Promise< { DatexAdjustmentInfo?: $frontendTypes.Orderful.DatexAdjustmentInfo[] }> {
    if(!this._orderful_birchwood_947_in_map) { 
      this._orderful_birchwood_947_in_map = this.injector.get(app_orderful_birchwood_947_in_mapService);
    }
    return this._orderful_birchwood_947_in_map.run(inParams);
  }
   
   

   
 
  private _orderful_birchwood_947_out_map: app_orderful_birchwood_947_out_mapService;
  public async orderful_birchwood_947_out_map(inParams: { datex_adjustment_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, mapping_summary?: boolean }): Promise< { payload?: any[], messages?: any[] }> {
    if(!this._orderful_birchwood_947_out_map) { 
      this._orderful_birchwood_947_out_map = this.injector.get(app_orderful_birchwood_947_out_mapService);
    }
    return this._orderful_birchwood_947_out_map.run(inParams);
  }
   
   

   
 
  private _orderful_crown_940_map: app_orderful_crown_940_mapService;
  public async orderful_crown_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_crown_940_map) { 
      this._orderful_crown_940_map = this.injector.get(app_orderful_crown_940_mapService);
    }
    return this._orderful_crown_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_crown_943_map: app_orderful_crown_943_mapService;
  public async orderful_crown_943_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_crown_943_map) { 
      this._orderful_crown_943_map = this.injector.get(app_orderful_crown_943_mapService);
    }
    return this._orderful_crown_943_map.run(inParams);
  }
   
   

   
 
  private _orderful_crown_945_map: app_orderful_crown_945_mapService;
  public async orderful_crown_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_crown_945_map) { 
      this._orderful_crown_945_map = this.injector.get(app_orderful_crown_945_mapService);
    }
    return this._orderful_crown_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_dsm_940_map: app_orderful_dsm_940_mapService;
  public async orderful_dsm_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_dsm_940_map) { 
      this._orderful_dsm_940_map = this.injector.get(app_orderful_dsm_940_mapService);
    }
    return this._orderful_dsm_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_dsm_943_map: app_orderful_dsm_943_mapService;
  public async orderful_dsm_943_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_dsm_943_map) { 
      this._orderful_dsm_943_map = this.injector.get(app_orderful_dsm_943_mapService);
    }
    return this._orderful_dsm_943_map.run(inParams);
  }
   
   

   
 
  private _orderful_dsm_944_map: app_orderful_dsm_944_mapService;
  public async orderful_dsm_944_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_dsm_944_map) { 
      this._orderful_dsm_944_map = this.injector.get(app_orderful_dsm_944_mapService);
    }
    return this._orderful_dsm_944_map.run(inParams);
  }
   
   

   
 
  private _orderful_dsm_945_map: app_orderful_dsm_945_mapService;
  public async orderful_dsm_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_dsm_945_map) { 
      this._orderful_dsm_945_map = this.injector.get(app_orderful_dsm_945_mapService);
    }
    return this._orderful_dsm_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_fair_oaks_940_map: app_orderful_fair_oaks_940_mapService;
  public async orderful_fair_oaks_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_fair_oaks_940_map) { 
      this._orderful_fair_oaks_940_map = this.injector.get(app_orderful_fair_oaks_940_mapService);
    }
    return this._orderful_fair_oaks_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_fair_oaks_943_map: app_orderful_fair_oaks_943_mapService;
  public async orderful_fair_oaks_943_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_fair_oaks_943_map) { 
      this._orderful_fair_oaks_943_map = this.injector.get(app_orderful_fair_oaks_943_mapService);
    }
    return this._orderful_fair_oaks_943_map.run(inParams);
  }
   
   

   
 
  private _orderful_fair_oaks_944_map: app_orderful_fair_oaks_944_mapService;
  public async orderful_fair_oaks_944_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_fair_oaks_944_map) { 
      this._orderful_fair_oaks_944_map = this.injector.get(app_orderful_fair_oaks_944_mapService);
    }
    return this._orderful_fair_oaks_944_map.run(inParams);
  }
   
   

   
 
  private _orderful_fair_oaks_945_map: app_orderful_fair_oaks_945_mapService;
  public async orderful_fair_oaks_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_fair_oaks_945_map) { 
      this._orderful_fair_oaks_945_map = this.injector.get(app_orderful_fair_oaks_945_mapService);
    }
    return this._orderful_fair_oaks_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_nortera_940_map: app_orderful_nortera_940_mapService;
  public async orderful_nortera_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_nortera_940_map) { 
      this._orderful_nortera_940_map = this.injector.get(app_orderful_nortera_940_mapService);
    }
    return this._orderful_nortera_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_nortera_943_map: app_orderful_nortera_943_mapService;
  public async orderful_nortera_943_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_nortera_943_map) { 
      this._orderful_nortera_943_map = this.injector.get(app_orderful_nortera_943_mapService);
    }
    return this._orderful_nortera_943_map.run(inParams);
  }
   
   

   
 
  private _orderful_nortera_945_map: app_orderful_nortera_945_mapService;
  public async orderful_nortera_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_nortera_945_map) { 
      this._orderful_nortera_945_map = this.injector.get(app_orderful_nortera_945_mapService);
    }
    return this._orderful_nortera_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_palermos_940_map: app_orderful_palermos_940_mapService;
  public async orderful_palermos_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_palermos_940_map) { 
      this._orderful_palermos_940_map = this.injector.get(app_orderful_palermos_940_mapService);
    }
    return this._orderful_palermos_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_palermos_943_map: app_orderful_palermos_943_mapService;
  public async orderful_palermos_943_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_palermos_943_map) { 
      this._orderful_palermos_943_map = this.injector.get(app_orderful_palermos_943_mapService);
    }
    return this._orderful_palermos_943_map.run(inParams);
  }
   
   

   
 
  private _orderful_palermos_944_map: app_orderful_palermos_944_mapService;
  public async orderful_palermos_944_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_palermos_944_map) { 
      this._orderful_palermos_944_map = this.injector.get(app_orderful_palermos_944_mapService);
    }
    return this._orderful_palermos_944_map.run(inParams);
  }
   
   

   
 
  private _orderful_palermos_945_map: app_orderful_palermos_945_mapService;
  public async orderful_palermos_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_palermos_945_map) { 
      this._orderful_palermos_945_map = this.injector.get(app_orderful_palermos_945_mapService);
    }
    return this._orderful_palermos_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_pretzilla_940_map: app_orderful_pretzilla_940_mapService;
  public async orderful_pretzilla_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_pretzilla_940_map) { 
      this._orderful_pretzilla_940_map = this.injector.get(app_orderful_pretzilla_940_mapService);
    }
    return this._orderful_pretzilla_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_pretzilla_943_map: app_orderful_pretzilla_943_mapService;
  public async orderful_pretzilla_943_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_pretzilla_943_map) { 
      this._orderful_pretzilla_943_map = this.injector.get(app_orderful_pretzilla_943_mapService);
    }
    return this._orderful_pretzilla_943_map.run(inParams);
  }
   
   

   
 
  private _orderful_pretzilla_945_map: app_orderful_pretzilla_945_mapService;
  public async orderful_pretzilla_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_pretzilla_945_map) { 
      this._orderful_pretzilla_945_map = this.injector.get(app_orderful_pretzilla_945_mapService);
    }
    return this._orderful_pretzilla_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_raos_940_map: app_orderful_raos_940_mapService;
  public async orderful_raos_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_raos_940_map) { 
      this._orderful_raos_940_map = this.injector.get(app_orderful_raos_940_mapService);
    }
    return this._orderful_raos_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_raos_943_map: app_orderful_raos_943_mapService;
  public async orderful_raos_943_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_raos_943_map) { 
      this._orderful_raos_943_map = this.injector.get(app_orderful_raos_943_mapService);
    }
    return this._orderful_raos_943_map.run(inParams);
  }
   
   

   
 
  private _orderful_raos_944_map: app_orderful_raos_944_mapService;
  public async orderful_raos_944_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_raos_944_map) { 
      this._orderful_raos_944_map = this.injector.get(app_orderful_raos_944_mapService);
    }
    return this._orderful_raos_944_map.run(inParams);
  }
   
   

   
 
  private _orderful_raos_945_map: app_orderful_raos_945_mapService;
  public async orderful_raos_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_raos_945_map) { 
      this._orderful_raos_945_map = this.injector.get(app_orderful_raos_945_mapService);
    }
    return this._orderful_raos_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_rhodes_940_map: app_orderful_rhodes_940_mapService;
  public async orderful_rhodes_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_rhodes_940_map) { 
      this._orderful_rhodes_940_map = this.injector.get(app_orderful_rhodes_940_mapService);
    }
    return this._orderful_rhodes_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_rhodes_943_map: app_orderful_rhodes_943_mapService;
  public async orderful_rhodes_943_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_rhodes_943_map) { 
      this._orderful_rhodes_943_map = this.injector.get(app_orderful_rhodes_943_mapService);
    }
    return this._orderful_rhodes_943_map.run(inParams);
  }
   
   

   
 
  private _orderful_rhodes_945_map: app_orderful_rhodes_945_mapService;
  public async orderful_rhodes_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_rhodes_945_map) { 
      this._orderful_rhodes_945_map = this.injector.get(app_orderful_rhodes_945_mapService);
    }
    return this._orderful_rhodes_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_richelieu_940_map: app_orderful_richelieu_940_mapService;
  public async orderful_richelieu_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_richelieu_940_map) { 
      this._orderful_richelieu_940_map = this.injector.get(app_orderful_richelieu_940_mapService);
    }
    return this._orderful_richelieu_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_richelieu_943_map: app_orderful_richelieu_943_mapService;
  public async orderful_richelieu_943_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_richelieu_943_map) { 
      this._orderful_richelieu_943_map = this.injector.get(app_orderful_richelieu_943_mapService);
    }
    return this._orderful_richelieu_943_map.run(inParams);
  }
   
   

   
 
  private _orderful_richelieu_944_map: app_orderful_richelieu_944_mapService;
  public async orderful_richelieu_944_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_richelieu_944_map) { 
      this._orderful_richelieu_944_map = this.injector.get(app_orderful_richelieu_944_mapService);
    }
    return this._orderful_richelieu_944_map.run(inParams);
  }
   
   

   
 
  private _orderful_richelieu_945_map: app_orderful_richelieu_945_mapService;
  public async orderful_richelieu_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_richelieu_945_map) { 
      this._orderful_richelieu_945_map = this.injector.get(app_orderful_richelieu_945_mapService);
    }
    return this._orderful_richelieu_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_saputo_940_map: app_orderful_saputo_940_mapService;
  public async orderful_saputo_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_saputo_940_map) { 
      this._orderful_saputo_940_map = this.injector.get(app_orderful_saputo_940_mapService);
    }
    return this._orderful_saputo_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_saputo_943_map: app_orderful_saputo_943_mapService;
  public async orderful_saputo_943_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_saputo_943_map) { 
      this._orderful_saputo_943_map = this.injector.get(app_orderful_saputo_943_mapService);
    }
    return this._orderful_saputo_943_map.run(inParams);
  }
   
   

   
 
  private _orderful_saputo_944_map: app_orderful_saputo_944_mapService;
  public async orderful_saputo_944_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_saputo_944_map) { 
      this._orderful_saputo_944_map = this.injector.get(app_orderful_saputo_944_mapService);
    }
    return this._orderful_saputo_944_map.run(inParams);
  }
   
   

   
 
  private _orderful_saputo_945_map: app_orderful_saputo_945_mapService;
  public async orderful_saputo_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_saputo_945_map) { 
      this._orderful_saputo_945_map = this.injector.get(app_orderful_saputo_945_mapService);
    }
    return this._orderful_saputo_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_saputo_947_in_map: app_orderful_saputo_947_in_mapService;
  public async orderful_saputo_947_in_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, mapping_summary?: boolean }): Promise< { DatexAdjustmentInfo?: $frontendTypes.Orderful.DatexAdjustmentInfo[] }> {
    if(!this._orderful_saputo_947_in_map) { 
      this._orderful_saputo_947_in_map = this.injector.get(app_orderful_saputo_947_in_mapService);
    }
    return this._orderful_saputo_947_in_map.run(inParams);
  }
   
   

   
 
  private _orderful_saputo_947_out_map: app_orderful_saputo_947_out_mapService;
  public async orderful_saputo_947_out_map(inParams: { datex_adjustment_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, mapping_summary?: boolean }): Promise< { payload?: any[], messages?: any[] }> {
    if(!this._orderful_saputo_947_out_map) { 
      this._orderful_saputo_947_out_map = this.injector.get(app_orderful_saputo_947_out_mapService);
    }
    return this._orderful_saputo_947_out_map.run(inParams);
  }
   
   

   
 
  private _orderful_sargento_940_map: app_orderful_sargento_940_mapService;
  public async orderful_sargento_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_sargento_940_map) { 
      this._orderful_sargento_940_map = this.injector.get(app_orderful_sargento_940_mapService);
    }
    return this._orderful_sargento_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_sargento_943_map: app_orderful_sargento_943_mapService;
  public async orderful_sargento_943_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_sargento_943_map) { 
      this._orderful_sargento_943_map = this.injector.get(app_orderful_sargento_943_mapService);
    }
    return this._orderful_sargento_943_map.run(inParams);
  }
   
   

   
 
  private _orderful_sargento_944_map: app_orderful_sargento_944_mapService;
  public async orderful_sargento_944_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_sargento_944_map) { 
      this._orderful_sargento_944_map = this.injector.get(app_orderful_sargento_944_mapService);
    }
    return this._orderful_sargento_944_map.run(inParams);
  }
   
   

   
 
  private _orderful_sargento_944_map_old: app_orderful_sargento_944_map_oldService;
  public async orderful_sargento_944_map_old(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_sargento_944_map_old) { 
      this._orderful_sargento_944_map_old = this.injector.get(app_orderful_sargento_944_map_oldService);
    }
    return this._orderful_sargento_944_map_old.run(inParams);
  }
   
   

   
 
  private _orderful_sargento_945_map: app_orderful_sargento_945_mapService;
  public async orderful_sargento_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_sargento_945_map) { 
      this._orderful_sargento_945_map = this.injector.get(app_orderful_sargento_945_mapService);
    }
    return this._orderful_sargento_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_vanskitchen_940_map: app_orderful_vanskitchen_940_mapService;
  public async orderful_vanskitchen_940_map(inParams: { orderful_payload?: any, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_vanskitchen_940_map) { 
      this._orderful_vanskitchen_940_map = this.injector.get(app_orderful_vanskitchen_940_mapService);
    }
    return this._orderful_vanskitchen_940_map.run(inParams);
  }
   
   

   
 
  private _orderful_vanskitchen_945_map: app_orderful_vanskitchen_945_mapService;
  public async orderful_vanskitchen_945_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_vanskitchen_945_map) { 
      this._orderful_vanskitchen_945_map = this.injector.get(app_orderful_vanskitchen_945_mapService);
    }
    return this._orderful_vanskitchen_945_map.run(inParams);
  }
   
   

   
 
  private _orderful_walmart_856_out_map: app_orderful_walmart_856_out_mapService;
  public async orderful_walmart_856_out_map(inParams: { datex_order_info?: any, transaction_type?: string, sender?: string, receiver?: string, stream?: string, owner?: string, project?: string, warehouse?: string, material?: string, packaging?: string, order_class?: string, order_type?: string, shipment_id?: number, order_id?: number, mapping_summary?: boolean }): Promise< { orders?: any[] }> {
    if(!this._orderful_walmart_856_out_map) { 
      this._orderful_walmart_856_out_map = this.injector.get(app_orderful_walmart_856_out_mapService);
    }
    return this._orderful_walmart_856_out_map.run(inParams);
  }
   
   

   
}
