import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Orderful.frontend.types'

@Injectable({ providedIn: 'root' })
export class Orderful_orderful_export_messagesService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { project?: string, order_class?: string, shipment_type?: number, transaction_type?: string, material?: string, packaging?: string, sender?: string, receiver?: string, stream?: string }): Promise<{ success?: boolean, messages?: string[], count?: number, orders?: any[] }> 
  {
    let url = `${environment.backendUrl}api/Orderful/functions/orderful_export_messages`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

