import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_shipping_label_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { order_id: number[] }): Promise<{ result?: { data?: { first_name?: string, line_1?: string, city?: string, state?: string, postal_code?: string, order_lookupcode?: string, owner_reference?: string, vendor_reference?: string, shipping_container_lookupcode?: string }[] } }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.order_id)) {
      missingRequiredInParams.push('\'order_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_shipping_label_flow/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      order_id: number[]    }) {
        if(isNil(inParams.order_id)) {
          return true; 
        }
      return false;
    }
}
