import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_get_report_data_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { package_name: string, report_name: string, inputs?: any }): Promise<{ report_data?: Blob }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.package_name)) {
      missingRequiredInParams.push('\'package_name\'');
    }
    if (isNil(inParams.report_name)) {
      missingRequiredInParams.push('\'report_name\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootprintApiManager/functions/get_report_data_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    const blobOptions: any = {
      ...options,
      observe: 'response',
      responseType: 'blob'
    };
    const response = await this.$utils.http.post<HttpResponse<Blob>>(url, body, blobOptions);
    const blobParamValue = response.status === 200 ? this.$utils.http.httpClientResponseToBlob(response) : null;
    return { report_data: blobParamValue };
    
  }

}

