import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_cancel_sales_ordersService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { orders: $frontendTypes.FootprintApiManager.input_cancel_sales_order[] }): Promise<void> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orders)) {
      missingRequiredInParams.push('\'orders\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootprintApiManager/functions/cancel_sales_orders`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

