import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_ds_get_order_addresses_by_order_idService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number }): 
  Promise<{ result: { Id?: number, AlternateEmail?: string, AlternateFax?: string, AlternateTelephone?: string, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, Greeting?: string, IsResidential?: boolean, LastName?: string, Line1?: string, Line2?: string, Line3?: string, Line4?: string, LookupCode?: string, MiddleName?: string, Name?: string, Notes?: string, Orderid?: number, PostalCode?: string, PrimaryEmail?: string, PrimaryFax?: string, PrimaryTelephone?: string, ReferenceCode?: string, State?: string, Title?: string, TypeId?: number, Url?: string, Type?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootprintApiManager/datasources/ds_get_order_addresses_by_order_id/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AlternateEmail?: string, AlternateFax?: string, AlternateTelephone?: string, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, Greeting?: string, IsResidential?: boolean, LastName?: string, Line1?: string, Line2?: string, Line3?: string, Line4?: string, LookupCode?: string, MiddleName?: string, Name?: string, Notes?: string, Orderid?: number, PostalCode?: string, PrimaryEmail?: string, PrimaryFax?: string, PrimaryTelephone?: string, ReferenceCode?: string, State?: string, Title?: string, TypeId?: number, Url?: string, Type?: { Name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootprintApiManager/datasources/ds_get_order_addresses_by_order_id/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, AlternateEmail?: string, AlternateFax?: string, AlternateTelephone?: string, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, Greeting?: string, IsResidential?: boolean, LastName?: string, Line1?: string, Line2?: string, Line3?: string, Line4?: string, LookupCode?: string, MiddleName?: string, Name?: string, Notes?: string, Orderid?: number, PostalCode?: string, PrimaryEmail?: string, PrimaryFax?: string, PrimaryTelephone?: string, ReferenceCode?: string, State?: string, Title?: string, TypeId?: number, Url?: string, Type?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootprintApiManager/datasources/ds_get_order_addresses_by_order_id/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
      return false;
    }
}
