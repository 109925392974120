import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { EndeavorLabs_ShellService } from './EndeavorLabs.shell.service';
import { EndeavorLabs_OperationService } from './EndeavorLabs.operation.service';
import { EndeavorLabs_DatasourceService } from './EndeavorLabs.datasource.index';
import { EndeavorLabs_FlowService } from './EndeavorLabs.flow.index';
import { EndeavorLabs_ReportService } from './EndeavorLabs.report.index';
import { EndeavorLabs_LocalizationService } from './EndeavorLabs.localization.service';
import { EndeavorLabs_annotations_grid_ComponentContextService } from './EndeavorLabs.annotations_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './EndeavorLabs.frontend.types'
import { $frontendTypes as $types} from './EndeavorLabs.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IEndeavorLabs_annotations_gridComponentEntity {
  Id?: number, CompletedDateTime?: string, Employee?: string, Length?: number, Notes?: string, ShipmentId?: number, Shipment?: { LookupCode?: string }, clength?: string}

interface IEndeavorLabs_annotations_gridComponentInParams {
  OrderId?: number}


class EndeavorLabs_annotations_gridComponentRowModel extends GridRowModel {
  grid: EndeavorLabs_annotations_gridComponent;
  entity: IEndeavorLabs_annotations_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    Employee: null,
    CompletedDateTime: null,
    ShipmentId: null,
    Notes: null,
    Length: null,
  }



  constructor(
    grid: EndeavorLabs_annotations_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: EndeavorLabs_ShellService,
private $datasources: EndeavorLabs_DatasourceService,
private $flows: EndeavorLabs_FlowService,
private $reports: EndeavorLabs_ReportService,
private $localization: EndeavorLabs_LocalizationService,
private $operations: EndeavorLabs_OperationService,
private $logger: CleanupLoggerService,
private $context: EndeavorLabs_annotations_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.Employee = new GridCellModel(
      new CellStyles(['center'], null),
      this.grid.headers.Employee,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.CompletedDateTime = new GridCellModel(
      new CellStyles(['center'], null),
      this.grid.headers.CompletedDateTime,
      new TextModel(null, null,  'datetime', 'MM-DD-YYYY', null)
,
null
      );
    
    this.cells.ShipmentId = new GridCellModel(
      new CellStyles(['center'], null),
      this.grid.headers.ShipmentId,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Notes = new GridCellModel(
      new CellStyles(['center'], null),
      this.grid.headers.Notes,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Length = new GridCellModel(
      new CellStyles(['center'], null),
      this.grid.headers.Length,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IEndeavorLabs_annotations_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IEndeavorLabs_annotations_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      OrderId:  $grid.inParams.OrderId ,
    };
    const data = await this.$datasources.EndeavorLabs.ds_prediction_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IEndeavorLabs_annotations_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.Employee.displayControl as TextModel).text = $row.entity.Employee;
    (this.cells.CompletedDateTime.displayControl as TextModel).text = $row.entity.CompletedDateTime?.toString();
    (this.cells.ShipmentId.displayControl as TextModel).text = $row.entity.Shipment.LookupCode.toString();
    (this.cells.Notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.Length.displayControl as TextModel).text = $row.entity?.clength;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }


}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'EndeavorLabs-annotations_grid',
  templateUrl: './EndeavorLabs.annotations_grid.component.html'
})
export class EndeavorLabs_annotations_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IEndeavorLabs_annotations_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('headersWidth', null);

  fullTextSearch: string;

  inParams: IEndeavorLabs_annotations_gridComponentInParams = { OrderId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Employee: new GridHeaderModel(new HeaderStyles(['center'], null), 'Employee', 'Employee', false, false, null, false),       CompletedDateTime: new GridHeaderModel(new HeaderStyles(['center'], null), 'CompletedDateTime', 'Completed date', false, false, null, false),       ShipmentId: new GridHeaderModel(new HeaderStyles(['center'], null), 'ShipmentId', 'Shipment', false, false, null, false),       Notes: new GridHeaderModel(new HeaderStyles(['center'], null), 'Notes', 'Notes', false, false, null, false),       Length: new GridHeaderModel(new HeaderStyles(['center'], null), 'Length', 'Length', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: EndeavorLabs_annotations_gridComponentRowModel[] = [];

  @Input('OrderId') set $inParams_OrderId(value: any) {
    this.inParams['OrderId'] = value;
  }
  get $inParams_OrderId(): any {
    return this.inParams['OrderId'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: EndeavorLabs_ShellService,
    private $datasources: EndeavorLabs_DatasourceService,
    private $flows: EndeavorLabs_FlowService,
    private $reports: EndeavorLabs_ReportService,
    private $localization: EndeavorLabs_LocalizationService,
    private $operations: EndeavorLabs_OperationService,
    private $logger: CleanupLoggerService,
    private $context: EndeavorLabs_annotations_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Annotations';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 10;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      OrderId:  $grid.inParams.OrderId ,
    };
    try {
      const data = await this.$datasources.EndeavorLabs.ds_prediction_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IEndeavorLabs_annotations_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new EndeavorLabs_annotations_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: EndeavorLabs_annotations_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }



 
  close() {
    this.$finish.emit();
  }
}
