import { IEnvironmentInfo, IComponentInfo, IPackageInfo, IApplicationInfo, ComponentType } from './app-context.service';
import { FootprintApiManager_ModuleContextService } from './FootprintApiManager.context.service';

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class FootprintApiManager_base_order_lines_grid_ComponentContextService {
  private readonly moduleContext: FootprintApiManager_ModuleContextService;

  constructor(moduleContext: FootprintApiManager_ModuleContextService) {
    this.moduleContext = moduleContext;
  }

  public get app(): IApplicationInfo {
    return this.moduleContext.app;
  }

  public get env(): IEnvironmentInfo {
    return this.moduleContext.env;
  }

  public get package(): IPackageInfo {
    return this.moduleContext.package;
  }

  public get component(): IComponentInfo {
    return {
      name: 'base_order_lines_grid',
      type: ComponentType.grid
    }
  }
}