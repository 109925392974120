import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootprintApiManager_ds_get_pallet_transactions_by_shipment_idService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipmentId?: number }): 
  Promise<{ result: { Id?: number, AccountId?: number, Amount?: number, CarrierId?: number, Date?: string, Employee?: string, Notes?: string, PalletClassId?: number, ProjectId?: number, Reference?: string, ReferenceCarrierId?: number, ShipmentId?: number, Account?: { LookupCode?: string, Name?: string }, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, PalletClass?: { Name?: string, Notes?: string }, Project?: { LookupCode?: string, Name?: string }, ReferenceCarrier?: { Name?: string, ScacCode?: string, ShortName?: string }, Shipment?: { LookupCode?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/FootprintApiManager/datasources/ds_get_pallet_transactions_by_shipment_id/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shipmentId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AccountId?: number, Amount?: number, CarrierId?: number, Date?: string, Employee?: string, Notes?: string, PalletClassId?: number, ProjectId?: number, Reference?: string, ReferenceCarrierId?: number, ShipmentId?: number, Account?: { LookupCode?: string, Name?: string }, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, PalletClass?: { Name?: string, Notes?: string }, Project?: { LookupCode?: string, Name?: string }, ReferenceCarrier?: { Name?: string, ScacCode?: string, ShortName?: string }, Shipment?: { LookupCode?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/FootprintApiManager/datasources/ds_get_pallet_transactions_by_shipment_id/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipmentId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, AccountId?: number, Amount?: number, CarrierId?: number, Date?: string, Employee?: string, Notes?: string, PalletClassId?: number, ProjectId?: number, Reference?: string, ReferenceCarrierId?: number, ShipmentId?: number, Account?: { LookupCode?: string, Name?: string }, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, PalletClass?: { Name?: string, Notes?: string }, Project?: { LookupCode?: string, Name?: string }, ReferenceCarrier?: { Name?: string, ScacCode?: string, ShortName?: string }, Shipment?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootprintApiManager/datasources/ds_get_pallet_transactions_by_shipment_id/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      shipmentId?: number    }) {
      return false;
    }
}
